<app-custom-spinner *ngIf="isLoading"></app-custom-spinner>
<div (click)="closeModal()" [class.ns-mr-24]="!isMobile" class="ns-close-button"></div>
<div *ngIf="!isLoading">
  <div class="first-line">
    <ng-container *ngIf="isMobile">
      <div class="d-flex w-100">
        <div class="avatar">
          <img (error)="onImageError()" alt="avatar" src="{{ userData.image }}">
        </div>
        <div class="name-wrapper">
          <div>
            <div class="name">{{ userData.name }} {{ userData.surname }}</div>
            <div *ngIf="userData.team" class="team">{{ userData.team }}</div>
            <div *ngIf="userData.campaign_participations === 1" class="participations">{{ "LAC.participation_1" | translate }}
              <span>{{ userData.campaign_participations }}</span>
              {{ "LAC.participation_2_singular" | translate }}</div>
            <div *ngIf="userData.campaign_participations >= 2" class="participations">{{ "LAC.participation_1" | translate }}
              <span>{{ userData.campaign_participations }}</span>
              {{ "LAC.participation_2_plural" | translate }}</div>
          </div>
        </div>
      </div>
      <div class="d-flex w-100">
        <div class="points-wrapper">
          <div class="points-item">
            <div>
              <div class="points-title">{{ "LAC.score_2" | translate }} {{ userData.campaign_name[currentLanguage] }} {{ "LAC.score_3" | translate }}</div>
              <div class="points-points">
                <span>{{ userData.campaign_points }}</span>
                {{ "LAC.points" | translate }}</div>
            </div>
          </div>
          <div class="points-item">
            <div>
              <div class="points-title">{{ "LAC.all_time" | translate }}</div>
              <div class="points-points">
                <span>{{ userData.all_time_points }}</span>
                {{ "LAC.points" | translate }}</div>
            </div>
          </div>
        </div>
      </div>

    </ng-container>
    <ng-container *ngIf="!isMobile">
      <div class="avatar">
        <img (error)="onImageError()" alt="avatar" src="{{ userData.image }}">
      </div>
      <div class="name-wrapper">
        <div>
          <div class="name">{{ userData.name }} {{ userData.surname }}</div>
          <div *ngIf="userData.team" class="team">{{ userData.team }}</div>
          <div *ngIf="userData.campaign_participations === 1" class="participations">{{ "LAC.participation_1" | translate }}
            <span>{{ userData.campaign_participations }}</span>
            {{ "LAC.participation_2_singular" | translate }}</div>
          <div *ngIf="userData.campaign_participations >= 2" class="participations">{{ "LAC.participation_1" | translate }}
            <span>{{ userData.campaign_participations }}</span>
            {{ "LAC.participation_2_plural" | translate }}</div>
        </div>
      </div>
      <div class="points-wrapper">
        <div class="points-item">
          <div>
            <div class="points-title">{{ "LAC.score_2" | translate }} {{ userData.campaign_name[currentLanguage] }} {{ "LAC.score_3" | translate }}</div>
            <div class="points-points">
              <span>{{ userData.campaign_points }}</span>
              {{ "LAC.points" | translate }}</div>
          </div>
        </div>
        <div class="points-item">
          <div>
            <div class="points-title">{{ "LAC.all_time" | translate }}</div>
            <div class="points-points">
              <span>{{ userData.all_time_points }}</span>
              {{ "LAC.points" | translate }}</div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="second-line">
    <div class="second-line-item">
      <div class="second-line-title">{{ "LAC.challenges_completed" | translate }}</div>
      <div class="second-line-value">{{ userData.challenge_count }}</div>
    </div>
    <div class="second-line-item">
      <div class="second-line-title">{{ "LAC.learning_completed" | translate }}</div>
      <div class="second-line-value">{{ userData.learning_count }}</div>
    </div>
    <div class="second-line-item">
      <div class="second-line-title">{{ "LAC.longest" | translate }}</div>
      <div *ngIf="userData.streak === 1" class="second-line-value">{{ userData.streak }} {{ "LAC.week" | translate }}</div>
      <div *ngIf="userData.streak !== 1" class="second-line-value">{{ userData.streak }} {{ "LAC.weeks" | translate }}</div>
    </div>
  </div>
  <div class="selector-wrapper">
    <div (click)="selectSelector('challenges')" [ngClass]="{'selected': selectorSelected === 'challenges'}" class="selector">{{ "LAC.challenges_2" | translate }}</div>
    <div (click)="selectSelector('learning_content')" [ngClass]="{'selected': selectorSelected === 'learning_content'}" class="selector">{{ "LAC.learning" | translate }}</div>
    <div (click)="selectSelector('badges')" [ngClass]="{'selected': selectorSelected === 'badges'}" class="selector">{{ "LAC.achievements_2" | translate }}</div>
  </div>
  <ng-container *ngIf="selectorSelected === 'challenges'">
    <div *ngFor="let campaign of userData.challenges; let i = index" class="campaign-wrapper ns-mt-22">
      <div (click)="toggleCampaignChallenges(i)" class="campaign-title">
        {{ campaign.campaign[currentLanguage] }}
        <i [class]="challengesToggle[i] ? 'fa fa-angle-up' : 'fa fa-angle-down'" class="arrow-icon"></i>
      </div>
      <div class="campaign-dates">{{ campaign.start_date[currentLanguage] }} {{ "LAC.to" | translate }} {{ campaign.end_date[currentLanguage] }}</div>
      <div *ngIf="challengesToggle[i]" class="campaign-challenges-wrapper">
        <div (click)="isMobile && challenge.visible_cta ? openChallengePage(challenge.slug): null"
             *ngFor="let challenge of campaign.challenges" class="challenge-item">
          <div class="challenge-image">
            <img alt="challenge-image" src="{{ challenge.image }}">
          </div>
          <div class="second-column">
            <div>
              <div class="challenge-name">{{ challenge.name[currentLanguage] }}</div>
              <div class="challenge-content">{{ challenge.content[currentLanguage] }}</div>
            </div>
          </div>
          <div class="button-wrapper">
            <div (click)="openChallengePage(challenge.slug)" *ngIf="!isMobile && challenge.visible_cta"
                 class="small-button">{{ "LAC.learn" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="userData.challenges.length === 0" class="no-content">
      {{ userData.name }} {{ "LAC.colleague_challenge_1" | translate }} {{ userData.name }}{{ "LAC.colleague_challenge_2" | translate }}
    </div>
  </ng-container>
  <div *ngIf="selectorSelected === 'learning_content'" class="learning-content">
    <div *ngFor="let campaign of userData.learning;let i = index" class="campaign-wrapper ns-mt-22">
      <div (click)="toggleCampaignLearningContent(i)"
           class="campaign-title">{{ campaign.campaign[currentLanguage] }}</div>
      <div
        class="campaign-dates">{{ campaign.start_date[currentLanguage] }} {{ "LAC.to" | translate }} {{ campaign.end_date[currentLanguage] }}</div>
      <i (click)="toggleCampaignLearningContent(i)"
         [class]="learningContentToggle[i] ? 'fa fa-angle-up' : 'fa fa-angle-down'"
         class="arrow-icon"></i>
      <div *ngIf="learningContentToggle[i]" class="campaign-learning-content">
        <div *ngFor="let learningNugget of campaign.content" class="learning-content-item">
          <div class="left-side">
            <div>
              <div class="filters-wrapper">
                <div
                  class="lac-tag light-green">{{ learningNugget.type_general === CampaignConstants.LC_TYPE_QUIZ ? 'Quiz' : "LAC.article" | translate }}</div>
                <div class="lac-tag light-green">+{{ learningNugget.type_points }} {{ "LAC.points" | translate }}</div>
                <div class="lac-tag light-green">
                  <i class="fa-solid fa-clock"></i>
                  {{ learningNugget.type_time }} {{ "LAC.minutes" | translate }}
                </div>
              </div>
              <div class="learning-content-name">{{ learningNugget.name[currentLanguage] }}</div>
            </div>
          </div>
          <div class="right-side">
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="userData.learning.length === 0" class="no-content">
      {{ userData.name }} {{ "LAC.colleague_learning" | translate }}
    </div>
  </div>
  <div *ngIf="selectorSelected === 'badges'" [class.badges-grid]="userData.badges.length >= 1" class="badges">
    <div *ngFor="let badge of userData.badges" class="badge">
      <div class="badge-image">
        <img alt="badge-image" src="{{ badge.image }}">
      </div>
      <div class="badge-name">{{ badge.title[currentLanguage] }}</div>
      <div [maxLineLimit]="2" class="badge-content" nsVerticalTextEllipsis>{{ badge.description[currentLanguage] }}</div>
    </div>
    <div *ngIf="userData.badges.length === 0" class="no-content">
      {{ "LAC.ups_1" | translate }} {{ userData.name }} {{ "LAC.ups_2" | translate }}
    </div>
  </div>
</div>
