import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { HeaderService } from '../../../services/header.service';
import { Constants } from '../../../app.constants';
import { User } from '../../user.model';
import { BaseComponent } from '../../base/base.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LanguageService } from '../../../services/language.service';
import { Language } from '../../projects.model';
import { ModalQueueService } from '../../services/modal/modal-queue.service';

@Component({
  selector: 'app-welcome-onboarding',
  templateUrl: './welcome-onboarding.component.html',
  styleUrls: ['./welcome-onboarding.component.scss']
})
export class WelcomeOnboardingComponent extends BaseComponent implements OnInit, OnDestroy {
  Constants = Constants;

  @Input() user: User;
  @Input() companyName: any;
  @Input() companyNameEn: any;

  currentStep = 1;
  campaign_topic: Language;

  constructor(private headerService: HeaderService,
              protected breakpointObserver: BreakpointObserver,
              protected languageService: LanguageService,
              private renderer: Renderer2,
              private modalQueue: ModalQueueService
  ) {
    super(languageService, breakpointObserver);
  }

  ngOnInit(): void {
    // Hides main page scrollbar.
    this.renderer.setStyle(document.body, 'overflow', 'hidden');

    const user = JSON.parse(localStorage.getItem('currentUser')) as User;
    if (user?.campaign_topic) {
      this.campaign_topic = user.campaign_topic;
    }

    this.headerService.$isContentMaskVisible.next(true);
  }

  onNextStepClick() {
    this.currentStep++;
    if (this.currentStep === 3) {
      this.isMobile ? this.closeModal() : this.headerService.$isWelcomeOnboardingFocusCenter.next(true);
    }
    if (this.currentStep === 4) {
      this.headerService.$isWelcomeOnboardingFocusRight.next(true);
    }
    if (this.currentStep === 5) {
      this.closeModal();
    }
  }

  closeModal(): void {
    this.headerService.$isContentMaskVisible.next(false);
    this.headerService.$isWelcomeOnboardingDone.next(true);
    this.modalQueue.closeModal(this.modalQueue.ids.WELCOME_ONBOARDING_ID);
  }

  ngOnDestroy() {
    this.modalQueue.closeModal(this.modalQueue.ids.WELCOME_ONBOARDING_ID);
    super.ngOnDestroy();
    this.renderer.removeStyle(document.body, 'overflow');
  }
}


