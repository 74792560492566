<app-custom-spinner *ngIf="isLoading"></app-custom-spinner>
<ng-container *ngIf="!isLoading">
  <div (click)="closeModal()" class="ns-close-button"></div>
  <div [class.one-column]="!hasReward" class="main-container">
    <img *ngIf="!isMobile" alt="End of campaign" class="top-image" src="assets/images/svg/end_lac_modal_top.svg">
    <img *ngIf="isMobile" alt="End of campaign" class="top-image" src="assets/images/svg/end_lac_modal_top_mobile.svg">
    <div class="title">
      {{ 'END_LAC_MODAL.title' | translate }}
    </div>
    <div class="subtitle">
      {{ 'END_LAC_MODAL.subtitle' | translate: {
        campaignName: endCampaignInfo?.campaign_name[currentLanguage],
        campaignTopic: endCampaignInfo?.campaign_topic[currentLanguage]}
      }}
    </div>
    <div *ngIf="hasReward" class="target-box">
      <ng-container *ngIf="endCampaignInfo?.reward_reached">
        <div class="target-title">{{ 'END_LAC_MODAL.target_reached_title' | translate }}</div>
        <div class="target-text">
          {{ 'END_LAC_MODAL.target_reached_text' | translate: {
            rewardImpact: endCampaignInfo?.reward_impact[currentLanguage]}
          }}
          <a (click)="openProjectPage()" class="ns-light-green">
            {{ endCampaignInfo?.reward_project_name[currentLanguage]}}</a>.
        </div>
      </ng-container>
      <ng-container *ngIf="!endCampaignInfo?.reward_reached">
        <div class="target-title">{{ 'END_LAC_MODAL.target_not_reached_title' | translate }}</div>
        <div class="target-text">
          {{ 'END_LAC_MODAL.target_not_reached_text' | translate:{companyName: companyName} }}
          <a (click)="openProjectPage()" class="ns-light-green cursor-pointer">
            {{ endCampaignInfo?.reward_project_name[currentLanguage]}}</a>{{ 'END_LAC_MODAL.try_next_time' | translate }}
        </div>
      </ng-container>
    </div>
    <div class="d-flex justify-content-center">
      <div class="activity-box">
        <div class="activity-number">{{ endCampaignInfo?.challenges_completed }}</div>
        <div class="activity-type">{{ 'END_LAC_MODAL.challenges_completed' | translate }}</div>
      </div>
      <div class="activity-box">
        <div class="activity-number">{{ endCampaignInfo?.nuggets_completed }}</div>
        <div class="activity-type">{{ 'END_LAC_MODAL.learning_completed' | translate }}</div>
      </div>
    </div>
    <div class="positions-title">{{ 'END_LAC_MODAL.top_positions' | translate }}</div>
    <div class="positions-box" *ngIf="endCampaignInfo.teams_leaderboard.length === 0">
      <div *ngFor="let user of podiumPositions | slice:0:4" class="position">
        <div [ngClass]="{'first': user?.position === 1, 'is-you': user?.current_user && user?.position !== 1}"
             class="leaderboard-number">{{ user?.position }}</div>
        <div [ngClass]="{'first': user?.position === 1}" class="leaderboard-picture">
          <img (error)="user.profile_picture = '/assets/icons/no-avatar.png'" alt="item-image" src="{{ user?.profile_picture }}">
        </div>
        <div [ngClass]="{'first': user?.position === 1, 'is-you': user?.current_user && user?.position !== 1}"
             class="leaderboard-name">{{ user?.name }} {{ user?.surname }}</div>
        <div [ngClass]="{'first': user?.position === 1, 'is-you': user?.current_user && user?.position !== 1}"
             class="leaderboard-points">{{ user.points }} pts
        </div>
      </div>
    </div>
    <div class="positions-box" *ngIf="endCampaignInfo.teams_leaderboard.length !== 0">
      <div *ngFor="let team of podiumPositions | slice:0:4" class="position">
        <div [ngClass]="{'first': team?.position === 1, 'is-you': team?.current_team && team?.position !== 1}"
             class="leaderboard-number">{{ team?.position }}</div>
        <div [ngClass]="{'first': team?.position === 1, 'is-you': team?.current_team && team?.position !== 1}"
             class="leaderboard-name">{{ team?.name }}</div>
        <div [ngClass]="{'first': team?.position === 1, 'is-you': team?.current_team && team?.position !== 1}"
             class="leaderboard-points">{{ team.points }} pts
        </div>
      </div>
    </div>
    <div class="button-container">
      <ns-button
          (callback$)="openLeaderboardModal()"
          [text]="'LAC.see' | translate"
          [type]="Constants.BUTTON_TYPE_DEFAULT_DARK_BLUE_GHOST_S"
          classes="ns-font-w-400 ns-p-10 ns-mr-minus-12">
      </ns-button>
    </div>
    <div class="bottom-container">
      <ns-button
          (callback$)="openCampaignPage()"
          [classes]="'ns-font-w-800'"
          [text]="'END_LAC_MODAL.see_campaign_activity' | translate"
          [type]="Constants.BUTTON_TYPE_DEFAULT_YELLOW_SOLID_M">
      </ns-button>
    </div>
    <div class="bottom-text">
        {{ 'END_LAC_MODAL.stay_tuned' | translate }}
      </div>
  </div>
</ng-container>
