import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { Constants } from '../../../app.constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalService } from '../../services/modal/modal.service';
import { LanguageService } from '../../../services/language.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NsSimpleInfoModalComponent } from '../ns-simple-info-modal/ns-simple-info-modal.component';
import { ModalConstants } from '../../services/modal/modal.constants';
import { TranslateService } from '@ngx-translate/core';
import { ProjectsService } from '../../../services/projects.service';
import { Proposal } from '../../projects.model';
import { User } from '../../user.model';

@Component({
  selector: 'app-propose-initiative-modal',
  templateUrl: './propose-initiative-modal.component.html',
  styleUrls: ['./propose-initiative-modal.component.scss']
})
export class ProposeInitiativeModalComponent implements OnInit {
  readonly Constants = Constants;

  @Input() user: User;
  @Input() initiativeType;
  @Input() title: string;
  @Input() explanation1 : string;
  @Input() explanation2 : string;
  @Input() explanation3 : string;


  initiativeForm: FormGroup;
  initiativeWhyForm: FormGroup;

  currentStep = 0;
  isLoading = true;

  screenWidth = 0;
  isMobile: boolean;
  hasMainContainerScroll = false;

  constructor(private languageService: LanguageService,
              private modal: ModalService, @Inject(MAT_DIALOG_DATA) data,
              private dialogRef: MatDialogRef<ProposeInitiativeModalComponent>,
              private fb: FormBuilder,
              private translate: TranslateService,
              private projectsService: ProjectsService) {
  }

  @ViewChild('mainContainer') mainContainerRef: ElementRef;

  get currentLanguage() {
    return this.languageService.getUserUsedLanguage();
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.isMobile = this.screenWidth < 768;

    this.initiativeForm = this.fb.group({
      ngoName: ['', Validators.required],
      ngoWebsite: [''],
      contactName: [''],
      contactEmail: ['', [Validators.required, Validators.email]],
      contactPhone: [''],
    });
    this.initiativeWhyForm = this.fb.group({
      contactWhy: ['', [Validators.required]]

    });

    this.isLoading = false;
  }

  checkForScroll() {
    setTimeout(() => {
      const element = this.mainContainerRef.nativeElement;
      this.hasMainContainerScroll = element.scrollHeight > element.clientHeight;
    }, 100)
  }

  onLearnMoreButtonClick() {
    let url: string;
    switch (this.currentLang) {
      case Constants.FR_LANG_KEY:
        url = Constants.SPONSORSHIP_PROGRAM_FOR_EMPLOYEES_URL_FR;
        break;
      case Constants.ES_LANG_KEY:
        url = Constants.SPONSORSHIP_PROGRAM_FOR_EMPLOYEES_URL_ES;
        break;
      case Constants.EN_LANG_KEY:
        url = Constants.SPONSORSHIP_PROGRAM_FOR_EMPLOYEES_URL_EN;
        break;
      default :
        url = Constants.SPONSORSHIP_PROGRAM_FOR_EMPLOYEES_URL_FR;
        break;
    }
    window.open(url, '_blank');
  }

  OnProposeButtonClick() {
    this.currentStep++;
    this.checkForScroll();
  }

  previousStep(): void {
    this.currentStep--;
  }

nextStep(): void {
    this.currentStep++;

    if (this.currentStep === 3) {
        const proposal: Proposal = {
            account_id: this.user.id,
            organization_name: this.initiativeForm.get('ngoName').value,
            type: this.initiativeType,
            organization_website: this.initiativeForm.get('ngoWebsite').value,
            contact_name: this.initiativeForm.get('contactName').value,
            contact_email: this.initiativeForm.get('contactEmail').value,
            contact_phone: this.initiativeForm.get('contactPhone').value,
            explanation: this.initiativeWhyForm.get('contactWhy').value
        };
        this.isLoading = true;

        this.projectsService.sendProposal(proposal).subscribe({
            next: () => {
              this.openSuccessModal();
              this.closeDialog();
              this.isLoading = false;
            },
          error: () => {
            this.isLoading = false;
            this.closeDialog();
          }
        });
    }
}

  openSuccessModal() {
    const modalHeight = this.screenWidth > 767 ? 350 : 284;
    const modalWidth = this.screenWidth > 767 ? 600 : 300;
    const infoModalRef = this.modal.openFixedModal(
      {}, NsSimpleInfoModalComponent, this.isMobile ? ModalConstants.PADDING_16_PANEL_CLASS : ModalConstants.DEFAULT_PANEL_CLASS, modalWidth, modalHeight);
    const modal = infoModalRef.componentInstance;
    modal.title = this.translate.instant('GENERAL.success_exclamation');
    modal.infoText = this.translate.instant('INITIATIVE_PROPOSAL_MODAL.success_text');
    modal.buttonText = this.translate.instant('GENERAL.close');
    modal.buttonType = this.isMobile ? Constants.BUTTON_TYPE_DEFAULT_DARK_BLUE_STROKE_M : Constants.BUTTON_TYPE_DEFAULT_GREEN_LINE_B;
  }

  closeDialog() {
    this.dialogRef.close();
  }

    get currentLang() {
    return this.translate.currentLang;
  }
}
