<div (click)="closeDialog()" class="ns-close-button"></div>
<div class="modal-container">
  <div [ngClass]="{'ns-mt-9':!isMobile,'ns-mt-17':isMobile}" class="success-title">
    {{"GENERAL.success_exclamation" | translate }}</div>
  <div [innerHTML]="'VOLUNTEERING_TAB.success_share_offer_info1' | translate"
       class="success-info-text ns-mt-14"></div>
  <div [innerHTML]="'VOLUNTEERING_TAB.success_share_offer_info2' | translate"
       class="success-info-text ns-mt-34"></div>
  <div [innerHTML]="'VOLUNTEERING_TAB.success_share_offer_info3' | translate"
       class="success-info-text ns-mt-4"></div>
  <div [innerHTML]="'VOLUNTEERING_TAB.success_share_offer_info4' | translate"
       class="success-info-text ns-mt-26"></div>
  <div [innerHTML]="'VOLUNTEERING_TAB.success_share_offer_info5' | translate:{url:getProposalUrl()}"
       class="success-info-text ns-mt-4 ns-mb-40"></div>
  <ns-button
    (callback$)="closeDialog()"
    [classes]="'ns-font-26 ns-line-height-26 ns-font-w-800 ns-pl-18 ns-pr-18 ' +
           (!isMobile ? 'ns-height-47':'ns-height-auto')"
    [styles]="!isMobile ? 'width:315px;':'width:100%;'"
    [text]="'VOLUNTEERING_TAB.see_other_opportunities' | translate"
    [type]="Constants.BUTTON_TYPE_DEFAULT_GREEN_STROKE_M">
  </ns-button>
</div>
