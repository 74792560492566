import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TagsService } from './tags.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  language$: BehaviorSubject<string> = new BehaviorSubject<string>(this.getLanguage());

  constructor(private router: Router,
              private location: Location,
              private tagsService: TagsService,
              private translate: TranslateService) {}

  getLanguage() {
    const userLastUsedLanguage = this.getUserUsedLanguage();
    const browserLang = userLastUsedLanguage ? userLastUsedLanguage : this.translate.getBrowserLang();
    return browserLang.match(/en|fr|es/) ? browserLang : 'en';
  }

  setInitialLanguageSettings() {
    const browserLang = this.getLanguage();
    this.translate.addLangs(['en', 'fr', 'es']);
    this.translate.setDefaultLang('en');
    this.translate.use(browserLang);
  }

  setLanguageFromUrl() {
    const language = this.router.url.slice(1, 3);
    this.translate.use(language);
    this.setUserUsedLanguage(language);
  }

  setUserUsedLanguage(value) {
    localStorage.setItem('USER_USED_LANGUAGE', JSON.stringify(value));
    localStorage.setItem('LOCALIZE_DEFAULT_LANGUAGE', value);
  }

  getUserUsedLanguage() {
    return JSON.parse(localStorage.getItem('USER_USED_LANGUAGE'));
  }

  getCurrentLangWithSlash() {
    return '/' + this.translate.currentLang;
  }

  getCurrentPath() {
    const urlAfterLang: string[] = this.router.url.split('/');
    urlAfterLang.shift();
    urlAfterLang.shift();
    return urlAfterLang;
  }

  changeLanguage(lang) {
    this.language$.next(lang);
    const urlAfterLang: string[] = this.router.url.split('/');
    urlAfterLang.shift();
    urlAfterLang.shift();
    let urlAfterLanguageChanged = '/';
    urlAfterLanguageChanged += lang;
    urlAfterLanguageChanged += '/';
    for (const el of urlAfterLang) {
      urlAfterLanguageChanged += el;
      urlAfterLanguageChanged += '/';
    }
    this.location.go(urlAfterLanguageChanged);
    this.translate.use(lang);
    this.setUserUsedLanguage(lang);
    switch (urlAfterLang[0]) {
      case 'log-in':
        this.tagsService.sendClickEventLogIn();
        break;
      case 'sign-up':
        this.tagsService.sendClickEventSignUp();
        break;
      case 'csr-projects':
        if (urlAfterLang.length > 1) {
          this.tagsService.sendClickEventProject();
        } else {
          this.tagsService.sendClickEventBrowseProjects();
        }
        break;
      case 'volunteering':
        this.tagsService.sendClickEventVolunteering();
        break;
      case 'impact-organizations':
        this.tagsService.sendClickEventImpactOrganizations();
        break;
      case 'terms-of-service':
        this.tagsService.sendClickEventTermsOfService();
        break;
      case 'RGPD':
        this.tagsService.sendClickEventPrivacyPolicy();
        break;
      case 'your-impact':
        switch (urlAfterLang[1]) {
          case 'impact':
            this.tagsService.sendClickEventImpact();
            break;
          case 'projects':
            this.tagsService.sendClickEventImpactProjects();
            break;
          default:
            this.tagsService.sendClickEventImpact();
            break;
        }
        break;
      case 'profile':
        this.tagsService.sendClickEventProfileSettings();
        break;
      case 'brands':
        if (urlAfterLang.length > 1) {
          this.tagsService.sendClickEventBrandProgram();
        } else {
          this.tagsService.sendClickEventBrand();
        }
        break;
      case 'campaign':
        if (urlAfterLang.length >= 2) {
          this.tagsService.sendClickEventChallenge();
        } else {
          this.tagsService.sendClickEventCampaign();
        }
        break;
      case 'home':
        this.tagsService.sendClickEventHome();
        break;
      default:
        this.tagsService.sendClickEventLanding();
    }
  }
}
