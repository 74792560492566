<div (click)="closeDialog()" class="ns-close-button"></div>
<div class="modal-container">
  <div [ngClass]="{'ns-mt-17':isMobile}" class="title">
    {{ title }}</div>
  <div [innerHTML]="infoText" [class]=" !isMobile ? _infoTextDesktopClasses: _infoTextMobileClasses "></div>
  <ns-button
    (callback$)="closeDialog()"
    [styles]="!isMobile ? 'min-width:175px;':'width:100%;'"
    [text]="buttonText"
    [type]="buttonType">
  </ns-button>
</div>
