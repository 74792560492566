import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OneSignal} from 'onesignal-ngx';
import {LanguageService} from 'src/app/services/language.service';
import {PushPreferencesModalResponse} from '../../models/push-notifications/push-notifications.model';

@Component({
  selector: 'app-soft-ask-modal',
  templateUrl: './soft-ask-modal.component.html',
  styleUrls: ['./soft-ask-modal.component.scss']
})
export class SoftAskModalComponent {
  readonly ALLOW_HARD_ASK_CODE = 'granted';

  constructor(private dialogRef: MatDialogRef<SoftAskModalComponent, PushPreferencesModalResponse>,
              private oneSignal: OneSignal,
              private languageService: LanguageService,
              @Inject(MAT_DIALOG_DATA) data) {
  }


  onAllowSoftAsk(): void {
    const modalResponse: PushPreferencesModalResponse = {status: true, native_status: false};

    this.oneSignal.showNativePrompt().then(() => {
      this.oneSignal.getNotificationPermission().then((notificationPermission: NotificationPermission) => {
        if (notificationPermission === this.ALLOW_HARD_ASK_CODE) {
          modalResponse.native_status = true;
          this.oneSignal.getUserId().then((playerId: string) => {
            modalResponse.onesignal_player_id = playerId;
            this.dialogRef.close(modalResponse);
          }).catch(() => {
            this.dialogRef.close(modalResponse);
          });
        } else {
          this.dialogRef.close(modalResponse);
        }
      });
    });
  }

  onDisallowSoftAsk(): void {
    this.dialogRef.close({status: false, native_status: false});
  }
}
