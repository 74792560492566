<div class="top-container">
  <div (click)="closeDialog()" class="close-button"></div>
</div>
<div class="title">{{ 'EXTERNAL_OFFER.title_external_offer' | translate }}</div>
<div class="subtitle">{{ 'EXTERNAL_OFFER.subtitle_external_offer' | translate }}</div>
<ol>
  <li>
    <div class="text-1" *ngIf="endsWithS">{{ 'EXTERNAL_OFFER.text1_external_offer' | translate }} {{ ioName }}{{ 'EXTERNAL_OFFER.text2withs_external_offer' | translate }}</div>
    <div class="text-1" *ngIf="!endsWithS">{{ 'EXTERNAL_OFFER.text1_external_offer' | translate }} {{ ioName }}{{ 'EXTERNAL_OFFER.text2withouts_external_offer' | translate }}</div>
    <div class="text-2">{{ 'EXTERNAL_OFFER.remember_external_offer' | translate }}</div>
    <div class="text-3">{{ 'EXTERNAL_OFFER.text3_external_offer' | translate }}</div>
  </li>
  <li>
    <div class="text-1">{{ 'EXTERNAL_OFFER.text4_external_offer' | translate }}</div>
    <div class="text-3">
      {{ 'EXTERNAL_OFFER.text5_external_offer' | translate }} {{ company }} {{ 'EXTERNAL_OFFER.text6_external_offer' | translate }}
      <a href="{{ getTypeformLink(currentLanguage) }}" target="_blank">{{ 'EXTERNAL_OFFER.text7_external_offer' | translate }}</a>{{ 'EXTERNAL_OFFER.text8_external_offer' | translate }}
    </div>
  </li>
</ol>
<div class="warning" *ngIf="endsWithS">{{ 'EXTERNAL_OFFER.warning1_external_offer' | translate }} {{ ioName }}{{ 'EXTERNAL_OFFER.warning2withs_external_offer' | translate }}</div>
<div class="warning" *ngIf="!endsWithS">{{ 'EXTERNAL_OFFER.warning1_external_offer' | translate }} {{ ioName }}{{ 'EXTERNAL_OFFER.warning2withouts_external_offer' | translate }}</div>
<div class="yellow-button" (click)="openIOWebsite(externalLink)">
  {{ 'EXTERNAL_OFFER.button_external_offer' | translate }}
  <img src="assets/icons/arrow-right-very-dark-grey.svg" alt="arrow-right">
</div>
