<div class="top-container">
  <div (click)="closeDialog()" class="close-button"></div>
  <div (click)="previousStep()" *ngIf="currentStep === 2" class="back-button">
    <div></div>
    <div>{{ 'VOLUNTEERING_TAB.go_back' | translate }}</div>
  </div>
</div>
<div class="applicant-steps">
  <div class="applicant-step activated"><span class="step-number">1</span>
    <div class="step-text">{{ 'VOLUNTEERING_TAB.your_information' | translate }}</div>
  </div>
  <div [class.activated]="currentStep >= 2" class="applicant-line"></div>
  <div [class.activated]="currentStep >= 2" class="applicant-step"><span class="step-number">2</span>
    <div class="step-text">{{ 'VOLUNTEERING_TAB.about_you' | translate }}</div>
  </div>
  <div class="applicant-line"></div>
  <div [class.activated]="currentStep === 3" class="applicant-step"><span class="step-number">3</span>
    <div class="step-text">{{ 'VOLUNTEERING_TAB.send_application' | translate }}</div>
  </div>
  <div class="applicant-dashed-line"></div>
  <div class="applicant-step grey"><span class="step-number">4</span>
    <div class="step-text grey">{{ 'VOLUNTEERING_TAB.confirm_volunteering_days' | translate }}</div>
  </div>
</div>
<div class="applicant-title" *ngIf="currentStep <= 2">{{ "VOLUNTEERING_TAB.almostdone_volunteeringtab" | translate }}</div>
<div class="applicant-form-wrapper">
  <ng-template [ngIf]="currentStep === 1">
    <form [formGroup]="applicantForm">
      <div class="form-grid">
        <label>
          <span>{{ "VOLUNTEERING_TAB.email_applicant" | translate }}</span>
          <span> *</span>
          <input formControlName="formEmail" placeholder="{{ userEmail }}" type="email">
        </label>
        <label>
          <span>{{ "VOLUNTEERING_TAB.name_applicant" | translate }}</span>
          <span> *</span>
          <input formControlName="formName" placeholder="{{ userName }}" type="text">
        </label>
        <label>
          <span>{{ "VOLUNTEERING_TAB.linkedin_applicant" | translate }}</span>
          <input [(ngModel)]="formLinkedin" formControlName="formLinkedin"
                 placeholder="https://www.linkedin.com/in/barackobama/"
                 type="text">
        </label>
      </div>
    </form>
  </ng-template>
  <ng-template [ngIf]="currentStep === 2">
    <form [formGroup]="applicantWhyForm">
      <div class="form-grid">
        <label>
          <span *ngIf="showIoName">{{ "VOLUNTEERING_TAB.why_applicant" | translate }} {{ ioName }}?</span>
          <span *ngIf="!showIoName">{{ "VOLUNTEERING_TAB.why_applicant" | translate }}?</span>
          <span> *</span>
          <textarea [(ngModel)]="userWhy" formControlName="userWhy"
                    placeholder="{{ 'VOLUNTEERING_TAB.why_applicant_placeholder' | translate }}"></textarea>
        </label>
      </div>
    </form>
  </ng-template>
</div>
<ns-button
  *ngIf="currentStep <= 2"
  (callback$)="currentStep === 1 ? nextStep() : lastStep()"
  [classes]="'w-100 ns-height-52 ns-font-26 ns-b-radius-10 ns-mt-40'"
  [disabled]="currentStep === 1 ? applicantForm.invalid : applicantWhyForm.invalid"
  [iconAlignedToBorder]="screenWidth > 767"
  [iconClasses]="'icon-12x19 ns-mr-16 ns-ml-10'"
  [iconRightSide]="true"
  [icon]="(currentStep === 1 ? applicantForm.invalid : applicantWhyForm.invalid) ?
            'assets/icons/arrow-right-disabled-grey.svg':
            'assets/icons/arrow-right-very-dark-grey.svg'"
  [text]="( currentStep === 1 ? 'VOLUNTEERING_TAB.next_button':'VOLUNTEERING_TAB.send_button') | translate"
  [type]="Constants.BUTTON_TYPE_DEFAULT_YELLOW_SOLID_M"
  class="w-100">
</ns-button>
<div *ngIf="currentStep === 3" class="step-3-wrapper">
  <div class="image-wrapper">
    <img src="assets/images/svg/application_sent.svg" alt="application-sent">
  </div>
  <div class="step-3-title">{{ "VOLUNTEERING_TAB.sent_applicant" | translate }}</div>
  <div *ngIf="showIoName" class="step-3-subtitle">
    {{ ioName }} {{ "VOLUNTEERING_TAB.subtitle_applicant" | translate }}
  </div>
  <div *ngIf="!showIoName" class="step-3-subtitle">
    {{ "VOLUNTEERING_TAB.next_end_applicant_noorg" | translate }}
  </div>
  <div class="step-3-content-1">{{ "VOLUNTEERING_TAB.remember_applicant" | translate }}</div>
  <div class="step-3-content-2">{{ "VOLUNTEERING_TAB.content2__applicant" | translate }}</div>
  <div class="step-3-square">
    <div class="step-3-square-title">{{ "VOLUNTEERING_TAB.square_title_volunteeringtab" | translate }}</div>
    <div class="step-3-square-text">
      {{ "VOLUNTEERING_TAB.square_text1_volunteeringtab" | translate }} {{ company }} {{ "VOLUNTEERING_TAB.square_text2_volunteeringtab" | translate }}
      <a href="{{ getTypeformLink(currentLanguage) }}" target="_blank">{{ "VOLUNTEERING_TAB.square_text3_volunteeringtab" | translate }}</a>
      {{ "VOLUNTEERING_TAB.square_text4_volunteeringtab" | translate }}
    </div>
  </div>
  <div class="step-3-button" (click)="closeDialog()">{{ 'VOLUNTEERING_TAB.close_volunteeringtab' | translate }}</div>
</div>
