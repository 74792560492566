<div class="subscribe-block">
  <div class="subscribe-heading">
    <img loading="lazy" src="/assets/icons/paper-airplane-banner.svg" alt="airplane">
    <span>{{ "SUBSCRIBE_BANNER.subscribe_subscribebanner" | translate }}</span>
  </div>
  <div class="subscribe-message">{{ "SUBSCRIBE_BANNER.updates_subscribebanner" | translate }}</div>
  <form [formGroup]="subscribeForm" class="subscribe-form" (ngSubmit)="subscribeForMailing()">
    <input type="email" placeholder="youremail@email.com" formControlName="email">
    <button class="subscribe" type="submit" [disabled]="subscribeForm.invalid">{{ "SUBSCRIBE_BANNER.button_subscribebanner" | translate }}</button>
  </form>
</div>
