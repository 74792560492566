import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Constants } from '../../../../app.constants';
import { RouterUrls } from '../../../../shared/router-urls';
import { ApiService } from '../../../../services/api.service';
import { catchError, map, tap } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-external-offer-dialog',
  templateUrl: './external-offer-dialog.component.html',
  styleUrls: ['./external-offer-dialog.component.scss']
})
export class ExternalOfferDialogComponent implements OnInit {
  readonly Constants = Constants;
  RouterUrls = RouterUrls;

  ioName: string;
  externalLink: string;
  endsWithS: boolean;
  offerId: number;
  userId: number;
  company: string;

  screenWidth: number;

  constructor(private dialogRef: MatDialogRef<ExternalOfferDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public application: any,
              private apiService: ApiService,
              private languageService: LanguageService
  ) {
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.ioName = this.application.ioName;
    this.endsWithS = this.ioName.slice(-1).toLowerCase() === 's';
    this.externalLink = this.application.externalLink;
    this.offerId = this.application.offerId;
    this.userId = this.application.userId;
    this.company = this.application.company;
  }

  openIOWebsite(url: string) {
    const data = {
      account_id: this.userId,
      offer_id: this.offerId
    };
    this.apiService.newExternalClick(data).subscribe();
    window.open(url, '_blank');
  }

  get currentLanguage() {
    return this.languageService.getUserUsedLanguage();
  }

  getTypeformLink(language: string) {
    if (language === 'es') {
      return 'https://noos.typeform.com/to/v5CHyp75';
    } else {
      if (language === 'en') {
        return 'https://noos.typeform.com/to/doKcHsMp';
      } else {
        return 'https://noos.typeform.com/to/H0KpiFYBz';
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
