import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Constants } from '../../../app.constants';
import { FiltersMetadata, FiltersValues } from '../../models/volunteering.model';
import { VolunteeringConstants } from '../../volunteering.constants';

@Component({
  selector: 'app-volunteering-mobile-filter-modal',
  templateUrl: './volunteering-mobile-filter-modal.component.html',
  styleUrls: ['./volunteering-mobile-filter-modal.component.scss']
})
export class VolunteeringMobileFilterModalComponent {
  public Constants = Constants;
  public VolunteeringConstants = VolunteeringConstants;
  public FILTER_IDS = VolunteeringConstants.FILTER_CRITERIA_IDS;
  _$filterOptionsChange: EventEmitter<FiltersMetadata>;

  @Output() optionChanges$ = new EventEmitter<{ filterCriteria: string, newFilterValues: any[] }>();
  @Output() applyAllFilters$ = new EventEmitter<FiltersValues>();
  @Input() set $filterOptionsChange(value: EventEmitter<FiltersMetadata>) {
    this._$filterOptionsChange = value;
    this._$filterOptionsChange.subscribe((filtersOptions: FiltersMetadata) => {
      this.currentFiltersOptions = filtersOptions;
    });
  }

  categoryIdsToFilterBy = [];
  languageIdsToFilterBy = [];
  formatIdsToFilterBy = [];
  locationIdsToFilterBy = [];
  durationIdsToFilterBy = [];
  dedicationIdsToFilterBy = [];

  counter = 0;

  currentFiltersOptions: FiltersMetadata;
  oldFiltersValues: FiltersValues;

  constructor(private bottomSheetRef: MatBottomSheetRef<VolunteeringMobileFilterModalComponent>,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: {
                filtersOptions: FiltersMetadata,
                filtersValues: FiltersValues
              }) {
    this.currentFiltersOptions = data.filtersOptions;
    this.oldFiltersValues = JSON.parse(JSON.stringify(data.filtersValues));
    this.setFilterValues(data.filtersValues);
  }

  onApplyFilterSelection(): void {
    const filtersValues: FiltersValues = {
      categoryIdsToFilterBy: this.categoryIdsToFilterBy,
      languageIdsToFilterBy: this.languageIdsToFilterBy,
      formatIdsToFilterBy: this.formatIdsToFilterBy,
      locationIdsToFilterBy: this.locationIdsToFilterBy,
      durationIdsToFilterBy: this.durationIdsToFilterBy,
      dedicationIdsToFilterBy: this.dedicationIdsToFilterBy
    };
    this.applyAllFilters$.next(filtersValues);
    this.bottomSheetRef.dismiss();
  }

  onRestoreFilters(): void {
    const filtersValues: FiltersValues = {
      categoryIdsToFilterBy: [],
      languageIdsToFilterBy: [],
      formatIdsToFilterBy: [],
      locationIdsToFilterBy: [],
      durationIdsToFilterBy: [],
      dedicationIdsToFilterBy: []
    };
    this.setFilterValues(filtersValues);
    this.applyAllFilters$.next(filtersValues);
  }

  onOptionChange(optionId, filterCriteria): void {
    switch (filterCriteria) {
      case this.FILTER_IDS.CATEGORY_ID:
        this.categoryIdsToFilterBy = this.addOrRemove(this.categoryIdsToFilterBy, optionId);
        this.optionChanges$.emit({filterCriteria, newFilterValues: this.categoryIdsToFilterBy});
        break;
      case this.FILTER_IDS.LANGUAGES_ID:
        this.languageIdsToFilterBy = this.addOrRemove(this.languageIdsToFilterBy, optionId);
        this.optionChanges$.emit({filterCriteria, newFilterValues: this.languageIdsToFilterBy});
        break;
      case this.FILTER_IDS.FORMATS_ID:
        this.formatIdsToFilterBy = this.addOrRemove(this.formatIdsToFilterBy, optionId);
        this.optionChanges$.emit({filterCriteria, newFilterValues: this.formatIdsToFilterBy});
        break;
      case this.FILTER_IDS.LOCATION_ID:
        this.locationIdsToFilterBy = this.addOrRemove(this.locationIdsToFilterBy, optionId);
        this.optionChanges$.emit({filterCriteria, newFilterValues: this.locationIdsToFilterBy});
        break;
      case this.FILTER_IDS.DURATION_ID:
        this.durationIdsToFilterBy = this.addOrRemove(this.durationIdsToFilterBy, optionId);
        this.optionChanges$.emit({filterCriteria, newFilterValues: this.durationIdsToFilterBy});
        break;
      case this.FILTER_IDS.DEDICATION_ID:
        this.dedicationIdsToFilterBy = this.addOrRemove(this.dedicationIdsToFilterBy, optionId);
        this.optionChanges$.emit({filterCriteria, newFilterValues: this.dedicationIdsToFilterBy});
        break;
    }
  }

  isChecked(value: any, array: any[]): boolean {
    return array.findIndex(val => val === value) !== -1;
  }

  // Returns an array with the item removed if it was present, or added if it was not.
  private addOrRemove(array, item): [] {
    const exists = array.includes(item);
    if (exists) {
      return array.filter((i) => {
        return i !== item;
      });
    } else {
      const result = array;
      result.push(item);
      return result;
    }
  }

  private setFilterValues(values: FiltersValues): void {
    this.categoryIdsToFilterBy = values.categoryIdsToFilterBy;
    this.languageIdsToFilterBy = values.languageIdsToFilterBy;
    this.formatIdsToFilterBy = values.formatIdsToFilterBy;
    this.locationIdsToFilterBy = values.locationIdsToFilterBy;
    this.durationIdsToFilterBy = values.durationIdsToFilterBy;
    this.dedicationIdsToFilterBy = values.dedicationIdsToFilterBy;
  }

  closeDialog(): void {
    this.applyAllFilters$.next(this.oldFiltersValues);
    this.bottomSheetRef.dismiss();
  }

}
