import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


import dayjs from 'dayjs';
import 'dayjs/locale/en.js';
import 'dayjs/locale/es.js';
import 'dayjs/locale/fr.js';
import { LanguageService } from 'src/app/services/language.service';
import { RouterUrls } from '../router-urls';


@Component({
  selector: 'app-notifications-card',
  templateUrl: './notifications-card.component.html',
  styleUrls: ['./notifications-card.component.scss']
})
export class NotificationsCardComponent implements OnInit {

  @Input() notifications = [];
  @Input() isMobile = false;
  @Output() clickedNotification$: EventEmitter<void> = new EventEmitter<void>();

  RouterUrls = RouterUrls;

  constructor(private languageService: LanguageService,
              private router: Router,
              private translate: TranslateService) {
  }

  _showNotifications = true;
  @Input() set showNotifications(value: boolean) {
    this._showNotifications = value;
    for (const notification of this.notifications) {
      notification.time_ago = dayjs(notification.date).fromNow();
    }
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe({
      next: () => {
        dayjs.locale(this.currentLanguage);
      }
    });
  }

  openPost(notification: any) {
    this.clickedNotification$.next();
    this.router.navigate([this.languageService.getCurrentLangWithSlash(), RouterUrls.Campaign, RouterUrls.CampaignPost, notification.id.toString()],
      { state: {notification:notification} });
  }

  get currentLanguage(): any {
    return this.languageService.getUserUsedLanguage();
  }
}
