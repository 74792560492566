<div *ngIf="!onboarding" class="close-button" (click)="closeDialog(false)"></div>
<div *ngIf="onboarding" class="onboarding-header">{{ "PREFERENCES_POPUP_1.almost_preferencespopup1" | translate }}</div>
<div *ngIf="onboarding" class="onboarding-progress">
  <div class="onboarding-unactivated onboarding-unactivated-1"></div>
  <div class="onboarding-1">1</div>
  <div class="onboarding-line onboarding-line-1"></div>
  <div class="onboarding-unactivated onboarding-unactivated-2"></div>
  <div class="onboarding-2">2</div>
  <div class="onboarding-line onboarding-line-2"></div>
  <div class="onboarding-activated"></div>
  <div class="onboarding-3">3</div>
</div>
<div class="title" [ngClass]="{'title-onboarding': onboarding}">{{ "PREFERENCES_POPUP_3.choose_preferencespopup3" | translate }}</div>
<div *ngIf="onboarding" class="onboarding-text">
  {{ "PREFERENCES_POPUP_3.text_preferencespopup3" | translate }}
</div>
<div class="select-container">
    <div class="select-text">{{ "PREFERENCES_POPUP_3.tax_preferencespopup3" | translate }}</div>
    <div class="select-wrapper">
        <select class="custom-select" [(ngModel)]="selectedCountry">
            <option *ngFor="let country of countries">{{country}}</option>
        </select>
    </div>
</div>
<div *ngIf="selectedCountry === 'France'" class="warning-wrapper" [ngClass]="{'warning-onboarding': !onboarding}">
  {{ "PREFERENCES_POPUP_3.hurray_preferencespopup3" | translate }}
</div>
<div *ngIf="selectedCountry !== 'France' && selectedCountry !== ''" class="warning-wrapper" [ngClass]="{'warning-onboarding': !onboarding}">
  {{ "PREFERENCES_POPUP_3.ouch_preferencespopup3" | translate }}
</div>

<div class="button-wrapper">
  <button [disabled]="selectedCountry === ''" (click)="onUpdate()">
    {{ (onboarding ? "PREFERENCES_POPUP_3.done_preferencespopup3" : "PREFERENCES_POPUP_1.update_preferencespopup1") | translate }}
    <img loading="lazy" *ngIf="onboarding" class="right-arrow" src="/assets/icons/arrow-right-very-dark-blue.svg" alt="right-arrow">
  </button>
</div>
