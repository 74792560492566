import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-subscribed-dialog',
  templateUrl: './subscribed-dialog.component.html',
  styleUrls: ['./subscribed-dialog.component.scss']
})
export class SubscribedDialogComponent implements OnInit {

  success: boolean;

  constructor(
    private dialogRef: MatDialogRef<SubscribedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
        this.success = data.success;
    }

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close({ event: 'close', data: [] });
  }
}
