import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { LanguageService } from '../services/language.service';
import { RouterUrls } from '../shared/router-urls';
import { Subscription } from 'rxjs';
import { TagsService } from '../services/tags.service';
import { Constants } from '../app.constants';

@Component({
  selector: 'app-vetting-process',
  templateUrl: './vetting-process.component.html',
  styleUrls: ['./vetting-process.component.scss']
})
export class VettingProcessComponent implements OnInit {

  clickEventSubscription: Subscription;
  RouterUrls = RouterUrls;

  constructor(
    private router: Router,
    private languageService: LanguageService,
    private titleService: Title,
    private meta: Meta,
    private tagsService: TagsService,
    private translate: TranslateService) {
      this.clickEventSubscription = this.tagsService.getClickEventVettingProcess().subscribe(() => {
        this.updateTags();
      });
    }

  ngOnInit(): void {
    this.updateTags();
  }

  onClick() {
    this.router.navigate([this.languageService.getCurrentLangWithSlash(), RouterUrls.ImpactOrganization]);
  }

  updateTags() {
    this.titleService.setTitle(this.translate.instant('VETTING_PROCESS.meta_title_vettingprocess'));
    this.meta.updateTag({name: 'description', content: this.translate.instant('VETTING_PROCESS.meta_description_vettingprocess')});
    this.meta.updateTag({property: 'og:title', content: this.translate.instant('VETTING_PROCESS.meta_title_vettingprocess')});
    this.meta.updateTag({property: 'og:description', content: this.translate.instant('VETTING_PROCESS.meta_description_vettingprocess')});
    this.meta.updateTag({property: 'og:type', content: 'URL'});
    this.meta.updateTag({property: 'og:url', content: window.location.href});
    this.meta.updateTag({property: 'og:image', content: Constants.APP_URL + 'assets/backgrounds/bg-vetting-process.webp'});
  }

}
