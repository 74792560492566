import { Component, OnDestroy } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../services/language.service';

/**
 *  BaseComponent serves as a foundational class designed to provide common functionalities
 *  to various components across the application.
 *  It includes:
 *  - Responsive design capabilities by monitoring viewport size changes and determining screen modes.
 *  - Language management features.
 *
 *  This class is equipped to be extended by other components that require responsive behavior and language
 *  management features.
 *
 *  Future utilities could be integrated here to maintain consistency and reduce code duplication.
 */
@Component({
  template: ''
})
export class BaseComponent implements OnDestroy {
  isMobile: boolean;
  /**
   * Represents the current screen mode based on width. 'mobile' for screens narrower than 768 pixels,
   * 'desktop' for screens wider than or equal to 768 pixels.
   */
  screenMode: 'mobile' | 'desktop';
  private subscription: Subscription;

  constructor(protected languageService: LanguageService,
              protected breakpointObserver: BreakpointObserver) {
    this.initScreenSize();
  }

  get currentLanguage(): any {
    return this.languageService.getUserUsedLanguage();
  }

  private initScreenSize() {
    this.subscription = this.breakpointObserver
      .observe(['(max-width: 767px)', '(min-width: 768px)'])
      .subscribe(result => {
        if (result.breakpoints['(max-width: 767px)']) {
          this.isMobile = true;
          this.screenMode = 'mobile';
        } else if (result.breakpoints['(min-width: 768px)']) {
          this.isMobile = false;
          this.screenMode = 'desktop';
        }
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
