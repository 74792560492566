<!-- DESKTOP CARD -->
<ng-container *ngIf="screenWidth > 767">
  <div [class]="classes" class="offer-card-container">
    <div [style.backgroundImage]="'url(' + offer.media + ')'" class="img-container"></div>
    <div class="content-container">
      <div class="top-buttons-container ns-mb-6">
        <div class="tags-container">
          <ng-container *ngFor="let tag of categoryTags">
            <span class="category-tag">
              {{ tag.tag[currentLang] }}
            </span>
          </ng-container>
        </div>
        <ns-button
          (callback$)="onShareButtonClick()"
          [classes]="'ns-line-height-12'"
          [iconClasses]="'icon-12x12 ns-mb-2'"
          [iconRightSide]="true"
          [icon]="'/assets/icons/paper-plane-send.svg'"
          [text]="'VOLUNTEERING_TAB.share' | translate | uppercase"
          [type]="Constants.BUTTON_TYPE_DEFAULT_DARK_BLUE_STROKE_T"
        >
        </ns-button>
      </div>
      <div [innerHTML]="offer?.title[currentLang]" class="card-title ns-mb-4"></div>
      <ng-container *ngIf="showIo">
        <div [innerHTML]="offer?.io?.name" class="card-metadata"></div>
      </ng-container>
      <ng-container *ngIf="!showIo">
        <div [innerHTML]="offer?.org_name" class="card-metadata"></div>
      </ng-container>
      <div *ngIf="offer?.duration" class="card-metadata">{{ offer?.duration[currentLang] }}</div>
      <div class="card-metadata">
        <ng-container *ngIf="offer?.city && offer?.city[currentLang]">
          {{offer.city[currentLang]}}</ng-container>
        <ng-container *ngIf="offer.country && offer?.city && offer.city[currentLang]">{{', '}}</ng-container>
        <ng-container *ngIf="offer.country">
          {{ 'COUNTRIES.' + offer.country | translate }}
        </ng-container>
        <ng-container *ngIf="formatTag">
          ({{formatTag?.tag[currentLang]}})
        </ng-container>
      </div>
      <div class="card-subtitle">{{ 'VOLUNTEERING_TAB.description_activities' | translate }}</div>
      <div [innerHTML]="offer?.description[currentLang]" class="card-text"></div>
      <div class="card-subtitle">{{'VOLUNTEERING_TAB.offer_requirements' | translate }}</div>
      <div class="card-text">
        <ng-container *ngIf="offer.requirements">
          <div [innerHTML]="offer.requirements[currentLang]"></div>
        </ng-container>
        <ng-container *ngIf="!offer.requirements">
          <div>{{ 'VOLUNTEERING_TAB.none' | translate }}</div>
        </ng-container>
      </div>
      <div class="card-subtitle">{{'VOLUNTEERING_TAB.application_deadline' | translate }}</div>
      <div class="card-text">{{offer.deadline_date ?
        offer.deadline_date :
        ('VOLUNTEERING_TAB.none' | translate)}}</div>
      <div class="card-subtitle">{{'VOLUNTEERING_TAB.volunteer_duration' | translate }}</div>
      <div class="card-text">{{offer.end_date ?
        ('VOLUNTEERING_TAB.until' | translate) + ' ' + (offer.end_date | date:'MMMM y':undefined : currentLang | titlecase) :
        ('VOLUNTEERING_TAB.none' | translate)}}</div>
      <div class="bottom-buttons-container ns-mt-10">
        <ns-button
          (callback$)="onLearnMoreButtonClick()"
          [classes]="'ns-width-140 ns-pr-10 ns-pl-10'"
          [text]="'VOLUNTEERING_TAB.learn_more' | translate"
          [type]="Constants.BUTTON_TYPE_DEFAULT_DARK_BLUE_STROKE_M">
        </ns-button>
        <ng-container *ngIf="!offer.hasApplied">
          <ns-button
            *ngIf="!offer.external"
            (callback$)="onApplyButtonClick(false)"
            [classes]="'ns-ml-12 ns-width-140 ns-pr-10 ns-pl-10'"
            [text]="'VOLUNTEERING_TAB.apply_button' | translate"
            [type]="Constants.BUTTON_TYPE_DEFAULT_YELLOW_SOLID_M">
          </ns-button>
          <ns-button
            *ngIf="offer.external"
            (callback$)="onApplyButtonClick(true)"
            [classes]="'ns-ml-12 ns-width-140 ns-pr-10 ns-pl-10'"
            [icon]="'assets/icons/external_link.svg'"
            [iconClasses]="'ns-ml-10'"
            [text]="'VOLUNTEERING_TAB.apply_button' | translate"
            [type]="Constants.BUTTON_TYPE_DEFAULT_YELLOW_SOLID_M">
          </ns-button>
        </ng-container>
        <ng-container *ngIf="offer.hasApplied">
          <div class="d-flex align-items-end ns-ml-12 ns-width-140 ns-height-39 font-alegreya-sans
           ns-font-14 ns-line-height-18 ns-font-w-500 ns-grey-8 ns-text-center">
            {{'VOLUNTEERING_TAB.applied_button_volunteeringtab' | translate}}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
<!-- MOBILE CARD -->
<ng-container *ngIf="screenWidth <= 767">
  <div [class]="classes" class="offer-card-container-mobile">
    <div class="img-container">
      <img [src]="offer.media" alt="image" loading="lazy">
      <div class="tags-container">
        <ng-container *ngFor="let tag of categoryTags">
            <span class="category-tag">
              {{ tag.tag[currentLang] }}
            </span>
        </ng-container>
      </div>
    </div>
    <div class="content-container">
      <div class="card-title ns-mb-8">{{offer?.title[currentLang]}}</div>
      <ng-container *ngIf="showIo">
        <div [innerHTML]="offer?.io?.name" class="card-metadata"></div>
      </ng-container>
      <ng-container *ngIf="!showIo">
        <div [innerHTML]="offer?.org_name" class="card-metadata"></div>
      </ng-container>
      <div class="d-flex justify-content-between">
        <div class="w-50">
          <div *ngIf="offer?.duration"
               class="card-metadata ns-mb-8 ns-pr-6">{{ offer?.duration[currentLang] }}</div>
        </div>
        <div class="w-50">
          <div class="card-metadata ns-mb-8 ns-text-right">
            <ng-container *ngIf="offer?.city && offer?.city[currentLang]">
              {{offer.city[currentLang]}}</ng-container>
            <ng-container *ngIf="offer.country && offer?.city && offer.city[currentLang]">{{', '}}</ng-container>
            <ng-container *ngIf="offer.country">
              {{'COUNTRIES.' + offer.country | translate}}
            </ng-container>
            <ng-container *ngIf="formatTag">
              <span style="white-space: nowrap;"> ({{formatTag?.tag[currentLang]}})</span>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="card-subtitle ns-font-13 ns-line-height-16">{{'VOLUNTEERING_TAB.volunteer_duration' | translate }}
        <span class="card-text">{{offer.end_date ?
          ('VOLUNTEERING_TAB.until' | translate) + ' ' + (offer.end_date | date:'MMMM y':undefined : currentLang | titlecase) :
          ('VOLUNTEERING_TAB.none' | translate)}}</span>
      </div>
      <div class="bottom-buttons-container ns-mt-23">
        <ns-button
          (callback$)="onLearnMoreButtonClick()"
          [classes]="'ns-height-42'"
          [styles]="'max-width: 160px'"
          [text]="'VOLUNTEERING_TAB.learn_more' | translate"
          [type]="Constants.BUTTON_TYPE_DEFAULT_YELLOW_SOLID_M">
        </ns-button>
        <ns-button
          (callback$)="onShareButtonClick()"
          [classes]="'ns-height-42 ns-p-15x12 ns-line-height-19 justify-content-center ns-font-12'"
          [iconClasses]="'icon-12x12 ns-mb-4'"
          [iconRightSide]="true"
          [icon]="'assets/icons/paper-plane-send.svg'"
          [text]="'VOLUNTEERING_TAB.share' | translate | uppercase"
          [type]="Constants.BUTTON_TYPE_DEFAULT_DARK_BLUE_STROKE_M">
        </ns-button>
      </div>
    </div>
  </div>
</ng-container>
