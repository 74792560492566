import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { LogEntry } from '../shared/models/frontend-log-entry/log-entry.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FrontendLogService {

  constructor(private apiService: ApiService) {
  }

  postLogEntry(logEntry: LogEntry) {
    logEntry.token = environment.backendLogToken;
    return this.apiService.postFrontendLogEntry(logEntry);
  }
}
