<h2 *ngIf="success">{{'SUBSCRIBED_MODAL.thank_subscribedmodal' | translate}}</h2>
<h2 *ngIf="!success">{{'SUBSCRIBED_MODAL.oups_subscribedmodal' | translate}}</h2>
<div *ngIf="success" class="confirmation-text">{{'SUBSCRIBED_MODAL.noosletter_subscribedmodal' | translate}}</div>
<div *ngIf="!success" class="confirmation-text">{{'SUBSCRIBED_MODAL.problem_subscribedmodal' | translate}}</div>
<div class="confirmation-icon">
  <img *ngIf="success" loading="lazy" src="assets/icons/checked-green.svg">
  <img *ngIf="!success" loading="lazy" src="assets/icons/cross-dark-blue.svg">
</div>
<button (click)="closeDialog()" class="green sans-serif">
  {{'SUBSCRIBED_MODAL.close_subscribedmodal' | translate}}
</button>
