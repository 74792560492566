export const environment = {
  production: true,
  staging: false,
  apiToken: 'rsnfdcuhesidufhcpqowexknsdbdvpamwdid78yfb42j',
  backendLogToken: '82wZ8IRGnzW16UaOeyn8ru76hNypPle2BTlFFYaiWbl4',
  stripePk: 'pk_live_51HYXEeLvLgKpg7yTCgHLYzveuq0qLORVCkCCyB9vB3KMiNn0CIdJeZ6DWaJ8495t3tueiKnftFHcJ2reRID8sBx900X8J4hdMm',
  hotjarSiteId: '2930629',
  oneSignalToken: '879994c3-2395-462a-b170-97ba1c301921',
  noosMsalClientId: '72f11357-007a-4f34-9785-bb73d9321d18',
  noosMsalTenantId: '8aba8181-85d7-4e1a-9bea-714d62284c93',
  noosMsalRedirectUri: 'https://app.noos.global/fr/log-in/',
};
