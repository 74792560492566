import { AfterViewInit, Component, OnInit, ViewChild, } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { RouterUrls } from '../../shared/router-urls';
import { TagsService } from 'src/app/services/tags.service';
import { LanguageService } from 'src/app/services/language.service';
import { Constants } from '../../app.constants';

declare global {
  interface Window {
    fcWidget?: any;
  }
}

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, AfterViewInit {

  direction: number;
  RouterUrls = RouterUrls;
  clickEventSubscription: Subscription;
  videosArray;
  videosLoaded = 0;
  @ViewChild('videoWrapper', {static: false}) videoWrapper;


  constructor(private titleService: Title,
              private languageService: LanguageService,
              private meta: Meta,
              private translate: TranslateService,
              private tagsService: TagsService) {
                this.updateTags();
                this.clickEventSubscription = this.tagsService.getClickEventLanding().subscribe(() => {
                  this.updateTags();
                });
  }

  ngAfterViewInit(): void {
    this.addVideoLoadListener();
  }
  addVideoLoadListener() {
    this.videosArray = this.videoWrapper.nativeElement.children;


    for (const video of this.videosArray) {
      video.addEventListener('loadedmetadata', () => {
        this.videosLoaded++;
        this.initialVideoPlay(this.videosArray[0], 15000);
      });
      video.preload = 'metadata';
    }
  }

  initialVideoPlay(videosArray: any, duration: number) {
    const videoElement = videosArray;
    videoElement.muted = true;
    videoElement.playsinline = true;
    videoElement.play();
    setTimeout(() => this.playNextVideo(videosArray, duration), duration);
  }


  playNextVideo(videosArray: any, duration: number) {
    const previousVideoElement = videosArray;
    const nextVideoElement = previousVideoElement;
    previousVideoElement.pause();
    previousVideoElement.currentTime = 0;
    nextVideoElement.muted = true;
    nextVideoElement.playsinline = true;
    nextVideoElement.play();
    setTimeout(() => this.playNextVideo(videosArray, duration), duration);
  }


  ngOnInit(): void {
    this.setupParallaxImage();
    this.updateTags();
    (document.querySelector('.slider') as HTMLElement).addEventListener('transitionend', () => {
      if (this.direction === 1) {
        (document.querySelector('.slider') as HTMLElement).prepend((document.querySelector('.slider') as HTMLElement).lastElementChild);
      } else {
        (document.querySelector('.slider') as HTMLElement).appendChild((
          document.querySelector('.slider') as HTMLElement).firstElementChild);
      }
      (document.querySelector('.slider') as HTMLElement).style.transition = 'none';
      (document.querySelector('.slider') as HTMLElement).style.transform = 'translate(0)';
      setTimeout(() => {
        (document.querySelector('.slider') as HTMLElement).style.transition = 'all 0.5s';
      });
    }, false);
  }

  next() {
    this.direction = -1;
    (document.querySelector('.carousel') as HTMLElement).style.justifyContent = 'flex-start';
    (document.querySelector('.slider') as HTMLElement).style.transform = 'translate(-20%)';
  }

  prev() {
    if (this.direction === -1) {
      this.direction = 1;
      (document.querySelector('.slider') as HTMLElement).appendChild((document.querySelector('.slider') as HTMLElement).firstElementChild);
    }
    (document.querySelector('.carousel') as HTMLElement).style.justifyContent = 'flex-end';
    (document.querySelector('.slider') as HTMLElement).style.transform = 'translate(20%)';
  }

  get currentLang() {
    return this.languageService.getCurrentLangWithSlash();
  }

  get currentLanguage() {
    return this.languageService.getUserUsedLanguage();
  }

  setupParallaxImage(): void {
    document.addEventListener('mousemove', parallax);

    function parallax(event) {
      this.querySelectorAll('.parallax-wrap img').forEach((shift) => {
        const position = shift.getAttribute('value');
        const x = (window.innerWidth - event.pageX * position) / 300;
        const y = (window.innerHeight - event.pageY * position) / 300;

        shift.style.transform = `translateX(${x}px) translateY(${y}px)`;
        shift.style.transition = `transform  0s cubic-bezier(0.55,-0.24, 0.17, 1.38)`;
      });
    }
  }

  sendPresentationMail(): void {
    switch (this.currentLanguage) {
      case Constants.FR_LANG_KEY:
        window.location.href = Constants.PRESENTATION_MAIL_CONTENT_FR;
        break;
      case Constants.EN_LANG_KEY:
        window.location.href = Constants.PRESENTATION_MAIL_CONTENT_EN;
        break;
      case Constants.ES_LANG_KEY:
        window.location.href = Constants.PRESENTATION_MAIL_CONTENT_ES;
        break;
    }
  }

  openChatWindow(): void {
    window.fcWidget.open();
    window.fcWidget.show();
  }

  updateTags() {
    this.titleService.setTitle(this.translate.instant('LANDING.meta_title_landing'));
    this.meta.updateTag({name: 'description', content: this.translate.instant('LANDING.meta_description_landing')});
    this.meta.updateTag({property: 'og:title', content: this.translate.instant('LANDING.meta_title_landing')});
    this.meta.updateTag({property: 'og:description', content: this.translate.instant('LANDING.meta_description_landing')});
    this.meta.updateTag({property: 'og:type', content: 'URL'});
    this.meta.updateTag({property: 'og:url', content: window.location.href});
    this.meta.updateTag({property: 'og:image', content: 'https://noos.ams3.digitaloceanspaces.com/meta%20_%20landing.png'});
  }
}
