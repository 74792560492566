<button
    (click)="onClick()"
    [class]="buttonClasses"
    [disabled]="disabled"
    [type]="buttonType ? buttonType : 'button'"
    [ngClass]="{'disabled' : disabled,
          'invisible' : invisible,
          'active': isActive,
          'position-relative':iconAlignedToBorder,
          'flex-row-reverse':!iconRightSide}"
    [style]="buttonStyles"
    type="button">
  <span [class.text-decoration-none]="noTextUnderline">{{ text }}</span>
  <div class="d-flex" [ngClass]="{
    'icon-align-to-border-container': iconAlignedToBorder,
    'justify-content-end': iconRightSide,
    'justify-content-start': !iconRightSide
    }">
    <img *ngIf="icon && !faIcon && !iconRightSide && !onlyIcon" [class]="iconClasses" [src]="icon" alt="icon" class="ns-mr-6">
    <img *ngIf="icon && !faIcon && iconRightSide && !onlyIcon" [class]="iconClasses" [src]="icon" alt="icon" class="ns-ml-6">
    <i *ngIf="faIcon && !icon && !iconRightSide && !onlyIcon" [class]="iconClasses"></i>
    <i *ngIf="faIcon && !icon && iconRightSide && !onlyIcon" [class]="iconClasses"></i>
  </div>
  <img *ngIf="icon && !faIcon && onlyIcon" [class]="iconClasses" [src]="icon" alt="icon">
</button>
