import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ProjectVolunteeringOffer } from '../../../shared/projects.model';
import { VolunteeringConstants } from '../../volunteering.constants';
import { Constants } from '../../../app.constants';
import { FilterTag } from '../../models/volunteering.model';
import { MatDialogRef } from '@angular/material/dialog';
import {
  ApplicantDialogComponent
} from '../../../browse-projects-module/project/project-volunteering/applicant-dialog/applicant-dialog.component';
import { ModalConstants } from '../../../shared/services/modal/modal.constants';
import { ModalService } from '../../../shared/services/modal/modal.service';
import { User } from '../../../shared/user.model';
import { VolunteeringShareModalComponent } from '../volunteering-share-modal/volunteering-share-modal.component';
import { Router } from '@angular/router';
import { VolunteeringApplication } from '../../models/volunteering-application.model';
import {
  ExternalOfferDialogComponent
} from 'src/app/browse-projects-module/project/project-volunteering/external-offer-dialog/external-offer-dialog.component';

@Component({
  selector: 'app-volunteering-card-modal',
  templateUrl: './volunteering-card-modal.component.html',
  styleUrls: ['./volunteering-card-modal.component.scss']
})
export class VolunteeringCardModalComponent implements OnInit, AfterViewInit {
  readonly VolunteeringConstants = VolunteeringConstants;
  readonly Constants = Constants;

  @Input() classes = '';
  @Input() offer: ProjectVolunteeringOffer;
  @Input() user: User;
  @Input() currentLang = 'fr';

  categoryTags: FilterTag[] = [];
  formatTag: FilterTag;

  screenWidth: number;
  screenHeight: number;
  showIo: boolean;


  constructor(
    private dialogRef: MatDialogRef<VolunteeringCardModalComponent>,
    private modal: ModalService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.showIo = this.offer?.io?.name !== VolunteeringConstants.NO_PROJECT_OFFER_KEY;
  }

  ngAfterViewInit() {
    if (this.offer && this.offer.tags.length > 0) {
      this.offer.tags.forEach((tag: FilterTag) => {
        if (tag.filter === VolunteeringConstants.CATEGORY_KEY) {
          this.categoryTags.push(tag);
        }
        if (tag.filter === VolunteeringConstants.FORMAT_KEY) {
          this.formatTag = tag;
        }
      });
    }
    this.router.navigate([], {
      queryParams: {
        'offer-id': this.offer?.id
      },
      queryParamsHandling: 'merge',
    });
  }

  onApplyButtonClick(external: boolean): void {
    let ioName: string;
    if (this.showIo) {
      ioName = this.offer.io.name;
    } else {
      ioName = this.offer.org_name;
    }
    if (external) {
      const modalData = {
        ioName: ioName,
        externalLink: this.offer.external_link,
        userId: this.user.id,
        offerId: this.offer.id,
        company: this.user.company_name
      };
      const modalHeight = window.innerWidth > 767 ? 579 : window.innerHeight;
      const modalWidth = window.innerWidth > 767 ? 600 : window.innerWidth;
      this.modal.openFixedModal(modalData, ExternalOfferDialogComponent,
        window.innerWidth > 767 ? ModalConstants.DEFAULT_PANEL_CLASS : ModalConstants.DEFAULT_FULLSCREEN_PANEL_CLASS,
        modalWidth);
    } else {
      const modalData: VolunteeringApplication = {
        email: this.user.email,
        name: this.user.name + ' ' + this.user.surname,
        userId: this.user.id,
        projectId: this.offer.id,
        ioName: ioName,
        company: this.user.company_name
      };
      const modalHeight = window.innerWidth > 767 ? 636 : window.innerHeight;
      const modalWidth = window.innerWidth > 767 ? 600 : window.innerWidth;
      this.modal.openFixedModal(modalData, ApplicantDialogComponent,
        window.innerWidth > 767 ? ModalConstants.DEFAULT_PANEL_CLASS : ModalConstants.DEFAULT_FULLSCREEN_PANEL_CLASS,
        modalWidth, modalHeight);
    }
  }

  onShareButtonClick(): void {
    const modalMinHeight = this.screenWidth > 767 ? 466 : this.screenHeight;
    const modalMinWidth = this.screenWidth > 767 ? 510 : this.screenWidth;
    this.modal.openAdaptableModal({user: this.user, offerId: this.offer.id}, VolunteeringShareModalComponent,
      window.innerWidth > 767 ? ModalConstants.DEFAULT_PANEL_CLASS : ModalConstants.DEFAULT_FULLSCREEN_PANEL_CLASS,
      modalMinWidth, modalMinHeight);
  }

  closeDialog(): void {
    this.router.navigate([], {
      queryParams: {
        'offer-id': null
      },
      queryParamsHandling: 'merge',
    });
    this.dialogRef.close();
  }

}
