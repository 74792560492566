<div class="title-block">
  <div class="title">
    <i class="fa-solid fa-angle-left" (click)="closeModal()"></i>
    {{ 'HEADER.notifications_header' | translate }}</div>
</div>
<div class="d-flex">
  <app-notifications-card
      [isMobile]="true"
      [notifications]="notifications"
      [showNotifications]="true"
      (clickedNotification$)="closeModal()">
  </app-notifications-card>
</div>
