import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LacService } from 'src/app/services/lac.service';
import { LanguageService } from 'src/app/services/language.service';
import { LacPublicProfile } from '../../models/common.model';
import { RouterUrls } from '../../../shared/router-urls';
import { Router } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ModalService } from '../../../shared/services/modal/modal.service';
import { Constants } from '../../../app.constants';
import { CampaignConstants } from '../../campaign.constants';

@Component({
  selector: 'app-user-public-modal',
  templateUrl: './user-public-modal.component.html',
  styleUrls: ['./user-public-modal.component.scss']
})
export class UserPublicModalComponent implements OnInit {
  readonly Constants = Constants;
  readonly CampaignConstants = CampaignConstants;

  isLoading = true;
  isMobile = false;
  @Input() accountId: number;
  @Input() modalTriggerContext = CampaignConstants.NAV_CONTEXT_USER_PUBLIC_MODAL_CAMPAIGN;
  selectorSelected = 'challenges';
  challengesToggle = [];
  learningContentToggle = [];
  userData: LacPublicProfile;


  constructor(private dialogRef: MatDialogRef<UserPublicModalComponent>,
              private lacService: LacService,
              private router: Router,
              private languageService: LanguageService,
              private breakpointObserver: BreakpointObserver,
              private modal: ModalService,
              @Inject(MAT_DIALOG_DATA) data) {
  }

  get currentLanguage() {
    return this.languageService.getUserUsedLanguage();
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 767px)'])
      .subscribe((stateVar: BreakpointState) => {
        this.isMobile = stateVar.matches;
      });
    this.getUserData();
  }

  getUserData() {
    this.lacService.getUserPublic(this.accountId).subscribe({
      next: (data: LacPublicProfile) => {
        this.userData = data;
        for (let i = 0; i < this.userData.challenges.length; i++) {
          this.challengesToggle.push(true);
        }
        for (let i = 0; i < this.userData.learning.length; i++) {
          this.learningContentToggle.push(true);
        }
        if (!this.userData.image) {
          this.onImageError();
        }
        this.isLoading = false;
      }
    });
  }

  onImageError() {
    this.userData.image = '/assets/icons/no-avatar.png';
  }

  closeModal() {
    this.dialogRef.close();
  }

  selectSelector(value: string) {
    this.selectorSelected = value;
  }

  toggleCampaignChallenges(i: number) {
    this.challengesToggle[i] = !this.challengesToggle[i];
  }

  toggleCampaignLearningContent(i: number) {
    this.learningContentToggle[i] = !this.learningContentToggle[i];
  }

  openChallengePage(slug: string): void {
    this.router.navigate([this.languageService.getCurrentLangWithSlash(), RouterUrls.Campaign, RouterUrls.CampaignChallenge, slug],
      {
        queryParams: {
          [CampaignConstants.QPARAM_NAV_CONTEXT]: this.modalTriggerContext,
          [CampaignConstants.QPARAM_ID]: this.accountId
        }
      });
    this.closeModal();
  }

  onCloseModalWithActivityCompletion(closeReason: string): void {
    switch (closeReason) {
      case CampaignConstants.ACTIVITY_CLOSED_BY_COMPLETION:
        this.isLoading = true;
        this.getUserData();
        break;
      case CampaignConstants.ACTIVITY_CLOSED_BY_INTERRUPTION:
        break;
    }
  }
}
