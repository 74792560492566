import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ApiService } from './api.service';
import * as User from '../shared/user.model';
import { AccountAuthType, SsoCredentials } from '../shared/user.model';
import { UserService } from './user.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RouterUrls } from '../shared/router-urls';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '../app.constants';
import { LanguageService } from './language.service';
import { ApiUrls } from '../shared/api-urls';
import { CountryDialogComponent } from '../profile-module/profile/country-dialog/country-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private isLogged = new BehaviorSubject<boolean>(false);
  private currentUser$ = new BehaviorSubject<User.User>(null);
  public onLogoutEvent$: EventEmitter<void> = new EventEmitter<void>();


  constructor(private apiService: ApiService,
              private userService: UserService,
              private dialog: MatDialog,
              private router: Router,
              private route: ActivatedRoute,
              private languageService: LanguageService) {
  }

  signUp(userCredentials): Observable<User.User> {
    return this.apiService.registerNewUser(userCredentials)
      .pipe(
        tap((user: User.User) => {
          this.onSuccessfulAuthentication(user);
        })
      );
  }

  ssoSignUp(userCredentials: SsoCredentials): Observable<User.User> {
    return this.apiService.ssoSignupVerification(userCredentials)
      .pipe(
        tap((user: User.User) => {
          this.onSuccessfulAuthentication(user);
        })
      );
  }

  logIn(userCredentials): Observable<User.User> {
    return this.apiService.logInUser(userCredentials)
      .pipe(
        tap((user: User.User) => {
          this.onSuccessfulAuthentication(user);
        })
      );

  }

  ssoLogin(userCredentials: SsoCredentials): Observable<User.User> {
    return this.apiService.ssoVerification(userCredentials)
      .pipe(
        tap((user: User.User) => {
          this.onSuccessfulAuthentication(user);
        })
      );
  }

  getAccountAuthType(userEmail: object): Observable<AccountAuthType> {
    return this.apiService.getAccountAuthType(userEmail);
  }

  getCurrentUser(): Observable<User.User> {
    return this.apiService.getCurrentUser()
      .pipe(
        tap(value => {
          this.onSuccessfulAuthentication(value);
        }),
        catchError(err => {
          this.logOutUser().subscribe();
          return throwError(err);
        })
      );
  }

  logOutUser() {
    this.onLogoutEvent$.emit();
    return this.apiService.logOutUser()
      .pipe(
        tap(() => {
            this.onLogout();
          }
        ),
        catchError(err => {
          this.onLogout();
          return throwError(err);
        })
      );
  }

  public get currentUserValue(): User.User {
    return this.currentUser$?.value;
  }

  resetPassword(email: string) {
    return this.apiService.resetUserPassword(email);
  }

  isPledge(): Observable<boolean> {
    return this.getCurrentUser()
      .pipe(
        map(value => {
            return value.pledge_id !== null;
          }
        ),
        catchError(() => of(false)),
      );
  }

  hasSubscription(): Observable<boolean> {
    return this.getCurrentUser()
      .pipe(
        map(value => value ? value.has_sub : false),
        catchError(() => of(false)),
      );
  }

  hasPaymentMethod(): Observable<boolean> {
    return this.getCurrentUser()
      .pipe(
        map(value => value ? value.has_payment_method : false)
      );
  }

  get isUserLogged(): Observable<boolean> {
    return this.isLogged.asObservable();
  }

  get currentUser(): Observable<User.User> {
    return this.currentUser$.asObservable();
  }

  get token(): string {
    return localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')).token : '';
  }

  /**
   * Handles redirection after authentication success based on user action and state.
   * @param user The authenticated user object.
   * @param isLogin if regular login, we set the action like this
   */

  handleAuthentication(user: User.User, isLogin?: boolean): void {
    if (!user.action) {
      user.action = isLogin ? 'login' : 'signup';
    }

    switch (user.action) {
      case 'signup':
        user.lac_only ?
          this.router.navigate([this.languageService.getCurrentLangWithSlash(), RouterUrls.Home]) :
          this.openSignupModals();
        break;
      case 'login':
        this.handleLogin(user);
        break;
      default:
        console.warn('AUTH_ERROR: Unhandled user action');
    }
  }

  openSignupModals() {
   const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '907px';
    dialogConfig.disableClose = true;
    dialogConfig.maxWidth = '907px';
    dialogConfig.closeOnNavigation = true;
    dialogConfig.panelClass = 'country-dialog';
    dialogConfig.backdropClass = 'backdropBackground';
    dialogConfig.data = {
      onboarding: true
    };

    this.dialog.open(CountryDialogComponent, dialogConfig);
  }

  goToSAMLAuth(): void {
    window.location.href = ApiUrls.SAMLLogin;
  }

  public onSuccessfulAuthentication(user: User.User): void {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.userService.setUser(user);
    this.currentUser$.next(user);
    this.isLogged.next(true);
  }

  /**
   * Handles the routing logic for a user who has logged in.
   * @param user The authenticated user object.
   */
  private handleLogin(user: User.User): void {
    // Prioritize return URL from queryParams
    const returnUrl = this.route.snapshot.queryParams[Constants.RETURN_URL_PARAM_KEY];
    if (returnUrl) {
      this.router.navigateByUrl(decodeURIComponent(returnUrl));
      return;
    }

    // Handle custom redirections for users with specific roles or needs
    if (user.custom_redirect === Constants.LOGIN_REDIRECTION_COMPANY_KEY) {
      const navigationPath = user.company_slug ?
        [this.languageService.getCurrentLangWithSlash(), RouterUrls.Brands, user.company_slug] :
        [this.languageService.getCurrentLangWithSlash(), RouterUrls.Home];
      this.router.navigate(navigationPath);
      return;
    }

    // Additional conditional routing based on the presence of certain attributes
    if ('redirect' in this.route.snapshot.queryParams) {
      this.router.navigate([this.languageService.getCurrentLangWithSlash(), RouterUrls.YourImpact]);
    } else if (user.pledge_id !== null) {
      this.router.navigate([this.languageService.getCurrentLangWithSlash(), RouterUrls.Home]);
    } else {
      this.router.navigate([this.languageService.getCurrentLangWithSlash(), RouterUrls.BrowseProjects]);
    }
  }

  private onLogout(): void {
    this.isLogged.next(false);
    this.userService.setUser(null);
    this.currentUser$.next(null);
    localStorage.removeItem('currentUser');
  }
}
