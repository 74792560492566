<div (click)="closeDialog()" class="ns-close-button"></div>
<div class="modal-container">
  <div class="title">{{ "VOLUNTEERING_TAB.share_volunteering_offer" | translate }}</div>
  <div class="info-text">{{ "VOLUNTEERING_TAB.share_volunteering_offer_info" | translate }}</div>
  <div class="search-input">
    <span class="search-icon">
      <i [class.ns-green]="isSearchFocusActive"
         [class.ns-light-grey]="!isSearchFocusActive && !isSearchDropdownOpen()"
         class="fa-solid fa-magnifying-glass"
      ></i>
    </span>
    <input
      (focusin)="isSearchFocusActive = true; showSearchDropdown = true;"
      (focusout)="isSearchFocusActive = false"
      [class.active]="isSearchDropdownOpen()"
      [formControl]="searchInput" [placeholder]="'VOLUNTEERING_TAB.search_for_people' | translate"
      type="text"
    >
    <div *ngIf="!isMobile && userContactsToShare.length > 0"
         [class.tags-container]="userContactsToShare.length > 1"
         [class.transparent-tags-container]="userContactsToShare.length === 1">
    <span *ngFor="let contact of userContactsToShare;">{{ contact.name }} {{ contact.surname }}
      <i (click)="onRemoveContactToShare(contact)" class="fa-solid fa-circle-xmark remove-button"></i></span>
    </div>
    <div *ngIf="isMobile" class="separator"></div>
    <div *ngIf="isSearchDropdownOpen()" class="search-input-dropdown">
      <label (click)="onAddContactToShare(contact)"
             (mouseout)="contact.addButton = false" (mouseover)="contact.addButton = true"
             *ngFor="let contact of filteredUserContacts"
             [class.bg-very-light-green]="contact.addButton" class="search-input-label">
        <input [value]="contact.email" type="radio"/>
        <div class="profile-picture-container">
          <img [alt]="contact.name" [src]="contact.profile_picture === 1 ?  noProfilePicture: contact.profile_picture">
        </div>
        <div class="contact-details">
          <div class="contact-detail-name">
            {{contact.name}} {{contact.surname}}
          </div>
        </div>
        <div [class.d-none]="!contact.addButton" class="add-button-container">
          <ns-button
            [classes]="'ns-height-24 ns-p-12x5 ns-font-12 ns-line-height-14 ns-font-w-700 ns-ml-8'"
            [text]="'GENERAL.add' | translate | uppercase"
            [type]="Constants.BUTTON_TYPE_DEFAULT_DARK_BLUE_SOLID_S"
          >
          </ns-button>
        </div>
      </label>
    </div>
  </div>
  <ng-container *ngIf="!isMobile">
    <div class="field-title">{{ "VOLUNTEERING_TAB.share_volunteering_write_message" | translate }}</div>
    <textarea [formControl]="userMessageTextarea" class="textarea-message"
              placeholder="{{'GENERAL.message' | translate}}" rows="5">
    </textarea>
  </ng-container>
  <div *ngIf="isMobile" class="list-container">
    <ng-container *ngIf="!isDataReady">
      <div class="spinner-container">
        <img src="/assets/icons/new-loading.gif"/>
      </div>
    </ng-container>
    <ng-container *ngIf="isDataReady">
      <label (click)="onCheckRadioButton(contact)"
             *ngFor="let contact of filteredUserContacts;"
             [style]="contact.checked && contact.foundWithSearchBar ? 'order: 1;':'order: 2;'" class="list-element">
        <div class="profile-picture-container">
          <img [alt]="contact.name" [src]="contact.profile_picture === 1 ?  noProfilePicture: contact.profile_picture">
        </div>
        <div class="contact-details">
          <div class="contact-detail-name">{{contact.name}} {{contact.surname}}</div>
        </div>
        <div [class.checked]="contact.checked"
             class="radio-button">
          <i *ngIf="contact.checked" class="fa-solid fa-check ns-white"></i></div>
      </label>
    </ng-container>
  </div>
  <ng-container *ngIf="!isMobile">
    <div class="button-container">
      <small *ngIf="noContactSelected" class="error-message">
        {{ 'VOLUNTEERING_TAB.no_person_selected_message' | translate }}</small>
      <small *ngIf="maxContactNumberReached" class="error-message ns-mt-4">
        {{ 'VOLUNTEERING_TAB.max_contacts_to_share' | translate  }}</small>
      <ns-button
        (callback$)="shareVolunteeringOffer()"
        [classes]="'w-100 ns-height-50 ns-font-26 ns-font-w-800 ns-b-radius-10'"
        [iconAlignedToBorder]="true"
        [iconClasses]="'icon-19x19 ns-mr-16 ns-ml-10'"
        [iconRightSide]="true"
        [icon]="'assets/icons/arrow-right-very-dark-grey.svg'"
        [text]="'GENERAL.send_via_email' | translate"
        [type]="Constants.BUTTON_TYPE_DEFAULT_YELLOW_SOLID_M"
        class="w-100">
      </ns-button>
    </div>
  </ng-container>
  <ng-container *ngIf="isMobile">
    <div class="button-container-mobile">
      <div class="field-title">{{ "VOLUNTEERING_TAB.share_volunteering_write_message" | translate }}</div>
      <input [formControl]="userMessageInput" class="input-message" placeholder="{{'GENERAL.message' | translate}}">
      <small *ngIf="noContactSelected"
             class="error-message">{{ 'VOLUNTEERING_TAB.no_person_selected_message' | translate }}</small>
      <small *ngIf="maxContactNumberReached"
             class="error-message">{{ 'VOLUNTEERING_TAB.max_contacts_to_share' | translate }}</small>
      <ns-button
        (callback$)="shareVolunteeringOffer()"
        [classes]="'w-100 ns-height-47 ns-font-26 ns-font-w-800 ns-b-radius-10'"
        [iconAlignedToBorder]="true"
        [iconClasses]="'icon-19x19 ns-mr-16 ns-ml-10'"
        [iconRightSide]="true"
        [icon]="'assets/icons/arrow-right-very-dark-grey.svg'"
        [text]="'GENERAL.send_via_email' | translate"
        [type]="Constants.BUTTON_TYPE_DEFAULT_YELLOW_SOLID_M"
        class="w-100">
      </ns-button>
    </div>
  </ng-container>
</div>


