import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FullImpactResponse } from '../shared/impact.model';
import { ApiService } from './api.service';
import { HomePageInfo } from '../home-module/home.models';
import { DonationListResponse, PaymentInfo, ProfileSettings, User, UserSubscription } from '../shared/user.model';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userSubject = new BehaviorSubject<User | null>(null);

  constructor(private apiService: ApiService) {
  }

  /**
   * Sets the current user of the application. If the user is set to `null`, it indicates that the user has been logged out.
   * @param user The user object to set as the current user, or `null` to log out the current user.
   */
  setUser(user: User | null): void {
    this.userSubject.next(user);
  }

  /**
   * Returns an Observable of the current user. If the value is `null`, it indicates that no user is currently logged in.
   * @returns An Observable emitting the current user object or `null` if no user is logged in.
   */
  getUser(): Observable<User | null> {
    return this.userSubject.asObservable();
  }

  getProfileSettings(): Observable<ProfileSettings> {
    return this.apiService.getProfileSettings();
  }

  getDonations(): Observable<DonationListResponse> {
    return this.apiService.getDonations();
  }

  getFullImpact(): Observable<FullImpactResponse> {
    return this.apiService.getFullImpact();
  }

  getProfilePicture(): Observable<string> {
    return this.apiService.getProfilePicture();
  }

  uploadProfilePicture(picture: File): Observable<string> {
    return this.apiService.uploadProfilePicture(picture);
  }

  getUserSubscription(): Observable<UserSubscription> {
    return this.apiService.getUserSubscription();
  }

  getUserLastFourDigits(): Observable<PaymentInfo> {
    return this.apiService.getUserLastFourDigits();
  }

  saveCauses(causes: string[]) {
    return this.apiService.saveCauses(causes);
  }

  saveContinents(continents: string[]) {
    return this.apiService.saveContinents(continents);
  }

  saveCountry(country: string) {
    return this.apiService.saveCountry(country);
  }

  editPaymentMethod(
    cardNumber: string,
    expMonth: string,
    expYear: string,
    cvc: string): Observable<any> {
    return this.apiService.editPaymentMethod(cardNumber, expMonth, expYear, cvc);
  }

  subscribeForMailing(email: string, language: string) {
    return this.apiService.subscribeForMailing(email, language);
  }

  getHomePageInfo(user: User): Observable<HomePageInfo> {
    return this.apiService.getHomePageInfo(user);
  }

}
