export const FooterConstants = Object.freeze({

  WHO_WE_ARE_CODE: 0,
  BLOG_CODE: 1,
  HOME_CODE: 2,
  RSE_TOOL_CODE: 3,
  EXPERTISE_IMPACT_CODE: 4,
  GET_A_DEMO_CODE: 5,
  CARBON_COMPENSATION_CODE: 6,
  CSR_WITH_OUR_CLIENTS_CODE: 7,
  OUR_ENGAGED_COMPANIES_CODE: 8,
  ABOUT_NOOS_CODE: 9,
  TERMS_AND_CONDITIONS_CODE: 10,
  PRIVACY_POLICY_CODE: 11

});
