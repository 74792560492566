<div class="vetting-process-header">
    <div class="top-info">
        <div class="bg-image"></div>
        <div class="container-xl custom-container">
            <div class="top-wrapper">
                <h1>{{ "VETTING_PROCESS.main_title_vettingprocess" | translate }}</h1>
                <h2>{{ "VETTING_PROCESS.main_subtitle_vettingprocess" | translate }}</h2>
            </div>
        </div>
    </div>
</div>
<div class="body-section">
    <div class="section-title">
        <div class="title-wrapper">
            {{ "VETTING_PROCESS.title_first_vettingprocess" | translate }}
            <br>{{ "VETTING_PROCESS.title_second_vettingprocess" | translate }}
        </div>
    </div>
    <div class="section-subtitle">
        {{ "VETTING_PROCESS.subtitle2_first_vettingprocess" | translate }}
        <br><span [innerHTML]="'VETTING_PROCESS.subtitle2_second_vettingprocess' | translate"></span>
    </div>
    <div class="heading first-heading">{{ "VETTING_PROCESS.heading_vettingprocess" | translate }}</div>
    <div class="reasons-container">
        <div class="reason-wrapper">
            <div class="reason-title">
                {{ "VETTING_PROCESS.impact_vettingprocess" | translate }}
            </div>
            <div class="reason-logo">
                <img loading="lazy" src="assets/icons/small-hummingbird.png" alt="impact">
            </div>
            <div class="reason-text">
                {{ "VETTING_PROCESS.impact_text1_vettingprocess" | translate }}
            </div>
        </div>
        <div class="reason-wrapper">
            <div class="reason-title">
                {{ "VETTING_PROCESS.proximity_vettingprocess" | translate }}
            </div>
            <div class="reason-logo">
                <img loading="lazy" src="assets/icons/localization.png" alt="proximity">
            </div>
            <div class="reason-text">
                {{ "VETTING_PROCESS.proximity_text_vettingprocess" | translate }}
            </div>
        </div>
        <div class="reason-wrapper">
            <div class="reason-title">
                {{ "VETTING_PROCESS.agile_vettingprocess" | translate }}
            </div>
            <div class="reason-logo">
                <img loading="lazy" src="assets/icons/agile.png" alt="agile">
            </div>
            <div class="reason-text">
                {{ "VETTING_PROCESS.agile_text_vettingprocess" | translate }}
            </div>
        </div>
        <div class="reason-wrapper">
            <div class="reason-title">
                {{ "VETTING_PROCESS.financial_vettingprocess" | translate }}
            </div>
            <div class="reason-logo">
                <img loading="lazy" src="assets/icons/efficiency.png" alt="efficiency">
            </div>
            <div class="reason-text">
                {{ "VETTING_PROCESS.financial_text_vettingprocess" | translate }}
            </div>
        </div>
        <div class="reason-wrapper">
            <div class="reason-title">
                {{ "VETTING_PROCESS.communication_vettingprocess" | translate }}
            </div>
            <div class="reason-logo">
                <img loading="lazy" src="assets/icons/communication.png" alt="communication">
            </div>
            <div class="reason-text">
                {{ "VETTING_PROCESS.communication_text_vettingprocess" | translate }}
            </div>
        </div>
        <div class="reason-wrapper">
            <div class="last-text">
                {{ "VETTING_PROCESS.more_vettingprocess" | translate }}
            </div>
        </div>
    </div>
    <div class="heading second-heading">{{ "IMPACT_PAGE.faq_impactpage" | translate }}</div>
    <div class="faq-block">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "VETTING_PROCESS.q1_vettingprocess" | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>{{ "VETTING_PROCESS.a1_vettingprocess" | translate }}</p>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "VETTING_PROCESS.q2_vettingprocess" | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>{{ "VETTING_PROCESS.a2_vettingprocess" | translate }}</p>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "VETTING_PROCESS.q3_vettingprocess" | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>{{ "VETTING_PROCESS.a3_vettingprocess" | translate }}</p>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "VETTING_PROCESS.q4_vettingprocess" | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>{{ "VETTING_PROCESS.a4_vettingprocess" | translate }}</p>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "VETTING_PROCESS.q5_vettingprocess" | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>{{ "VETTING_PROCESS.a5_vettingprocess" | translate }}</p>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "VETTING_PROCESS.q6_vettingprocess" | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>{{ "VETTING_PROCESS.a6_vettingprocess" | translate }}</p>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "VETTING_PROCESS.q7_vettingprocess" | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>{{ "VETTING_PROCESS.a7_vettingprocess" | translate }}</p>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "VETTING_PROCESS.q8_vettingprocess" | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>{{ "VETTING_PROCESS.a8_vettingprocess" | translate }}</p>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "VETTING_PROCESS.q9_vettingprocess" | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>{{ "VETTING_PROCESS.a9_vettingprocess" | translate }}</p>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "VETTING_PROCESS.q10_vettingprocess" | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>{{ "VETTING_PROCESS.a10_vettingprocess" | translate }}</p>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ "VETTING_PROCESS.q11_vettingprocess" | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>{{ "VETTING_PROCESS.a11_vettingprocess" | translate }}</p>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
</div>
<div class="ngo-section">
    <div class="ngo-wrapper">
        <div class="ngo-section-title">
            {{ "VETTING_PROCESS.are_you_vettingprocess" | translate }}
            <br>{{ "VETTING_PROCESS.ngo_vettingprocess" | translate }}
        </div>
        <div class="ngo-section-subtitle" (click)="onClick()">
            {{ "VETTING_PROCESS.howto_vettingprocess" | translate }}
        </div>
    </div>
</div>

<app-subscribe-banner></app-subscribe-banner>
