<div class="card-container">
  <div class="card-item">
    <h2>{{ "RESET_PASSWORD_PAGE.reset_password_resetpassword" | translate }}</h2>

    <form *ngIf="!isMessageSent" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
      <div class="form-grid">
        <label><span>{{ "RESET_PASSWORD_PAGE.email_resetpassword" | translate }}</span><span> *</span>
          <input type="email" placeholder="example@mail.com" formControlName="email">
        </label>
      </div>

      <div *ngIf="resetPasswordForm.invalid" class="error-messages">
        <div
          *ngIf="email.errors?.required && email.touched">{{ "RESET_PASSWORD_PAGE.email_required_resetpassword" | translate }}</div>
        <div
          *ngIf="email.errors?.pattern && email.touched">{{ "RESET_PASSWORD_PAGE.email_structure_resetpassword" | translate }}</div>
        <div
          *ngIf="email.errors?.wrongCredentials">{{ "RESET_PASSWORD_PAGE.wrong_email_resetpassword" | translate }}</div>
        <div *ngIf="email.errors?.usesSsoAuth">{{ "RESET_PASSWORD_PAGE.account_uses_sso_auth" | translate }}</div>
      </div>

      <button class="green sans-serif" type="submit"
              [disabled]="resetPasswordForm.invalid">{{ "RESET_PASSWORD_PAGE.reset_password_button_resetpassword" | translate }}</button>

      <a class="link"
         [routerLink]="['../', RouterUrls.LogIn]">{{ "RESET_PASSWORD_PAGE.wait_remember_resetpassword" | translate }}</a>
    </form>

    <ng-template [ngIf]="isMessageSent">
      <img loading="lazy" src="../../../assets/icons/paper-airplane.svg" alt="airplane">
      <div class="email-sent">
        {{ "RESET_PASSWORD_PAGE.mail_sent_resetpassword" | translate }}
      </div>
    </ng-template>

  </div>
</div>
