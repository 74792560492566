import { Component, HostListener, OnInit } from '@angular/core';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

const DEFAULT_DURATION = 300;

@Component({
  selector: 'app-why-noos-calculator',
  templateUrl: './why-noos-calculator.component.html',
  styleUrls: ['./why-noos-calculator.component.scss'], animations: [
    trigger('collapse', [
      state('false', style({ 'margin-top': '16px', height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1 })),
      state('true', style({ 'margin-top': '0px', height: '0', visibility: 'hidden', opacity: 0 })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ]),
    trigger('rotatedState', [
      state('true', style({ transform: 'rotate(0)' })),
      state('false', style({ transform: 'rotate(-180deg)' })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in-out')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-in'))
    ]),
    trigger('fadeAnimation', [
      transition('false=>true', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ]),
      transition('true=>false', [animate('500ms 1000ms', style({ opacity: 0 }))])
    ])
  ]
})
export class WhyNoosCalculatorComponent implements OnInit {
  firstFaqCollapsed = true;
  secondFaqCollapsed = true;
  isCalculationDone = false;
  isMobile = false;

  turnoverRate: number;
  defaultMedianSalary = 3275;
  annualTurnoverCostWithoutCsr: number;
  annualTurnoverCostWithCsr: number;

  maturityOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  savingsCalculatorForm: UntypedFormGroup = new UntypedFormGroup({
    workforceNumber: new UntypedFormControl('', Validators.required),
    employeesLeft: new UntypedFormControl('', Validators.required),
    employeesRecruited: new UntypedFormControl('', Validators.required),
    medianGrossSalary: new UntypedFormControl(''),
    maturityLevel: new UntypedFormControl('')
  });

  formValues: any;
  previousValues: object = {
    workforceNumber: '',
    employeesLeft: '',
    employeesRecruited: '',
    medianGrossSalary: '',
  };

  constructor(private formBuilder: UntypedFormBuilder) {
  }

  get workforceNumber() {
    return this.savingsCalculatorForm.get('workforceNumber');
  }

  get employeesLeft() {
    return this.savingsCalculatorForm.get('employeesLeft');
  }

  get employeesRecruited() {
    return this.savingsCalculatorForm.get('employeesRecruited');
  }

  @HostListener('document:keydown', ['$event'])
  @HostListener('document:keyup', ['$event'])
  handleOutsideClick(event) {
    if ((event.keyCode === 40 || event.keyCode === 38)) {
      if (this.previousValues.hasOwnProperty(event.target.id)) {
        event.preventDefault();
      }
    }
  }

  ngOnInit(): void {
    this.savingsCalculatorForm = this.formBuilder.group({
      workforceNumber: ['', [Validators.required]],
      employeesLeft: ['', [Validators.required]],
      employeesRecruited: ['', [Validators.required]],
      medianGrossSalary: [''],
      maturityLevel: [1, [Validators.required]],
    });
    this.isMobile = window.innerWidth < 768;
  }

  onSubmitCalculation(): void {

    if (this.savingsCalculatorForm.valid) {
      this.formValues = this.savingsCalculatorForm.value;

      const medianGrossSalary = this.formValues.medianGrossSalary ? this.formValues.medianGrossSalary : this.defaultMedianSalary;

      this.annualTurnoverCostWithoutCsr = this.formValues.employeesLeft * 7.5 * medianGrossSalary;

      const percentageGain = (10 - this.formValues.maturityLevel) * (0.34375 / 10);
      this.annualTurnoverCostWithCsr = this.annualTurnoverCostWithoutCsr * (1 - percentageGain);

      this.turnoverRate = ((this.formValues.employeesLeft + this.formValues.employeesRecruited) / 2) / this.formValues.workforceNumber;

      this.isCalculationDone = true;
    } else {
      Object.keys(this.savingsCalculatorForm.controls).forEach(field => {
        const control = this.savingsCalculatorForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  onType(event: InputEvent | any): void {
    const control: AbstractControl = this.savingsCalculatorForm.controls[event.target.id];

    if (!this.isDigit(event.data) || control.value?.toString().length > 7) {
      control.setValue(this.previousValues[event.target.id]);
    } else {
      this.previousValues[event.target.id] = control.value;
    }
  }

  private isDigit(value): boolean {
    return value !== '-' && value !== '+' &&
      value !== 'e' && value !== 'E' && value !== '.' && value !== ',' &&
      typeof parseInt(value, 10) === 'number';
  }
}
