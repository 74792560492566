import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TagsService } from '../services/tags.service';
import { Constants } from '../app.constants';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  clickEventSubscription: Subscription;
  Constants = Constants;

  constructor(
    private titleService: Title,
    private meta: Meta,
    private tagsService: TagsService) {
      this.clickEventSubscription = this.tagsService.getClickEventPrivacyPolicy().subscribe(() => {
        this.updateTags();
      });
    }

  ngOnInit(): void {
    this.updateTags();
  }

  updateTags() {
    this.titleService.setTitle('Politique de confidentialité');
    this.meta.updateTag({name: 'description', content: ''});
    this.meta.updateTag({property: 'og:title', content: 'Politique de confidentialité'});
    this.meta.updateTag({property: 'og:description', content: ''});
    this.meta.updateTag({property: 'og:type', content: 'URL'});
    this.meta.updateTag({property: 'og:url', content: window.location.href});
    this.meta.updateTag({property: 'og:image', content: 'https://noos.ams3.digitaloceanspaces.com/meta%20_%20landing.png'});
  }

}
