<div class="main-container">
  <div class="main-title">{{ 'WHY_NOOS_CALCULATOR.main_title' | translate }}</div>
  <div class="turnover-subtitle">{{ 'WHY_NOOS_CALCULATOR.turnover_subtitle' | translate }}</div>
  <div class="turnover-info">{{ 'WHY_NOOS_CALCULATOR.turnover_info' | translate }}</div>
  <div class="calculator-title">{{ 'WHY_NOOS_CALCULATOR.calculator_title' | translate }}</div>
  <div class="calculator-container">
    <div class="right-side">
      <form (ngSubmit)="onSubmitCalculation()" [formGroup]="savingsCalculatorForm" class="d-flex flex-column">
        <div class="input-label ns-mb-8">{{ 'WHY_NOOS_CALCULATOR.workforce_title' | translate }} </div>
        <input (input)="onType($event)" [class.input-error]="workforceNumber.touched && workforceNumber.invalid"
               class="input" formControlName="workforceNumber" id="workforceNumber" inputmode="numeric"
               type="number"/>
        <small *ngIf="workforceNumber.touched && workforceNumber?.errors?.required"
               class="error-message">{{ 'WHY_NOOS_CALCULATOR.field_required_error' | translate }} </small>
        <div class="input-label ns-mb-8">{{ 'WHY_NOOS_CALCULATOR.employees_left_title' | translate }}</div>
        <input (input)="onType($event)" [class.input-error]="employeesLeft.touched && employeesLeft.invalid"
               class="input" formControlName="employeesLeft" id="employeesLeft" type="number"/>
        <small *ngIf="employeesLeft.touched && employeesLeft?.errors?.required"
               class="error-message">{{ 'WHY_NOOS_CALCULATOR.field_required_error' | translate }} </small>
        <div class="input-label ns-mb-5">{{ 'WHY_NOOS_CALCULATOR.employees_recruited_title' | translate }}</div>
        <div class="input-hint ns-mb-8">{{ 'WHY_NOOS_CALCULATOR.employees_recruited_hint' | translate }}</div>
        <input (input)="onType($event)"
               [class.input-error]="employeesRecruited.touched && employeesRecruited.invalid" class="input"
               formControlName="employeesRecruited" id="employeesRecruited" type="number"/>
        <small *ngIf="employeesRecruited.touched && employeesRecruited?.errors?.required"
               class="error-message">{{ 'WHY_NOOS_CALCULATOR.field_required_error' | translate }} </small>
        <div class="input-label ns-mb-5">{{ 'WHY_NOOS_CALCULATOR.median_gross_salary_title' | translate }}</div>
        <div class="input-hint ns-mb-8">{{ 'WHY_NOOS_CALCULATOR.median_gross_salary_hint' | translate }}</div>
        <input (input)="onType($event)" class="input" formControlName="medianGrossSalary" id="medianGrossSalary"
               type="number"/>
        <div class="input-label ns-mb-8">{{ 'WHY_NOOS_CALCULATOR.maturity_level_title' | translate }}</div>
        <div class="input-hint ns-mb-8">{{ 'WHY_NOOS_CALCULATOR.maturity_level_hint' | translate }}</div>
        <div class="input-dropdown-container">
          <i class="fa-solid fa-angle-down"></i>
          <select class="input-dropdown" formControlName="maturityLevel" id="maturityLevel">
            <option *ngFor="let option of maturityOptions" [ngValue]="option">{{option}}</option>
          </select>
        </div>
        <button class="submit-button"
                type="submit">{{ 'WHY_NOOS_CALCULATOR.calculate_button' | translate }} </button>
      </form>
    </div>
    <div class="left-side">
      <div *ngIf="!isCalculationDone"
           class="no-calculation-text">{{ (isMobile ? 'WHY_NOOS_CALCULATOR.calculation_placeholder_mobile' : 'WHY_NOOS_CALCULATOR.calculation_placeholder_desktop') | translate }} </div>
      <div *ngIf="isCalculationDone" class="results-container">
        <div class="info-text">{{ 'WHY_NOOS_CALCULATOR.turnover_title' | translate }}</div>
        <div class="amount">€ {{annualTurnoverCostWithoutCsr | currency:'':'':'1.0-0':'fr'}}</div>
        <div class="info-text ns-mt-53" style="max-width: 325px;">
          {{ 'WHY_NOOS_CALCULATOR.csr_savings_title' | translate }} </div>
        <div class="amount">€ {{annualTurnoverCostWithCsr |currency:'':'':'1.0-0':'fr'}}</div>
        <div class="big-info-text ns-mt-53">{{ 'WHY_NOOS_CALCULATOR.savings_over_year_title_1' | translate }}</div>
        <div
            class="big-amount"> €{{ (annualTurnoverCostWithoutCsr - annualTurnoverCostWithCsr) |currency:'':'':'1.0-0':'fr' }}</div>
        <div class="big-info-text">{{ 'WHY_NOOS_CALCULATOR.savings_over_year_title_2' | translate }}</div>
      </div>
    </div>
  </div>
  <div class="faq-container" style="margin-top: 30px">
    <div (click)="firstFaqCollapsed = !firstFaqCollapsed" class="title">
      <i [@rotatedState]="firstFaqCollapsed" class="fa-solid fa-angle-down"></i>
      {{ 'WHY_NOOS_CALCULATOR.faq1_title' | translate }}
    </div>
    <div [@collapse]="firstFaqCollapsed"
         class="content">{{ 'WHY_NOOS_CALCULATOR.faq1_content' | translate }}
    </div>
  </div>
  <div class="faq-container" style="margin-top: 16px; margin-bottom:63px;">
    <div (click)="secondFaqCollapsed = !secondFaqCollapsed" class="title">
      <i [@rotatedState]="secondFaqCollapsed" class="fa-solid fa-angle-down"></i>
      {{ 'WHY_NOOS_CALCULATOR.faq2_title' | translate }}
    </div>
    <div [@collapse]="secondFaqCollapsed"
         class="content">{{ 'WHY_NOOS_CALCULATOR.faq2_content' | translate }}
    </div>
  </div>
</div>
