import { Directive, ElementRef, Input, Renderer2, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

/**
 * Applies text truncation with ellipsis to an element if its content exceeds a specified number of lines.
 */
@Directive({
  selector: '[nsVerticalTextEllipsis]'
})
export class NsTruncateWithEllipsisDirective implements OnChanges, OnDestroy {

  @Input() maxLineLimit = 3;
  private langChangeSubscription: Subscription;

  constructor(private el: ElementRef,
              private renderer: Renderer2,
              private translateService: TranslateService) {
    this.langChangeSubscription = this.translateService.onLangChange.subscribe(() => {
      // Re-apply truncation logic when the language changes
      this.applyTruncationStyle();
      setTimeout(() => this.checkAndApplyTooltip(), 30);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Apply truncation style only if there's a change in the maxLineLimit.
    if (changes['maxLineLimit']) {
      this.applyTruncationStyle();
      // Delay to ensure styles are applied before calculating overflow.
      setTimeout(() => this.checkAndApplyTooltip(), 30);
    }
  }

  /**
   * Sets the styles on the element to use webkit line clamp for text truncation.
   */
  private applyTruncationStyle(): void {
    const styles = {
      'display': '-webkit-box',
      '-webkit-line-clamp': this.maxLineLimit.toString(),
      '-webkit-box-orient': 'vertical',
      'overflow': 'hidden',
      'height': 'auto'
    };

    Object.entries(styles).forEach(([key, value]) =>
      this.renderer.setStyle(this.el.nativeElement, key, value));
  }

  /**
   * Checks if the element's content is overflowing and applies a tooltip with the full text if it is.
   */
  private checkAndApplyTooltip(): void {
    const element = this.el.nativeElement;
    // Adds a native browser tooltip if text overflows.
    if (element.scrollHeight > element.clientHeight) {
      const fullText = element.innerText || element.textContent;
      this.renderer.setAttribute(element, 'title', fullText);
    } else {
      this.renderer.removeAttribute(element, 'title');
    }
  }

   ngOnDestroy(): void {
    this.langChangeSubscription.unsubscribe();
  }
}
