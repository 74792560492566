<header [class.degraded]="isDegraded && !isScrolled && !isMobileMenuOpened && !transparentHeader"
        [class.transparent]="transparentHeader && !isScrolled && !isMobileMenuOpened"
        class="ns-header-unlogged">
  <ng-container *ngIf="!isPrivacyMode">
    <!-- Desktop Header -->
    <div class="container">
      <div class="left-container">
        <a [routerLink]="currentLang">
          <img alt="NooS Logo" class="logo" src="/assets/icons/logo-white-2.svg">
        </a>
        <div (click)="navigateToStaticPage()" class="header-link ns-ml-48">{{ 'HEADER.for_businesses' | translate }}</div>
        <div (click)="navigateToBlog()" class="header-link ns-ml-48">{{ 'HEADER.blog' |translate }}</div>
      </div>
      <div class="right-container">
        <a [routerLink]="[currentLang, RouterUrls.BrowseProjects]" class="header-link ns-mr-45"
           routerLinkActive="active">{{ 'HEADER.our_impact_projects' | translate }}
        </a>
        <a [routerLink]="[currentLang, RouterUrls.LogIn]" class="login-button ns-mr-40"
           routerLinkActive="active">{{ 'HEADER.log_in_header' | translate }}
        </a>
        <div (click)="openCloseLanguageList()" class="language">
          <div class="language-icon"></div>
          <div class="language-dropdown-arrow"></div>
          <div [class.no-pointer-events]="!isLanguageListOpened" class="language-list-wrapper-wrapper">
            <div [ngClass]="{'has-border': isLanguageListOpened}" class="language-list-wrapper">
              <ul *ngIf="isLanguageListOpened" [@inOutAnimation] class="language-list">
                <li>
                  <a (click)="changeLanguage('es')" [ngClass]="{'active-lang': currentLang === '/es'}" class="language-element"
                     routerLinkActive="active-lang">Español
                  </a>
                </li>
                <li>
                  <a (click)="changeLanguage('fr')" [ngClass]="{'active-lang': currentLang === '/fr'}" class="language-element"
                     routerLinkActive="active-lang">Français
                  </a>
                </li>
                <li>
                  <a (click)="changeLanguage('en')" [ngClass]="{'active-lang': currentLang === '/en'}" class="language-element"
                     routerLinkActive="active-lang">English
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile Header -->
    <div class="mobile-container">
      <div class="left-container">
        <img alt="NooS Logo" class="logo" src="/assets/icons/logo-white-2-mobile.svg">
      </div>
      <div class="right-container">
        <div (click)="openCloseMobileMenu()" [class.close-icon]="isMobileMenuOpened"
             class="toggle d-flex align-items-center">
          <img [src]="isMobileMenuOpened ?
        '/assets/icons/cross-white.svg': '/assets/icons/unlogged-menu-toggler.svg'" alt="toggle">
        </div>
      </div>
    </div>
  </ng-container>
  <img *ngIf="isPrivacyMode" alt="GRTgaz Logo" class="grt-logo" src="/assets/logos/grt-gaz-logo.png">
</header>
<!-- Mobile Dropdown Menu -->
<div (click)="isMobileMenuOpened = false" *ngIf="isMobileMenuOpened" class="overlay"></div>
<div *ngIf="isMobileMenuOpened" class="mobile-menu">
  <ul class="menu">
    <li (click)="openCloseMobileMenu();navigateToStaticPage()"><a>{{'HEADER.for_businesses' | translate}}</a></li>
    <li (click)="openCloseMobileMenu();navigateToBlog()"><a>{{ 'HEADER.blog' | translate }}</a></li>
    <li (click)="openCloseMobileMenu()" routerLinkActive="active-mobile"><a
      [routerLink]="[currentLang, RouterUrls.BrowseProjects]">{{ 'HEADER.our_impact_projects' | translate }}</a></li>
    <li (click)="openCloseMobileMenu()" routerLinkActive="active-mobile"><a
      [routerLink]="[currentLang, RouterUrls.LogIn]">{{ 'HEADER.log_in_header' | translate }}</a></li>
  </ul>
  <ul class="change-lang">
    <li routerLinkActive="active-lang"><a (click)="changeLanguage('es')"
                                          [ngClass]="{'active-lang': currentLang === '/es'}">ES</a></li>
    <li routerLinkActive="active-lang"><a (click)="changeLanguage('fr')"
                                          [ngClass]="{'active-lang': currentLang === '/fr'}">FR</a></li>
    <li routerLinkActive="active-lang"><a (click)="changeLanguage('en')"
                                          [ngClass]="{'active-lang': currentLang === '/en'}">EN</a></li>
  </ul>
</div>
