import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
  BrandAccessibility,
  BrandProgramResponse,
  Brands,
  DataForBrandsPage,
  DataForImpactPage,
  MatchingGiftResponse,
  ValidateImpactRequest,
  ValidateImpactResponses
} from '../shared/brands.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class BrandsService {

  constructor(private apiService: ApiService) { }

  isBrandPage = new BehaviorSubject<boolean>(false);
  isDemoPage = new BehaviorSubject<boolean>(false);
  brands: Brands[];
  dataForBrandsPage: DataForBrandsPage;

  getBrandInfo(brandName: string): Observable<any> {
    return this.apiService.getBrand(brandName)
      .pipe(
        map(value => {
          return new DataForImpactPage(value);
        })
      );
  }

  getBrandsPageInfo(): Observable<DataForBrandsPage> {
    if (this.dataForBrandsPage) {
      return of(this.dataForBrandsPage);
    } else {
      return this.apiService.getBrandsPageInfo()
        .pipe(
          tap(data => this.dataForBrandsPage = data)
        );
    }
  }

  getProgramInfo(programSlug: string): Observable<BrandProgramResponse> {
    return this.apiService.getProgramInfo(programSlug);
  }

  getProgramImpact(programId: number): Observable<any> {
    return this.apiService.getProgramImpact(programId);
  }

  getMatchingGiftImpact(programId: number): Observable<any> {
    return this.apiService.getMatchingGiftImpact(programId);
  }

  validateImpact(payload: ValidateImpactRequest): Observable<any> {
    return this.apiService.validateImpact(payload)
      .pipe(
        switchMap(response => {
          if (response.vote === 'Premium') {
            return of(ValidateImpactResponses.Premium);
          } else {
            return of(ValidateImpactResponses.Success);
          }
        }),
        catchError(error => {
          switch (error.error.vote) {
            case 'This email cannot vote':
              return of(ValidateImpactResponses.Error);
            case 'This pledge is already over':
              return of(ValidateImpactResponses.TooLate);
            case 'Email already voted':
              return of(ValidateImpactResponses.AlreadyVoted);
          }
        })
      );
  }

  getMatchingGiftInfo(programSlug: string): Observable<MatchingGiftResponse> {
    return this.apiService.getMatchingGift(programSlug);
  }


  getNoKpiMatchingGiftInfo(programSlug: string): Observable<MatchingGiftResponse> {
    return this.apiService.getNoKpiMatchingGift(programSlug);
  }

  getCrisisEventInfo(programSlug: string): Observable<any> {
    return this.apiService.getCrisisEvent(programSlug);
  }

  checkPaymentIntent(payload: any): Observable<any> {
    return this.apiService.checkPaymentIntent(payload);
  }

  getBrandsImpact(): Observable<any> {
    return this.apiService.getBrandsImpact();
  }

  getBrandsAccessibility(): Observable<BrandAccessibility[]> {
    return this.apiService.getBrandsAccessibility().pipe(
      map(response => response.brands));
  }
}
