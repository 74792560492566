import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpErrorReport } from '../shared/models/http-error-report/http-error-report.model';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorService {

  constructor(private apiService: ApiService) {}

  getHttpErrors(): Observable<any> {
    return this.apiService.getHttpErrors();
  }

  reportHttpError(errorReport: HttpErrorReport) {
    return this.apiService.reportHttpError(errorReport);
  }
}
