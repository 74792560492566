<div (click)="closeModal()" class="ns-close-button"></div>

<!-- Main Title -->
<div *ngIf="!endsWithS" [ngClass]="{'teams': teamsLeaderboard?.length >= 1}" class="main-title">
  {{ "LAC.leaderboard_1" | translate }} {{ company }}{{ "LAC.leaderboard_2_with_s" | translate }}
</div>
<div *ngIf="endsWithS" [ngClass]="{'teams': teamsLeaderboard?.length >= 1}" class="main-title">
  {{ "LAC.leaderboard_1" | translate }} {{ company }}{{ "LAC.leaderboard_2_without_s" | translate }}
</div>

<!-- Teams Switch -->
<div *ngIf="teamsLeaderboard?.length >= 1 && !teamsOnly" class="teams-switch">
  <div (click)="teamsSelected = true" [ngClass]="{'active': teamsSelected}"
       class="option">{{ "LAC.teams" | translate }}
  </div>
  <div (click)="teamsSelected = false" [ngClass]="{'active': !teamsSelected}"
       class="option">{{ "LAC.individuals" | translate }}
  </div>
</div>

<!-- Individual Leaderboard -->
<div class="main-table" *ngIf="!teamsSelected">
  <div class="leaderboard-item" (click)="openUserPublicModal(user.account_id)" *ngFor="let user of leaderboard">
    <div [ngClass]="{
          'first': user.position === 1,
          'second': user.position === 2 && isPrivacyMode,
          'third': user.position === 3 && isPrivacyMode,
          'is-you': user.current_user
        }" class="leaderboard-number">
      {{ user.position }}
    </div>
    <div class="leaderboard-picture" [ngClass]="{'first': user.position === 1}">
      <img (error)="onImageError(user)" [src]="user.profile_picture" alt="item-image">
    </div>
    <div [ngClass]="{
          'first': user.position === 1,
          'second': user.position === 2 && isPrivacyMode,
          'third': user.position === 3 && isPrivacyMode,
          'is-you': user.current_user
        }" class="leaderboard-name">
      {{ user.name }} {{ user.surname }}
    </div>
    <div [ngClass]="{
          'first': user.position === 1,
          'second': user.position === 2 && isPrivacyMode,
          'third': user.position === 3 && isPrivacyMode,
          'is-you': user.current_user
        }" class="leaderboard-points">
      {{ user.points }} pts
    </div>
  </div>
</div>

<!-- Teams Leaderboard -->
<div class="main-table" *ngIf="teamsSelected">
  <div (click)="!teamsOnly ? openTeamLeaderboardModal(team) : {}" *ngFor="let team of teamsLeaderboard"
       class="leaderboard-item">
    <div [ngClass]="{
          'first': team.position === 1,
          'second': team.position === 2 && isPrivacyMode,
          'third': team.position === 3 && isPrivacyMode,
          'is-you': team.current_team
        }" class="leaderboard-number teams">
      {{ team.position }}
    </div>

    <!-- Crown or Medal based on Privacy Mode -->
    <div *ngIf="!isPrivacyMode && team.position === 1" class="team-icon">
      <img alt="crown" src="/assets/icons/crown.svg">
    </div>
    <div *ngIf="isPrivacyMode && team.position === 1" class="medal-icon">
      <img alt="gold-medal" src="/assets/icons/lac-gold-medal.svg">
    </div>
    <div *ngIf="isPrivacyMode && team.position === 2" class="medal-icon">
      <img alt="silver-medal" src="/assets/icons/lac-silver-medal.svg">
    </div>
    <div *ngIf="isPrivacyMode && team.position === 3" class="medal-icon">
      <img alt="bronze-medal" src="/assets/icons/lac-bronze-medal.svg">
    </div>

    <!-- Team Name and Points -->
    <div [ngClass]="{
          'first': team.position === 1,
          'second': team.position === 2 && isPrivacyMode,
          'third': team.position === 3 && isPrivacyMode,
          'is-you': team.current_team
        }" class="leaderboard-name">
      {{ team.name }}
    </div>
    <div [ngClass]="{
          'first': team.position === 1,
          'second': team.position === 2 && isPrivacyMode,
          'third': team.position === 3 && isPrivacyMode,
          'is-you': team.current_team
        }" class="leaderboard-points">
      {{ team.points }} pts
    </div>
  </div>
</div>
