import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { RouterUrls } from '../shared/router-urls';
import { WhyNoosCalculatorComponent } from './components/why-noos-calculator/why-noos-calculator.component';
import { SimplePublicHeaderComponent } from './components/simple-public-header/simple-public-header.component';
import { SimplePublicFooterComponent } from './components/simple-public-footer/simple-public-footer.component';

const routes: Routes = [
    {path: '', pathMatch: 'full', redirectTo: RouterUrls.WhyNoosCalculator},
    {path: RouterUrls.WhyNoosCalculator, component: WhyNoosCalculatorComponent}
  ]
;

@NgModule({
  declarations: [
    WhyNoosCalculatorComponent,
    SimplePublicHeaderComponent,
    SimplePublicFooterComponent
  ],
  exports: [
    SimplePublicFooterComponent,
    SimplePublicHeaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule
  ]
})
export class PublicModule {
}
