import { EventEmitter, Injectable } from '@angular/core';
import { ClockInfo } from '../shared/user.model';
import { ProjectsService } from './projects.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  // Onboardings
  $isFirstVoteOnboardingDone: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  $isSupportOnboardingDone: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  $isVolunteeringOnboardingDone: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  $isWelcomeOnboardingDone: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  $isContentMaskVisible: EventEmitter<boolean> = new EventEmitter<boolean>();
  $isWelcomeOnboardingFocusRight: EventEmitter<boolean> = new EventEmitter<boolean>();
  $isWelcomeOnboardingFocusCenter: EventEmitter<boolean> = new EventEmitter<boolean>();

  $isDegradedBackground: EventEmitter<boolean> = new EventEmitter<boolean>();
  $showNewCampaignIndicator: EventEmitter<boolean> = new EventEmitter<boolean>();
  $reloadNotifications: EventEmitter<void> = new EventEmitter<void>();
  $profilePictureUpdated: EventEmitter<string> = new EventEmitter<string>();
  votesLeft: number;

  constructor(private projectsService: ProjectsService) {
    this.projectsService.updatedVotes$.subscribe({
      next: () => {
        this.projectsService.getClockInfo()
          .subscribe((clockInfo: ClockInfo) => {
            this.votesLeft = clockInfo.votes_left;
          });
      }
    });
  }

  public setDegradedBackground(value: boolean) {
    this.$isDegradedBackground.emit(value);
  }
}
