export class RouterUrls {
  public static SignUp = 'sign-up';
  public static LogIn = 'log-in';
  public static SamlAuth = 'saml-auth';
  public static ResetPassword = 'reset-password';
  public static ResetPasswordConfirm = 'reset-password-confirm';
  public static ChangePassword = 'change-password';
  public static FourOFour = '404';

  public static TermsOfService = 'terms-of-service';
  public static PrivacyPolicy = 'RGPD';
  public static Contact = 'contact';
  public static VettingProcess = 'impact-selection';
  public static ImpactOrganization = 'impact-organizations';

  public static BrowseProjects = 'csr-projects';
  public static BrowseProjectsProject = 'project';
  public static BrowseProjectsProjectDescription = 'description';
  public static BrowseProjectsProjectArticles = 'news';
  public static BrowseProjectsProjectVolunteering = 'volunteering';
  public static NoKpiProjects = 'no-kpi';

  public static Home = 'home';

  public static YourImpact = 'your-impact';
  public static YourImpactImpact = 'impact';
  public static YourImpactProjects = 'projects';

  public static Profile = 'profile';
  public static ProfileSettings = 'settings';

  public static Brands = 'brands';

  public static VolunteeringOffers = 'volunteering';

  public static CrisisEvents = 'crisis-events';

  public static Challenges = 'challenges';

  public static Campaign = 'campaign';
  public static CampaignChallenge = 'challenge';
  public static CampaignPost = 'post';

  // Public Urls
  public static Public = 'public';
  public static WhyNoosCalculator = 'why-noos-calculator';
}
