import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TagsService } from '../services/tags.service';

declare function openAxeptioCookies(): any;

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss']
})
export class TermsOfServiceComponent implements OnInit {

  clickEventSubscription: Subscription;

  constructor(
    private titleService: Title,
    private meta: Meta,
    private tagsService: TagsService) {
      this.clickEventSubscription = this.tagsService.getClickEventTermsOfService().subscribe(() => {
        this.updateTags();
      });
    }

  ngOnInit(): void {
    this.updateTags();
  }

  openCookies() {
    openAxeptioCookies();
  }

  updateTags() {
    this.titleService.setTitle('Conditions d\'utilisation');
    this.meta.updateTag({name: 'description', content: ''});
    this.meta.updateTag({property: 'og:title', content: 'Conditions d\'utilisation'});
    this.meta.updateTag({property: 'og:description', content: ''});
    this.meta.updateTag({property: 'og:type', content: 'URL'});
    this.meta.updateTag({property: 'og:url', content: window.location.href});
    this.meta.updateTag({property: 'og:image', content: 'https://noos.ams3.digitaloceanspaces.com/meta%20_%20landing.png'});
  }
}
