import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ns-button',
  templateUrl: './ns-button.component.html',
  styleUrls: ['./ns-button.component.scss']
})
export class NsButtonComponent implements OnInit {

  @Input() type: string;
  @Input() buttonType: string;
  @Input() text: string;
  @Input() noTextUnderline = false;
  @Input() styles = '';
  @Input() disabled: boolean;
  @Input() isActive: boolean;
  @Input() invisible?: boolean;
  @Input() brandColor?: string;
  @Input() icon: string;
  /**
   * Font Awesome icon string
   */
  @Input() faIcon: string;
  @Input() iconRightSide?: boolean;
  @Input() iconAlignedToBorder?: boolean;
  @Input() onlyIcon?: boolean;
  @Input() iconClasses = '';
  @Output() callback$: EventEmitter<void> = new EventEmitter();
  buttonClasses: string;
  buttonStyles: string;

  @Input() set classes(value: string) {
    this.buttonClasses = this.type;
    this.buttonClasses += ` ${ value }`;
  }

  ngOnInit() {
    this.buttonStyles = this.styles;
    if (this.type) {
      this.buttonClasses += ` ${ this.type }`;
    }
    if (this.faIcon) {
      this.iconClasses += ` ${ this.faIcon }`;
    }

    if (this.brandColor) {
      this.buttonClasses += ' --color';
    }
  }

  onClick(): void {
    this.callback$.next();
  }
}
