import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notifications-modal',
  templateUrl: './notifications-modal.component.html',
  styleUrls: ['./notifications-modal.component.scss']
})
export class NotificationsModalComponent implements OnInit {

  @Input() notifications: any[];

  constructor(private dialogRef: MatDialogRef<NotificationsModalComponent>,
              @Inject(MAT_DIALOG_DATA)
                data) { }

  ngOnInit(): void {
  }

  closeModal(){
    this.dialogRef.close();
  }

}
