import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { RouterUrls } from '../router-urls';
import { ProjectsService } from '../../services/projects.service';
import { LanguageService } from '../../services/language.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../custom-validators';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../services/modal/modal.service';
import { ModalConstants } from '../services/modal/modal.constants';
import { SubscribedDialogComponent } from '../subscribed-dialog/subscribed-dialog.component';
import { FooterConstants } from './footer.constants';
import { Router } from '@angular/router';
import { Constants } from '../../app.constants';
import { UserService } from '../../services/user.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  subscribeForm: UntypedFormGroup;
  FooterConstants = FooterConstants;
  RouterUrls = RouterUrls;
  additionalMarginBottom$: Observable<number>;
  year: number;

  constructor(
    private router: Router,
    private userService: UserService,
    private translate: TranslateService,
    private modal: ModalService,
    private projectsService: ProjectsService,
    private languageService: LanguageService,
    private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.subscribeForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(CustomValidators.emailValidationRegex)]]
    });

    this.additionalMarginBottom$ = this.projectsService.additionalMarginBottomFooter;
    this.year = new Date().getFullYear();
  }

  subscribeForMailing() {
    this.userService.subscribeForMailing(this.subscribeForm.value.email, this.translate.currentLang).subscribe(
      res => {
        this.subscribeForm.reset();
        this.modal.openFixedModal({success: true}, SubscribedDialogComponent, ModalConstants.DEFAULT_PANEL_CLASS);
      },
      error => {
        this.modal.openFixedModal({success: false}, SubscribedDialogComponent, ModalConstants.DEFAULT_PANEL_CLASS);
      }
    );
  }

  get currentLanguage() {
    return this.languageService.getUserUsedLanguage();
  }

  navigateTo(code: number): void {
    switch (code) {
      case FooterConstants.HOME_CODE:
        this.router.navigate([this.languageService.getCurrentLangWithSlash()]);
        break;
      case FooterConstants.RSE_TOOL_CODE:
        switch (this.currentLanguage) {
          case Constants.FR_LANG_KEY:
            window.open(Constants.RSE_TOOL_LINK_FR, '_blank');
            break;
          case Constants.EN_LANG_KEY:
            window.open(Constants.RSE_TOOL_LINK_EN, '_blank');
            break;
          case Constants.ES_LANG_KEY:
            window.open(Constants.RSE_TOOL_LINK_ES, '_blank');
            break;
        }
        break;
      case FooterConstants.EXPERTISE_IMPACT_CODE:
        switch (this.currentLanguage) {
          case Constants.FR_LANG_KEY:
            window.open(Constants.EXPERTISE_IMPACT_LINK_FR, '_blank');
            break;
          case Constants.EN_LANG_KEY:
            window.open(Constants.EXPERTISE_IMPACT_LINK_EN, '_blank');
            break;
          case Constants.ES_LANG_KEY:
            window.open(Constants.EXPERTISE_IMPACT_LINK_ES, '_blank');
            break;
        }
        break;
      case FooterConstants.GET_A_DEMO_CODE:
        switch (this.currentLanguage) {
          case Constants.FR_LANG_KEY:
            window.open(Constants.DEMO_LINK_FR, '_blank');
            break;
          case Constants.EN_LANG_KEY:
            window.open(Constants.DEMO_LINK_EN, '_blank');
            break;
          case Constants.ES_LANG_KEY:
            window.open(Constants.DEMO_LINK_ES, '_blank');
            break;
        }
        break;
      case FooterConstants.BLOG_CODE:
        window.open(Constants.BLOG_URL + this.languageService.getUserUsedLanguage() + '/home/');
        break;
      case FooterConstants.WHO_WE_ARE_CODE:
        switch (this.currentLanguage) {
          case Constants.FR_LANG_KEY:
            window.open(Constants.WHO_WE_ARE_LINK_FR, '_blank');
            break;
          case Constants.EN_LANG_KEY:
            window.open(Constants.WHO_WE_ARE_LINK_EN, '_blank');
            break;
          case Constants.ES_LANG_KEY:
            window.open(Constants.WHO_WE_ARE_LINK_ES, '_blank');
            break;
        }
        break;
      case FooterConstants.ABOUT_NOOS_CODE:
        switch (this.currentLanguage) {
          case Constants.FR_LANG_KEY:
            window.open(Constants.ABOUT_NOOS_LINK_FR, '_blank');
            break;
          case Constants.EN_LANG_KEY:
            window.open(Constants.ABOUT_NOOS_LINK_EN, '_blank');
            break;
          case Constants.ES_LANG_KEY:
            window.open(Constants.ABOUT_NOOS_LINK_ES, '_blank');
            break;
        }
        break;
      case FooterConstants.CARBON_COMPENSATION_CODE:
        switch (this.currentLanguage) {
          case Constants.FR_LANG_KEY:
            window.open(Constants.CARBON_COMPENSATION_LINK_FR, '_blank');
            break;
          case Constants.EN_LANG_KEY:
            window.open(Constants.CARBON_COMPENSATION_LINK_EN, '_blank');
            break;
          case Constants.ES_LANG_KEY:
            window.open(Constants.CARBON_COMPENSATION_LINK_ES, '_blank');
            break;
        }
        break;
      case FooterConstants.CSR_WITH_OUR_CLIENTS_CODE:
        switch (this.currentLanguage) {
          case Constants.FR_LANG_KEY:
            window.open(Constants.CSR_CLIENTS_LINK_FR, '_blank');
            break;
          case Constants.EN_LANG_KEY:
            window.open(Constants.CSR_CLIENTS_LINK_EN, '_blank');
            break;
          case Constants.ES_LANG_KEY:
            window.open(Constants.CSR_CLIENTS_LINK_ES, '_blank');
            break;
        }
        break;
      case FooterConstants.OUR_ENGAGED_COMPANIES_CODE:
        this.router.navigate([this.languageService.getCurrentLangWithSlash(), RouterUrls.Brands]);
        break;
      case FooterConstants.TERMS_AND_CONDITIONS_CODE:
        this.router.navigate([this.languageService.getCurrentLangWithSlash(), RouterUrls.TermsOfService]);
        break;
      case FooterConstants.PRIVACY_POLICY_CODE:
        this.router.navigate([this.languageService.getCurrentLangWithSlash(), RouterUrls.PrivacyPolicy]);
        break;
      default:
        break;
    }

  }
}
