<div [class.fourth-step]="currentStep === 4"
     [class.third-step]="currentStep === 3" class="welcome-ob-container">
  <!-- Step 1 -->
  <div *ngIf="currentStep === 1" class="step">
    <img [src]="'assets/images/png/welcome_onboarding_1_' + screenMode + '.png'" alt="Welcome Image">
    <div class="content">
      <div class="title-1">{{ 'WELCOME_ONBOARDING.step_1_title' | translate:{username: this.user.name} }}</div>
      <div class="text-1">{{ 'WELCOME_ONBOARDING.step_1_text' | translate }}</div>
      <div class="d-flex justify-content-center mt-auto">
        <ns-button
          (callback$)="onNextStepClick()"
          [classes]="'ns-pr-55'"
          [iconAlignedToBorder]="true"
          [iconClasses]="'icon-19x19 ns-mr-15'"
          [iconRightSide]="true"
          [icon]="'assets/icons/arrow-right-very-dark-grey.svg'"
          [noTextUnderline]="true"
          [styles]="'min-width:200px;'"
          [text]="'WELCOME_ONBOARDING.step_1_button_text' | translate"
          [type]="Constants.BUTTON_TYPE_DEFAULT_YELLOW_FILLED_B">
        </ns-button>
      </div>
    </div>
  </div>
  <!-- END Step 1 -->
  <!-- Step 2 -->
  <div *ngIf="currentStep === 2" class="step-2">
    <div class="title-1">{{ 'WELCOME_ONBOARDING.step_2_title' | translate }}</div>
    <div class="text-1">{{ 'WELCOME_ONBOARDING.step_2_text' | translate }}</div>
    <div class="cards-container">
      <div class="card">
        <img [src]="'assets/images/png/welcome_onboarding_2_' + screenMode + '.png'" alt="Complete challenges">
        <div class="content">
          <ng-container *ngIf="campaign_topic">
            <div class="title">{{ 'WELCOME_ONBOARDING.step_2_box_1a_title' | translate }}</div>
            <div
              class="text">{{ 'WELCOME_ONBOARDING.step_2_box_1a_text' | translate:{campaignTopic: campaign_topic[currentLanguage]}
              }}</div>
          </ng-container>
          <ng-container *ngIf="!campaign_topic">
            <div class="title">{{ 'WELCOME_ONBOARDING.step_2_box_1b_title' | translate }}</div>
            <div
              class="text">{{ 'WELCOME_ONBOARDING.step_2_box_1b_text' | translate:{company: this.companyName} }}</div>
          </ng-container>
        </div>
      </div>
      <div class="card">
        <img [src]="'assets/images/png/welcome_onboarding_3_' + screenMode + '.png'"
             alt="Support Projects">
        <div class="content">
          <div class="title">{{ 'WELCOME_ONBOARDING.step_2_box_2_title' | translate }}</div>
          <div class="text">{{ 'WELCOME_ONBOARDING.step_2_box_2_text' | translate:{company: this.companyName} }}</div>
        </div>
      </div>
      <div class="card">
        <img [src]="'assets/images/png/welcome_onboarding_4_' + screenMode + '.png'"
             alt="Volunteer">
        <div class="content">
          <div class="title">{{ 'WELCOME_ONBOARDING.step_2_box_3_title' | translate }}</div>
          <div class="text">{{ 'WELCOME_ONBOARDING.step_2_box_3_text' | translate }}</div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-auto">
      <ns-button
        (callback$)="onNextStepClick()"
        [noTextUnderline]="true"
        [styles]="'min-width:200px;'"
        [text]="'WELCOME_ONBOARDING.step_2_button_text' | translate"
        [type]="Constants.BUTTON_TYPE_DEFAULT_YELLOW_FILLED_B">
      </ns-button>
      <div class="stepper">{{ 'GENERIC.step_x_of_y' | translate:{x: currentStep, y: 4} }}</div>
    </div>
  </div>
  <!-- END Step 2 -->
  <!--region Step 3 and 4 -->
  <div *ngIf="currentStep > 2" class="step-3-and-4-container">
    <div [class.last-step]="currentStep === 4" class="top-square"></div>
    <div class="title-2">{{('WELCOME_ONBOARDING.step_' + currentStep + '_title') | translate}}</div>
    <div class="text-2">{{('WELCOME_ONBOARDING.step_' + currentStep + '_text')
      | translate:{company: currentLanguage === 'en' ? companyNameEn : companyName} }}</div>
    <div class="d-flex justify-content-end ns-mt-auto">
      <ns-button
        (callback$)="onNextStepClick()"
        [classes]="'justify-content-center font-alegreya-sans ns-height-40 ns-p-20x10 ns-radius-8 ns-font-18 ns-font-w-800'"
        [styles]="'min-width: 95px;'"
        [iconAlignedToBorder]="false"
        [iconClasses]="'icon-13x13 ns-ml-12'"
        [iconRightSide]="true"
        [icon]=" currentStep === 3 ? 'assets/icons/arrow-right-very-dark-grey.svg':''"
        [text]="('WELCOME_ONBOARDING.step_' + currentStep + '_button_text') | translate"
        [type]="Constants.BUTTON_TYPE_DEFAULT_YELLOW_SOLID_M">
      </ns-button>
    </div>
    <div class="stepper">{{ 'GENERIC.step_x_of_y' | translate:{x: currentStep, y: 4} }}</div>
  </div>
  <!--endregion END Step 3 and 4 -->
</div>
