<ng-container *ngIf="offer">
  <div class="top-container">
    <div (click)="onShareButtonClick()" *ngIf="screenWidth < 767"
         class="share-button"><span>{{'VOLUNTEERING_TAB.share' | translate | uppercase}}</span><div></div></div>
    <div (click)="closeDialog()" class="close-button"></div>
  </div>
  <div [class]="classes" [ngClass]="{'mobile-bottom-padding': this.screenWidth < 767}"
       class="offer-card-container scrollable-y">
    <div class="img-container">
      <img [src]="offer.media" alt="image" loading="lazy">
      <div class="tags-container">
        <ng-container *ngFor="let tag of categoryTags">
            <span class="category-tag">
              {{ tag.tag[currentLang] }}
            </span>
        </ng-container>
      </div>
    </div>
    <div [ngClass]="{'ns-pt-8': screenWidth < 767}" class="ns-pt-8 content-container">
      <div *ngIf="screenWidth > 767" class="top-buttons-container ns-mt-20 ns-mb-8">
        <ns-button
          (callback$)="onShareButtonClick()"
          [iconRightSide]="true"
          [icon]="'/assets/icons/paper-plane-send.svg'"
          [iconClasses]="'icon-12x12 ns-mb-2'"
          [classes]="'ns-line-height-12'"
          [text]="'VOLUNTEERING_TAB.share' | translate | uppercase"
          [type]="Constants.BUTTON_TYPE_DEFAULT_DARK_BLUE_STROKE_T">
        </ns-button>
        <ng-container *ngIf="!offer.hasApplied">
          <ns-button
            *ngIf="!offer.external"
            (callback$)="onApplyButtonClick(false)"
            [classes]="'ns-ml-12'"
            [styles]=" screenWidth > 767 ? 'width: 172px; height: 48px;':''"
            [text]="'VOLUNTEERING_TAB.apply_button' | translate"
            [type]="Constants.BUTTON_TYPE_DEFAULT_YELLOW_SOLID_M">
          </ns-button>
          <ns-button
            *ngIf="offer.external"
            (callback$)="onApplyButtonClick(true)"
            [classes]="'ns-ml-12'"
            [styles]=" screenWidth > 767 ? 'width: 172px; height: 48px;':''"
            [iconRightSide]="true"
            [icon]="'assets/icons/external_link.svg'"
            [text]="'VOLUNTEERING_TAB.apply_button' | translate"
            [type]="Constants.BUTTON_TYPE_DEFAULT_YELLOW_SOLID_M">
          </ns-button>
        </ng-container>
        <ng-container *ngIf="offer.hasApplied">
          <div class="d-flex justify-content-center align-items-center ns-width-172 ns-height-48 font-alegreya-sans
           ns-font-14 ns-line-height-18 ns-font-w-500 ns-grey-8 ns-text-center">
            {{'VOLUNTEERING_TAB.applied_button_volunteeringtab' | translate}}
          </div>
        </ng-container>
      </div>
      <div class="tags-container ns-mb-8">
        <ng-container *ngFor="let tag of categoryTags">
            <span class="category-tag">
              {{ tag.tag[currentLang] }}
            </span>
        </ng-container>
      </div>
      <div [class]="'card-title' +
      (this.screenWidth < 767 ? ' ns-mb-12 ns-font-24 ns-line-height-26': ' ns-mb-24 ns-font-32 ns-line-height-34')" [innerHTML]="offer?.title[currentLang]">
      </div>
      <ng-container *ngIf="showIo">
        <div [innerHTML]="offer?.io?.name" class="card-metadata"></div>
      </ng-container>
      <ng-container *ngIf="!showIo">
        <div [innerHTML]="offer?.org_name" class="card-metadata"></div>
      </ng-container>
      <div *ngIf="offer?.duration" class="card-metadata">{{ offer.duration[currentLang] }}</div>
      <div [class.ns-mb-12]="this.screenWidth < 767" [class.ns-mb-24]="this.screenWidth >= 767" class="card-metadata">
        <ng-container *ngIf="offer.city && offer?.city[currentLang]">
          {{offer.city[currentLang]}}</ng-container>
        <ng-container *ngIf="offer.country && offer.city && offer.city[currentLang]">{{', '}}</ng-container>
        <ng-container *ngIf="offer.country">
          {{'COUNTRIES.' + offer.country | translate}}
        </ng-container>
        <ng-container *ngIf="formatTag">
          ({{formatTag?.tag[currentLang]}})
        </ng-container>
      </div>
      <div class="card-subtitle ns-mb-8">{{'VOLUNTEERING_TAB.description_activities' | translate}}</div>
      <div [innerHTML]="offer?.description[currentLang]" class="card-text"></div>
      <div class="card-subtitle ns-mt-24 ns-mb-8">{{'VOLUNTEERING_TAB.offer_requirements' | translate}}</div>
      <div class="card-text">
        <ng-container *ngIf="offer.requirements">
          <div [innerHTML]="offer.requirements[currentLang]"></div>
        </ng-container>
        <ng-container *ngIf="!offer.requirements">
          <div>{{'VOLUNTEERING_TAB.none' | translate}}</div>
        </ng-container>
      </div>
      <div class="card-subtitle ns-mt-24 ns-mb-8">{{'VOLUNTEERING_TAB.application_deadline' | translate }}</div>
      <div class="card-text">{{offer.deadline_date ?
        offer.deadline_date :
        ('VOLUNTEERING_TAB.none' | translate)}}</div>
      <div class="card-subtitle ns-mt-24 ns-mb-8">{{'VOLUNTEERING_TAB.volunteer_duration' | translate }}</div>
      <div class="card-text">{{offer.end_date ?
        ('VOLUNTEERING_TAB.until' | translate) + ' ' + (offer.end_date | date:'MMMM y':undefined:currentLang | titlecase) :
        ('VOLUNTEERING_TAB.none' | translate)}}</div>
      <div class="d-flex flex-column">
        <div>
          <div [class.ns-mt-12]="this.screenWidth < 767" [class.ns-mt-24]="this.screenWidth >= 767"
               class="card-subtitle ns-mb-8">{{'VOLUNTEERING_TAB.soft_skills' | translate }}</div>
          <div class="card-text">
            <ng-container *ngIf="offer.soft_skills">
              <div [innerHTML]="offer.soft_skills[currentLang]"></div>
            </ng-container>
            <ng-container *ngIf="!offer.soft_skills">
              <div>{{'VOLUNTEERING_TAB.none' | translate}}</div>
            </ng-container>
          </div>
        </div>
        <div>
          <div [class.ns-mt-12]="this.screenWidth < 767" [class.ns-mt-24]="this.screenWidth >= 767"
               class="card-subtitle ns-mb-8">{{'VOLUNTEERING_TAB.hard_skills' | translate }}</div>
          <div class="card-text">
            <ng-container *ngIf="offer.hard_skills">
              <div [innerHTML]="offer.hard_skills[currentLang]"></div>
            </ng-container>
            <ng-container *ngIf="!offer.hard_skills">
              <div>{{'VOLUNTEERING_TAB.none' | translate}}</div>
            </ng-container>
          </div>
        </div>
      </div>
      <ng-container>
        <div *ngIf="showIo" class="bottom-container">
          <img alt="IO Logo" loading="lazy" src="{{offer.io.logo_square}}"/>
          <div>
            <div class="card-subtitle ns-mb-8">{{'VOLUNTEERING_TAB.about_program' | translate }}</div>
            <div [innerHTML]="offer.io.description[currentLang]" class="card-text"></div>
          </div>
        </div>
      </ng-container>
      <ng-container>
        <div *ngIf="!showIo" class="bottom-container">
          <img alt="IO Logo" loading="lazy" src="{{offer.org_logo}}"/>
          <div>
            <div class="card-subtitle ns-mb-8">{{'VOLUNTEERING_TAB.about_program' | translate }}</div>
            <div [innerHTML]="offer.org_description[currentLang]" class="card-text"></div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="screenWidth < 767" class="bottom-floating-container">
    <ng-container *ngIf="!offer.hasApplied">
      <ns-button
        *ngIf="!offer.external"
        (callback$)="onApplyButtonClick(false)"
        [classes]="'w-100 ns-font-26 ns-height-42 ns-line-height-26 ns-font-w-800 ns-b-radius-10'"
        [iconClasses]="'icon-12x19 ns-ml-10'"
        [iconRightSide]="true"
        [icon]="'assets/icons/arrow-right-very-dark-grey.svg'"
        [text]="'VOLUNTEERING_TAB.apply_button' | translate"
        [type]="Constants.BUTTON_TYPE_DEFAULT_YELLOW_SOLID_M"
        style="width: 100% !important;">
      </ns-button>
      <ns-button
        *ngIf="offer.external"
        (callback$)="onApplyButtonClick(true)"
        [classes]="'w-100 ns-font-26 ns-height-42 ns-line-height-26 ns-font-w-800 ns-b-radius-10'"
        [iconClasses]="'icon-25x25 ns-ml-10'"
        [iconRightSide]="true"
        [icon]="'assets/icons/external_link.svg'"
        [text]="'VOLUNTEERING_TAB.apply_button' | translate"
        [type]="Constants.BUTTON_TYPE_DEFAULT_YELLOW_SOLID_M"
        style="width: 100% !important;">
      </ns-button>
    </ng-container>
    <ng-container *ngIf="offer.hasApplied">
      <div class="disabled-apply-button">
        {{'VOLUNTEERING_TAB.applied_button_volunteeringtab' | translate}}
      </div>
    </ng-container>
  </div>
</ng-container>
