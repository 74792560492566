import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CorePageComponent } from './core-page/core-page.component';
import { LandingPageComponent } from './core-page/landing-page/landing-page.component';
import { RouterUrls } from './shared/router-urls';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { LogInComponent } from './auth/log-in/log-in.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ResetPasswordConfirmComponent } from './auth/reset-password-confirm/reset-password-confirm.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { ImpactOrganizationComponent } from './impact-organization/impact-organization.component';
import { PageFourOFourComponent } from './page-four-o-four/page-four-o-four.component';
import { canActivateRoute } from './guards/auth.guard';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { VolunteeringComponent } from './volunteering/volunteering.component';
import { VettingProcessComponent } from './vetting-process/vetting-process.component';
import { SamlRedirectComponent } from './auth/saml-redirect/saml-redirect.component';
import { SamlAuthComponent } from './auth/saml-auth/saml-auth.component';
import { LacOnlyGuard } from './guards/lac-only.guard';

const routes: Routes = [
  {path: 'saml-success', component: SamlRedirectComponent},
  {path: '', redirectTo: 'en', pathMatch: 'full'},
  {
    path: 'en', component: CorePageComponent, children: [
      {path: '', component: LandingPageComponent, canActivate: [LacOnlyGuard]},
      {path: RouterUrls.SignUp, component: SignUpComponent},
      {path: RouterUrls.LogIn, component: LogInComponent},
      {path: RouterUrls.SamlAuth, component: SamlAuthComponent},
      {path: RouterUrls.ResetPassword, component: ResetPasswordComponent},
      {path: RouterUrls.ResetPasswordConfirm, component: ResetPasswordConfirmComponent},
      {path: RouterUrls.TermsOfService, component: TermsOfServiceComponent},
      {path: RouterUrls.PrivacyPolicy, component: PrivacyPolicyComponent},
      {path: RouterUrls.VettingProcess, component: VettingProcessComponent},
      {path: RouterUrls.ImpactOrganization, component: ImpactOrganizationComponent},
      {path: RouterUrls.FourOFour, component: PageFourOFourComponent},
      {
        path: RouterUrls.VolunteeringOffers,
        component: VolunteeringComponent,
        canActivate: [canActivateRoute, LacOnlyGuard]
      },
      {
        path: RouterUrls.Home, canActivate: [canActivateRoute, LacOnlyGuard],
        loadChildren: () => import('./home-module/home.module').then(m => m.HomeModule)
      },
      {
        path: RouterUrls.BrowseProjects, canActivate: [LacOnlyGuard],
        loadChildren: () => import('./browse-projects-module/browse-projects.module').then(m => m.BrowseProjectsModule)
      },
      {
        path: RouterUrls.Campaign, canActivate: [canActivateRoute],
        loadChildren: () => import('./campaign-module/campaign.module').then(m => m.CampaignModule)
      },
      {
        path: RouterUrls.YourImpact, canActivate: [canActivateRoute, LacOnlyGuard],
        loadChildren: () => import('./your-impact-module/your-impact.module').then(m => m.YourImpactModule)
      },
      {
        path: RouterUrls.Profile, canActivate: [canActivateRoute],
        loadChildren: () => import('./profile-module/profile.module').then(m => m.ProfileModule)
      },
      {
        path: RouterUrls.Brands, canActivate: [LacOnlyGuard],
        loadChildren: () => import('./brands-module/brands.module').then(m => m.BrandsModule)
      },
      {
        path: RouterUrls.CrisisEvents, canActivate: [LacOnlyGuard],
        loadChildren: () => import('./crisis-events-module/crisis-events.module').then(m => m.CrisisEventsModule)
      },
      {
        path: RouterUrls.NoKpiProjects, canActivate: [LacOnlyGuard],
        loadChildren: () => import('./no-kpi-projects-module/no-kpi-projects.module').then(m => m.NoKpiProjectsModule)
      },
      {
        path: RouterUrls.Public, canActivate: [LacOnlyGuard],
        loadChildren: () => import('./public-module/public.module').then(m => m.PublicModule)
      },
      { path: '**', redirectTo: RouterUrls.FourOFour }
    ]
  },
  {
    path: 'es', component: CorePageComponent, children: [
      {path: '', component: LandingPageComponent, canActivate: [LacOnlyGuard]},
      {path: RouterUrls.SignUp, component: SignUpComponent},
      {path: RouterUrls.LogIn, component: LogInComponent},
      {path: RouterUrls.SamlAuth, component: SamlAuthComponent},
      {path: RouterUrls.ResetPassword, component: ResetPasswordComponent},
      {path: RouterUrls.ResetPasswordConfirm, component: ResetPasswordConfirmComponent},
      {path: RouterUrls.TermsOfService, component: TermsOfServiceComponent},
      {path: RouterUrls.PrivacyPolicy, component: PrivacyPolicyComponent},
      {path: RouterUrls.VettingProcess, component: VettingProcessComponent},
      {path: RouterUrls.ImpactOrganization, component: ImpactOrganizationComponent},
      {path: RouterUrls.FourOFour, component: PageFourOFourComponent},
      {
        path: RouterUrls.VolunteeringOffers,
        component: VolunteeringComponent,
        canActivate: [canActivateRoute, LacOnlyGuard]
      },
      {
        path: RouterUrls.Home, canActivate: [canActivateRoute, LacOnlyGuard],
        loadChildren: () => import('./home-module/home.module').then(m => m.HomeModule)
      },
      {
        path: RouterUrls.BrowseProjects, canActivate: [LacOnlyGuard],
        loadChildren: () => import('./browse-projects-module/browse-projects.module').then(m => m.BrowseProjectsModule)
      },
      {
        path: RouterUrls.Campaign, canActivate: [canActivateRoute],
        loadChildren: () => import('./campaign-module/campaign.module').then(m => m.CampaignModule)
      },
      {
        path: RouterUrls.YourImpact, canActivate: [canActivateRoute, LacOnlyGuard],
        loadChildren: () => import('./your-impact-module/your-impact.module').then(m => m.YourImpactModule)
      },
      {
        path: RouterUrls.Profile, canActivate: [canActivateRoute],
        loadChildren: () => import('./profile-module/profile.module').then(m => m.ProfileModule)
      },
      {
        path: RouterUrls.Brands, canActivate: [LacOnlyGuard],
        loadChildren: () => import('./brands-module/brands.module').then(m => m.BrandsModule)
      },
      {
        path: RouterUrls.CrisisEvents, canActivate: [LacOnlyGuard],
        loadChildren: () => import('./crisis-events-module/crisis-events.module').then(m => m.CrisisEventsModule)
      },
      {
        path: RouterUrls.NoKpiProjects, canActivate: [LacOnlyGuard],
        loadChildren: () => import('./no-kpi-projects-module/no-kpi-projects.module').then(m => m.NoKpiProjectsModule)
      },
      {
        path: RouterUrls.Public, canActivate: [LacOnlyGuard],
        loadChildren: () => import('./public-module/public.module').then(m => m.PublicModule)
      },
      { path: '**', redirectTo: RouterUrls.FourOFour }
    ]
  },
  {
    path: 'fr', component: CorePageComponent, children: [
      {path: '', component: LandingPageComponent, canActivate: [LacOnlyGuard]},
      {path: RouterUrls.SignUp, component: SignUpComponent},
      {path: RouterUrls.LogIn, component: LogInComponent},
      {path: RouterUrls.SamlAuth, component: SamlAuthComponent},
      {path: RouterUrls.ResetPassword, component: ResetPasswordComponent},
      {path: RouterUrls.ResetPasswordConfirm, component: ResetPasswordConfirmComponent},
      {path: RouterUrls.TermsOfService, component: TermsOfServiceComponent},
      {path: RouterUrls.PrivacyPolicy, component: PrivacyPolicyComponent},
      {path: RouterUrls.VettingProcess, component: VettingProcessComponent},
      {path: RouterUrls.ImpactOrganization, component: ImpactOrganizationComponent},
      {path: RouterUrls.FourOFour, component: PageFourOFourComponent},
      {
        path: RouterUrls.VolunteeringOffers,
        component: VolunteeringComponent,
        canActivate: [canActivateRoute, LacOnlyGuard]
      },
      {
        path: RouterUrls.Home, canActivate: [canActivateRoute, LacOnlyGuard],
        loadChildren: () => import('./home-module/home.module').then(m => m.HomeModule)
      },
      {
        path: RouterUrls.BrowseProjects, canActivate: [canActivateRoute, LacOnlyGuard],
        loadChildren: () => import('./browse-projects-module/browse-projects.module').then(m => m.BrowseProjectsModule)
      },
      {
        path: RouterUrls.Campaign, canActivate: [canActivateRoute],
        loadChildren: () => import('./campaign-module/campaign.module').then(m => m.CampaignModule)
      },
      {
        path: RouterUrls.YourImpact, canActivate: [canActivateRoute, LacOnlyGuard],
        loadChildren: () => import('./your-impact-module/your-impact.module').then(m => m.YourImpactModule)
      },
      {
        path: RouterUrls.Profile, canActivate: [canActivateRoute],
        loadChildren: () => import('./profile-module/profile.module').then(m => m.ProfileModule)
      },
      {
        path: RouterUrls.Brands, canActivate: [LacOnlyGuard],
        loadChildren: () => import('./brands-module/brands.module').then(m => m.BrandsModule)
      },
      {
        path: RouterUrls.CrisisEvents, canActivate: [LacOnlyGuard],
        loadChildren: () => import('./crisis-events-module/crisis-events.module').then(m => m.CrisisEventsModule)
      },
      {
        path: RouterUrls.NoKpiProjects, canActivate: [LacOnlyGuard],
        loadChildren: () => import('./no-kpi-projects-module/no-kpi-projects.module').then(m => m.NoKpiProjectsModule)
      },
      {
        path: RouterUrls.Public, canActivate: [LacOnlyGuard],
        loadChildren: () => import('./public-module/public.module').then(m => m.PublicModule)
      },
      { path: '**', redirectTo: RouterUrls.FourOFour }
    ]
  },
  { path: '**', redirectTo: 'en', pathMatch: 'full' }
];


@NgModule({
  declarations: [],
  imports: [
    TranslateModule.forRoot(),
    RouterModule.forRoot(routes, {
      preloadingStrategy: NoPreloading,
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [
    RouterModule,
  ]
})
export class AppRoutingModule {
}
