import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Constants } from '../../../app.constants';
import { MatDialogRef } from '@angular/material/dialog';
import { isMobile } from '../../../utils/screen.utils';

@Component({
  selector: 'app-ns-simple-info-modal',
  templateUrl: './ns-simple-info-modal.component.html',
  styleUrls: ['./ns-simple-info-modal.component.scss']
})
export class NsSimpleInfoModalComponent implements OnInit, AfterViewInit {
  readonly Constants = Constants;

  @Input() title: string;
  @Input() infoText: string;
  @Input() infoTextDesktopClasses:string;
  @Input() infoTextMobileClasses: string ;
  @Input() buttonText: string;
  @Input() buttonType: string;
  _infoTextDesktopClasses = 'info-text ';
  _infoTextMobileClasses = 'info-text ' ;

  isMobile: boolean;

  constructor(private dialogRef: MatDialogRef<NsSimpleInfoModalComponent>) {
  }

  ngOnInit(): void {
    this.isMobile = isMobile();
  }

  ngAfterViewInit() {
    if (this.infoTextDesktopClasses) {
      this._infoTextDesktopClasses += ` ${ this.infoTextDesktopClasses }`;

      if (!this.infoTextMobileClasses){
        this._infoTextMobileClasses = this._infoTextDesktopClasses;
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
