<app-custom-spinner *ngIf="isLoading"></app-custom-spinner>
<div (click)="closeModal()" class="ns-close-button"></div>
<div class="main-title"> {{ teamName }}</div>
<div class="main-table">
  <div class="leaderboard-item" (click)="openUserPublicModal(user.account_id)" *ngFor="let user of leaderboard">
    <div class="leaderboard-number" [ngClass]="{'first': user.position === 1, 'is-you': user.current_user}">{{ user.position }}</div>
    <div class="leaderboard-picture" [ngClass]="{'first': user.position === 1}">
      <img (error)="onImageError(user)" src="{{ user.profile_picture }}" alt="user-image">
    </div>
    <div class="leaderboard-name" [ngClass]="{'first': user.position === 1, 'is-you': user.current_user}">{{ user.name }} {{ user.surname }}</div>
    <div class="leaderboard-points" [ngClass]="{'first': user.position === 1, 'is-you': user.current_user}">{{ user.points }}
      pts
    </div>
  </div>
</div>