import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { MatBottomSheet, MatBottomSheetConfig, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(public dialog: MatDialog,
              public bottomSheet: MatBottomSheet) {
  }

  openFixedModal(data: any,
                 component: ComponentType<any>,
                 panelClass: string | string[],
                 width?: number,
                 height?: number): MatDialogRef<any> {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = width ? `${ width }px` : '900px';
    if (height) {
      dialogConfig.height = `${ height }px`;
      dialogConfig.maxHeight = `${ height }px`;
    }
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = panelClass;
    dialogConfig.maxWidth = width ? `${ width }px` : '900px';
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = data;
    dialogConfig.backdropClass = 'backdropBackground';
    dialogConfig.autoFocus = false;

    return this.dialog.open(component, dialogConfig);
  }

  openAdaptableModal(
    data: any, component: ComponentType<any>, panelClass: string,
    minWidth?: number, minHeight?: number,
    maxWidth?: number, maxHeight?: number): MatDialogRef<any> {

    const dialogConfig = new MatDialogConfig();
    if (minWidth) {
      dialogConfig.minWidth = `${ minWidth }px`;
    }
    if (minHeight) {
      dialogConfig.minHeight = `${ minHeight }px`;
    }
    if (maxWidth) {
      dialogConfig.maxWidth = `${ maxWidth }px`;
    }
    if (maxHeight) {
      dialogConfig.maxHeight = `${ maxHeight }px`;
    }
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = panelClass;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = data;
    dialogConfig.backdropClass = 'backdropBackground';
    dialogConfig.autoFocus = false;

    return this.dialog.open(component, dialogConfig);
  }

  openNotificationPopup(data: any, component: ComponentType<any>,
                        panelClass: string, width?: number, height?: number, topPosition?: boolean): MatDialogRef<any> {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = width ? `${ width }px` : '700px';
    dialogConfig.maxWidth = width ? `${ width }px` : '700px';
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = panelClass ? panelClass : 'no-padding-panel';
    dialogConfig.height = height ? `${ height }px` : '215px';
    dialogConfig.maxHeight = height ? `${ width }px` : '215px';
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = data;
    dialogConfig.backdropClass = 'backdropBackground';
    if (topPosition) {
      dialogConfig.position = {top: '20px'};
    }

    return this.dialog.open(component, dialogConfig);
  }

  openBottomModal(data: any, component: ComponentType<any>, panelClass: string): MatBottomSheetRef {

    const bottomSheetConfig = new MatBottomSheetConfig();
    bottomSheetConfig.disableClose = true;
    bottomSheetConfig.panelClass = panelClass;
    bottomSheetConfig.closeOnNavigation = true;
    bottomSheetConfig.data = data;
    bottomSheetConfig.backdropClass = 'backdropBackground';

    return this.bottomSheet.open(component, bottomSheetConfig);
  }
}
