import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { RouterUrls } from '../../router-urls';
import { LanguageService } from '../../../services/language.service';
import { LandingPageComponent } from '../../../core-page/landing-page/landing-page.component';
import { Constants } from '../../../app.constants';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-header-unlogged',
  templateUrl: './header-unlogged.component.html',
  styleUrls: ['./header-unlogged.component.scss'],
  providers: [LandingPageComponent],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0, transform: 'translateY(-10px)' }),
            animate('0.2s ease-out',  style({ opacity: 1, transform: 'translateY(0px)' }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1, transform: 'translateY(0px)' }),
            animate('0.2s ease-in', style({ opacity: 0, transform: 'translateY(-10px)' }))
          ]
        )
      ]
    )
  ]
})
export class HeaderUnloggedComponent implements OnInit, OnDestroy {

  RouterUrls = RouterUrls;

  @Input() transparentHeader: boolean;

  isMobileMenuOpened = false;
  isLanguageListOpened = false;
  isDegraded = true;
  isScrolled: boolean;
  routerSubscription: Subscription;
  isPrivacyMode: boolean;

  constructor(private languageService: LanguageService,
              private landingPage: LandingPageComponent,
              private router: Router) {
  }

  @HostListener('document:mousedown', ['$event'])
  handleOutsideClick(event) {
    if (this.isLanguageListOpened
      && !event.target.classList.contains('language-element')
      && !event.target.classList.contains('language')
      && !event.target.classList.contains('language-icon')
      && !event.target.classList.contains('language-dropdown-arrow')) {
      this.isLanguageListOpened = false;
    }
  }

  get currentLang() {
    return this.languageService.getCurrentLangWithSlash();
  }

  get currentLanguage(): any {
    return this.languageService.getUserUsedLanguage();
  }

  ngOnInit(): void {
    this.routerSubscription = this.setDegradedHeader();
    this.isPrivacyMode = this.router.url.includes(RouterUrls.SamlAuth);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.isScrolled = window.pageYOffset > 0;
  }

  changeLanguage(lang) {
    this.languageService.changeLanguage(lang);
  }

  openCloseMobileMenu() {
    this.isMobileMenuOpened = !this.isMobileMenuOpened;
  }

  openCloseLanguageList() {
    this.isLanguageListOpened = !this.isLanguageListOpened;
  }

  setDegradedHeader() {
    const notDegradedHeaderRoutes = [
      RouterUrls.SignUp,
      RouterUrls.LogIn,
      RouterUrls.SamlAuth,
      RouterUrls.ChangePassword,
      RouterUrls.ResetPassword,
      RouterUrls.ResetPasswordConfirm,
      RouterUrls.TermsOfService,
      RouterUrls.PrivacyPolicy,
      RouterUrls.Contact,
      RouterUrls.BrowseProjects,
      RouterUrls.YourImpact,
      RouterUrls.Profile,
      'redirect=1',
    ];
    const degradedHeaderRoutes = [
      RouterUrls.BrowseProjects,
      RouterUrls.Brands
    ];
    const urlArray = this.router.url.split('/').join(',').split('?').join(',').split(',');
    for (const i in urlArray) {
      if (urlArray[i].includes('redirect=1')) {
        urlArray.push('redirect=1');
      }
    }
    this.isDegraded = !notDegradedHeaderRoutes.some(path => urlArray.includes(path));
    if (degradedHeaderRoutes.some(path => urlArray.includes(path)) && urlArray.length > 3) {
      this.isDegraded = true;
    }
    return this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isPrivacyMode = this.router.url.includes(RouterUrls.SamlAuth);
        const urlArrayEvent = event.url.split('/').join(',').split('?').join(',').split(',');

        for (const i in urlArrayEvent) {
          if (urlArrayEvent[i].includes('redirect=1')) {
            urlArrayEvent.push('redirect=1');
          }
        }

        this.isDegraded = !notDegradedHeaderRoutes.some(path => urlArrayEvent.includes(path));
        if (degradedHeaderRoutes.some(path => urlArrayEvent.includes(path)) && urlArrayEvent.length > 3) {
          this.isDegraded = true;
        }
      }
    });
  }

  navigateToBlog(): void {
    window.open(Constants.BLOG_URL + this.currentLanguage + '/home/', '_blank');
  }

  navigateToStaticPage(): void {
    window.open(Constants.STATIC_PAGE_URL + (this.currentLanguage !== Constants.FR_LANG_KEY ? this.currentLanguage : ''), '_blank');
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }
}
