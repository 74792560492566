import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RouterUrls } from '../shared/router-urls';
import { Observable, of, Subscription } from 'rxjs';
import { TagsService } from '../services/tags.service';
import * as Projects from '../shared/projects.model';
import { ProjectVolunteeringOffer } from '../shared/projects.model';
import { switchMap } from 'rxjs/operators';
import { ProjectsService } from '../services/projects.service';
import { AuthService } from '../services/auth.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { VolunteeringConstants } from './volunteering.constants';
import { FiltersMetadata, FiltersValues, FilterTag, TagMetaData } from './models/volunteering.model';
import { Constants } from '../app.constants';
import { ModalService } from '../shared/services/modal/modal.service';
import { ModalConstants } from '../shared/services/modal/modal.constants';
import {
  VolunteeringMobileFilterModalComponent
} from './components/volunteering-mobile-filter-modal/volunteering-mobile-filter-modal.component';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { User } from '../shared/user.model';
import { Meta, Title } from '@angular/platform-browser';
import { VolunteeringCardModalComponent } from './components/volunteering-card-modal/volunteering-card-modal.component';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import {
  ProposeInitiativeModalComponent
} from '../shared/modals/propose-initiative-modal/propose-initiative-modal.component';
import { MatSelect } from '@angular/material/select';

const DEFAULT_DURATION = 300;

@Component({
  selector: 'app-volunteering',
  templateUrl: './volunteering.component.html',
  styleUrls: ['./volunteering.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('collapse', [
      state('false', style({'margin-top': '16px', height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1})),
      state('true', style({'margin-top': '0px', height: '0', visibility: 'hidden', opacity: 0})),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ]),
    trigger('rotatedState', [
      state('true', style({transform: 'rotate(0)'})),
      state('false', style({transform: 'rotate(-180deg)'})),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in-out')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-in'))
    ]),
  ]
})
export class VolunteeringComponent implements OnInit, OnDestroy {
  public Constants = Constants;
  public VolunteeringConstants = VolunteeringConstants;
  public FILTER_IDS = VolunteeringConstants.FILTER_CRITERIA_IDS;

  @Output() filterOptionsChange$ = new EventEmitter<FiltersMetadata>();

  clickEventSubscription: Subscription;
  RouterUrls = RouterUrls;
  projectVolunteeringOffers$: Observable<Projects.ProjectVolunteeringOffer[]>;
  projectVolunteeringOffers: Projects.ProjectVolunteeringOffer[];
  filteredOffers: Projects.ProjectVolunteeringOffer[];
  private subscriptions = new Subscription();
  paginationLimit = 5;
  offersNumber: number;
  offersNumberWithFilter: number;
  companyName: string;

  userAppliedOffers: number[] = [];
  user: User;


  public categorySelectControl: UntypedFormControl = new UntypedFormControl('');
  public languageSelectControl: UntypedFormControl = new UntypedFormControl('');
  public formatSelectControl: UntypedFormControl = new UntypedFormControl('');
  public locationSelectControl: UntypedFormControl = new UntypedFormControl('');
  public durationSelectControl: UntypedFormControl = new UntypedFormControl('');
  public dedicationSelectControl: UntypedFormControl = new UntypedFormControl('');

  offersFilterForm = new UntypedFormGroup({
    categories: this.categorySelectControl,
    languages: this.languageSelectControl,
    format: this.formatSelectControl,
    location: this.locationSelectControl,
    duration: this.durationSelectControl,
    dedication: this.dedicationSelectControl,
  });

  searchCategories: FilterTag[];
  searchLanguages: FilterTag[];
  searchFormats: FilterTag[];
  searchLocations: FilterTag[];
  searchDurations: FilterTag[];
  searchDedications: FilterTag[];

  categoryIdsToFilterBy = [];
  languageIdsToFilterBy = [];
  formatIdsToFilterBy = [];
  locationIdsToFilterBy = [];
  durationIdsToFilterBy = [];
  dedicationIdsToFilterBy = [];

  filterCategoriesInfo: TagMetaData[] = [];
  filterLanguagesInfo: TagMetaData[] = [];
  filterFormatsInfo: TagMetaData[] = [];
  filterLocationsInfo: TagMetaData[] = [];
  filterDurationInfo: TagMetaData[] = [];
  filterDedicationInfo: TagMetaData[] = [];

// Object to track whether each filter was empty upon the last interaction.
  wasFilterEmpty = {
    [this.FILTER_IDS.CATEGORY_ID]: true,
    [this.FILTER_IDS.LANGUAGES_ID]: true,
    [this.FILTER_IDS.FORMATS_ID]: true,
    [this.FILTER_IDS.LOCATION_ID]: true,
    [this.FILTER_IDS.DURATION_ID]: true,
    [this.FILTER_IDS.DEDICATION_ID]: true,
  };

  private applyFilterChanges = false; // Controla si se deben aplicar los cambios

  screenWidth: number;
  screenHeight: number;
  isMobile = false;
  isLoading = true;
  offerModalOpened = false;
  firstBlockCollapsed = true;
  secondBlockCollapsed = true;

  constructor(
    private titleService: Title,
    private meta: Meta,
    private authService: AuthService,
    private projectsService: ProjectsService,
    private router: Router,
    private modal: ModalService,
    private tagsService: TagsService,
    private translate: TranslateService,
    private route: ActivatedRoute) {
    this.updateTags();
    this.clickEventSubscription = this.tagsService.getClickEventVolunteering().subscribe(() => {
        this.updateTags();
      }
    );
  }

  get currentLang() {
    return this.translate.currentLang;
  }

  ngOnInit(): void {
    this.updateTags();
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.isMobile = this.screenWidth < 768;
    /* Volunteering offers */
    this.subscriptions.add(
      this.projectsService.fullProjectInfo
        .pipe(
          switchMap(() => this.projectsService.getAllProjectVolunteering())
        )
        .subscribe(projectVolunteeringOffers => {
          this.projectVolunteeringOffers = projectVolunteeringOffers;
          this.projectVolunteeringOffers$ = of(projectVolunteeringOffers);
          // SET UP Filters
          this.filteredOffers = this.projectVolunteeringOffers;
          this.offersNumber = projectVolunteeringOffers.length;
          this.searchCategories = this.getTagsToSearch(projectVolunteeringOffers, this.VolunteeringConstants.CATEGORY_KEY);
          this.searchLanguages = this.getTagsToSearch(projectVolunteeringOffers, this.VolunteeringConstants.LANGUAGE_KEY);
          this.searchFormats = this.getTagsToSearch(projectVolunteeringOffers, this.VolunteeringConstants.FORMAT_KEY);
          this.searchLocations = this.getTagsToSearch(projectVolunteeringOffers, this.VolunteeringConstants.LOCATION_KEY);
          this.searchDurations = this.getTagsToSearch(projectVolunteeringOffers, this.VolunteeringConstants.DURATION_KEY);
          this.searchDedications = this.getTagsToSearch(projectVolunteeringOffers, this.VolunteeringConstants.DEDICATION_KEY);
          this.filterCategoriesInfo = this.setTagsMetadata(this.filteredOffers, this.searchCategories);
          this.filterFormatsInfo = this.setTagsMetadata(this.filteredOffers, this.searchFormats);
          this.filterLanguagesInfo = this.setTagsMetadata(this.filteredOffers, this.searchLanguages);
          this.filterFormatsInfo = this.setTagsMetadata(this.filteredOffers, this.searchFormats);
          this.filterLocationsInfo = this.setTagsMetadata(this.filteredOffers, this.searchLocations);
          this.filterDurationInfo = this.setTagsMetadata(this.filteredOffers, this.searchDurations);
          this.filterDedicationInfo = this.setTagsMetadata(this.filteredOffers, this.searchDedications);
          this.offersNumberWithFilter = this.filterOffers(this.projectVolunteeringOffers).length;
          this.isLoading = false;
          this.updateTags();
          // Account info
          this.subscriptions.add(
            this.authService.currentUser.subscribe({
              next: (user) => {
                this.user = user;
                this.companyName = this.user.company_name;
                this.getUserAppliedOffers(this.user.email);
              },
              error: (error) => {
              }
            }));
          // Route query parameters
          this.route.queryParams
            .subscribe(params => {
                if (params['offer-id']) {
                  this.openOfferModal(+params['offer-id']);
                }
              }
            );
        }, () => {
          this.isLoading = false;
          this.updateTags();
        }));

    this.subscriptions.add(
      this.projectsService.userOffersUpdated$.subscribe(() => {
        this.getUserAppliedOffers(this.user.email);
      }));

    this.projectsService.getClockInfo().subscribe(data => {
      if (data) {
        this.companyName = data.company_name;
      }
    });
  }

  //#region FILTERS
  onRestoreIndividualFilter(control: UntypedFormControl, criterionId) {
    control.setValue([]);
    this.onFilterSelectionChange(criterionId, []);
    this.onFilterOffers();
    this.offersNumberWithFilter = this.filterOffers(this.projectVolunteeringOffers).length;
  }

  onApplyIndividualFilterSelection(filterCriteria: number, filterValues: any[]) {
    this.applyFilterChanges = true;
    this.onFilterSelectionChange(filterCriteria, filterValues);
    this.onFilterOffers();
    this.paginationLimit = this.offersNumber;
  }

  onFilterOffers(): void {
    this.projectVolunteeringOffers$ = of(this.filterOffers(this.projectVolunteeringOffers));
  }

  onFilterSelectionChange(filterCriteria: number, filterValues: any[]): void {
    switch (filterCriteria) {
      case this.FILTER_IDS.CATEGORY_ID:
        this.categoryIdsToFilterBy = filterValues;
        break;
      case this.FILTER_IDS.LANGUAGES_ID:
        this.languageIdsToFilterBy = filterValues;
        break;
      case this.FILTER_IDS.FORMATS_ID:
        this.formatIdsToFilterBy = filterValues;
        break;
      case this.FILTER_IDS.LOCATION_ID:
        this.locationIdsToFilterBy = filterValues;
        break;
      case this.FILTER_IDS.DURATION_ID:
        this.durationIdsToFilterBy = filterValues;
        break;
      case this.FILTER_IDS.DEDICATION_ID:
        this.dedicationIdsToFilterBy = filterValues;
        break;
    }
  }

  onSelectOpened(selectCriteria: number) {
    switch (selectCriteria) {
      case this.FILTER_IDS.CATEGORY_ID:
        this.wasFilterEmpty[selectCriteria] = this.categoryIdsToFilterBy.length === 0;
        this.filterCategoriesInfo = this.setTagsMetadata(
          this.filterOffers(this.projectVolunteeringOffers, this.FILTER_IDS.CATEGORY_ID),
          this.searchCategories);
        break;
      case this.FILTER_IDS.LANGUAGES_ID:
        this.wasFilterEmpty[selectCriteria] = this.languageIdsToFilterBy.length === 0;
        this.filterLanguagesInfo = this.setTagsMetadata(
          this.filterOffers(this.projectVolunteeringOffers, this.FILTER_IDS.LANGUAGES_ID),
          this.searchLanguages);
        break;
      case this.FILTER_IDS.FORMATS_ID:
        this.wasFilterEmpty[selectCriteria] = this.formatIdsToFilterBy.length === 0;
        this.filterFormatsInfo = this.setTagsMetadata(
          this.filterOffers(this.projectVolunteeringOffers, this.FILTER_IDS.FORMATS_ID),
          this.searchFormats);
        break;
      case this.FILTER_IDS.LOCATION_ID:
        this.wasFilterEmpty[selectCriteria] = this.locationIdsToFilterBy.length === 0;
        this.filterLocationsInfo = this.setTagsMetadata(
          this.filterOffers(this.projectVolunteeringOffers, this.FILTER_IDS.LOCATION_ID),
          this.searchLocations);
        break;
      case this.FILTER_IDS.DURATION_ID:
        this.wasFilterEmpty[selectCriteria] = this.durationIdsToFilterBy.length === 0;
        this.filterDurationInfo = this.setTagsMetadata(
          this.filterOffers(this.projectVolunteeringOffers, this.FILTER_IDS.DURATION_ID),
          this.searchDurations);
        break;
      case this.FILTER_IDS.DEDICATION_ID:
        this.wasFilterEmpty[selectCriteria] = this.dedicationIdsToFilterBy.length === 0;
        this.filterDedicationInfo = this.setTagsMetadata(
          this.filterOffers(this.projectVolunteeringOffers, this.FILTER_IDS.DEDICATION_ID),
          this.searchDedications);
        break;
    }
  }

  onOptionChanges(filterCriteria: number, newFilterValues: any[]): void {
    this.onPreviewFiltersResults(filterCriteria, newFilterValues);
  }

/**
 * Handles the closing of a filter. It applies changes if they have been made,
 * otherwise restores the filter to its previous state based on whether it was empty.
 */
handleFilterClose(filterCriteria: number, filterControl: UntypedFormControl, matSelect: MatSelect) {
    if (this.applyFilterChanges) {
        // Apply the changes if the apply button was pressed
        filterControl.setValue(matSelect.value);
        this.applyFilterChanges = false;
    } else if (this.wasFilterEmpty[filterCriteria]) {
        // Restore the previous value if the select was closed without applying changes
        this.onRestoreIndividualFilter(filterControl, filterCriteria);
    }
    matSelect.close(); // Close the select options menu
}

  // This method filters offers by different type of criteria, being able to exclude one criterion for selects
  filterOffers(offers: ProjectVolunteeringOffer[], excludeCriteriaIndex?: number): ProjectVolunteeringOffer[] {
    return offers.filter((offer) => {
      const categoriesMatch = excludeCriteriaIndex === this.FILTER_IDS.CATEGORY_ID ? true :
        this.checkMatches(offer.tags.filter((tag: FilterTag) => tag.filter === this.VolunteeringConstants.CATEGORY_KEY),
          this.categoryIdsToFilterBy);
      const languagesMatch = excludeCriteriaIndex === this.FILTER_IDS.LANGUAGES_ID ? true :
        this.checkMatches(offer.tags.filter((tag: FilterTag) => tag.filter === this.VolunteeringConstants.LANGUAGE_KEY),
          this.languageIdsToFilterBy);
      const formatMatch = excludeCriteriaIndex === this.FILTER_IDS.FORMATS_ID ? true :
        this.checkMatches(offer.tags.filter((tag: FilterTag) => tag.filter === this.VolunteeringConstants.FORMAT_KEY),
          this.formatIdsToFilterBy);
      const locationsMatch = excludeCriteriaIndex === this.FILTER_IDS.LOCATION_ID ? true :
        this.checkMatches(offer.tags.filter((tag: FilterTag) => tag.filter === this.VolunteeringConstants.LOCATION_KEY),
          this.locationIdsToFilterBy);
      const durationMatch = excludeCriteriaIndex === this.FILTER_IDS.DURATION_ID ? true :
        this.checkMatches(offer.tags.filter((tag: FilterTag) => tag.filter === this.VolunteeringConstants.DURATION_KEY),
          this.durationIdsToFilterBy);
      const dedicationMatch = excludeCriteriaIndex === this.FILTER_IDS.DEDICATION_ID ? true :
        this.checkMatches(offer.tags.filter((tag: FilterTag) => tag.filter === this.VolunteeringConstants.DEDICATION_KEY),
          this.dedicationIdsToFilterBy);

      return categoriesMatch && languagesMatch && formatMatch && locationsMatch && durationMatch && dedicationMatch;
    });
  }

  openMobileFilterMenu(): void {
    const filtersOptions: FiltersMetadata = {
      filterCategoriesInfo: this.filterCategoriesInfo,
      filterLanguagesInfo: this.filterLanguagesInfo,
      filterFormatsInfo: this.filterFormatsInfo,
      filterLocationsInfo: this.filterLocationsInfo,
      filterDurationInfo: this.filterDurationInfo,
      filterDedicationInfo: this.filterDedicationInfo,
      language: this.currentLang,
      offersNumber: this.filterOffers(this.projectVolunteeringOffers).length
    };

    const filtersValues: FiltersValues = {
      categoryIdsToFilterBy: this.categoryIdsToFilterBy,
      languageIdsToFilterBy: this.languageIdsToFilterBy,
      formatIdsToFilterBy: this.formatIdsToFilterBy,
      locationIdsToFilterBy: this.locationIdsToFilterBy,
      durationIdsToFilterBy: this.durationIdsToFilterBy,
      dedicationIdsToFilterBy: this.dedicationIdsToFilterBy
    };

    const modalRef: MatBottomSheetRef = this.modal.openBottomModal({filtersOptions, filtersValues},
      VolunteeringMobileFilterModalComponent, ModalConstants.FULL_SCREEN_BOTTOM_PANEL_CLASS);

    this.connectModalFilters(modalRef);
  }

  updateAllFilterOptions(): void {
    this.filterCategoriesInfo = this.setTagsMetadata(
      this.filterOffers(this.projectVolunteeringOffers, this.FILTER_IDS.CATEGORY_ID),
      this.searchCategories);

    this.filterLanguagesInfo = this.setTagsMetadata(
      this.filterOffers(this.projectVolunteeringOffers, this.FILTER_IDS.LANGUAGES_ID),
      this.searchLanguages);

    this.filterFormatsInfo = this.setTagsMetadata(
      this.filterOffers(this.projectVolunteeringOffers, this.FILTER_IDS.FORMATS_ID),
      this.searchFormats);

    this.filterLocationsInfo = this.setTagsMetadata(
      this.filterOffers(this.projectVolunteeringOffers, this.FILTER_IDS.LOCATION_ID),
      this.searchLocations);

    this.filterDurationInfo = this.setTagsMetadata(
      this.filterOffers(this.projectVolunteeringOffers, this.FILTER_IDS.DURATION_ID),
      this.searchDurations);

    this.filterDedicationInfo = this.setTagsMetadata(
      this.filterOffers(this.projectVolunteeringOffers, this.FILTER_IDS.DEDICATION_ID),
      this.searchDedications);

    this.offersNumberWithFilter = this.filterOffers(this.projectVolunteeringOffers).length;

    this.filterOptionsChange$.next({
      filterCategoriesInfo: this.filterCategoriesInfo,
      filterLanguagesInfo: this.filterLanguagesInfo,
      filterFormatsInfo: this.filterFormatsInfo,
      filterLocationsInfo: this.filterLocationsInfo,
      filterDurationInfo: this.filterDurationInfo,
      filterDedicationInfo: this.filterDedicationInfo,
      language: this.currentLang,
      offersNumber: this.offersNumberWithFilter
    });
  }

  setTagsMetadata(offers: ProjectVolunteeringOffer[], searchTags: FilterTag[]): TagMetaData[] {
    const filterTagsData = [];
    searchTags?.forEach((sTag: FilterTag) => {
      let tagCount = 0;
      offers.forEach((offer: ProjectVolunteeringOffer) => {
        if (offer.tags.some((tag: FilterTag) => tag.id === sTag.id && tag.filter === sTag.filter)) {
          tagCount++;
        }
      });
      filterTagsData.push({tag: sTag, offersNumber: tagCount});
    });
    return filterTagsData;
  }

  getTagsToSearch(offers, tagFilterKey: string): FilterTag[] {
    const availableSearchTags = [];
    for (const offer of offers) {
      const searchTagsOnOffer = offer.tags.filter((tag: FilterTag) => tag.filter === tagFilterKey);
      for (const searchTag of searchTagsOnOffer) {
        if (!availableSearchTags.some((tag: FilterTag) => tag.id === searchTag.id)) {
          availableSearchTags.push(searchTag);
        }
      }
    }
    return availableSearchTags.sort((a, b) => a.priority - b.priority);
  }

  onRestoreAllFilters(): void {
    Object.keys(this.offersFilterForm.controls).forEach((key) => {
      this.offersFilterForm.get(key).setValue([]);
    });
    this.categoryIdsToFilterBy = [];
    this.languageIdsToFilterBy = [];
    this.formatIdsToFilterBy = [];
    this.locationIdsToFilterBy = [];
    this.durationIdsToFilterBy = [];
    this.dedicationIdsToFilterBy = [];
    this.projectVolunteeringOffers$ = of(this.projectVolunteeringOffers);
    this.offersNumberWithFilter = this.filterOffers(this.projectVolunteeringOffers).length;
    this.paginationLimit = 5;
  }

  onPreviewFiltersResults(filterCriteria: number, newFilterValues: any[]): void {
    switch (filterCriteria) {
      case this.FILTER_IDS.CATEGORY_ID:
        this.categoryIdsToFilterBy = newFilterValues;
        break;
      case this.FILTER_IDS.LANGUAGES_ID:
        this.languageIdsToFilterBy = newFilterValues;
        break;
      case this.FILTER_IDS.FORMATS_ID:
        this.formatIdsToFilterBy = newFilterValues;
        break;
      case this.FILTER_IDS.LOCATION_ID:
        this.locationIdsToFilterBy = newFilterValues;
        break;
      case this.FILTER_IDS.DURATION_ID:
        this.durationIdsToFilterBy = newFilterValues;
        break;
      case this.FILTER_IDS.DEDICATION_ID:
        this.dedicationIdsToFilterBy = newFilterValues;
        break;
    }
    this.updateAllFilterOptions();
  }

  showMore() {
    this.paginationLimit += 8;
  }

  //#endregion FILTERS

  onProposeOfferButtonClick(): void {
    let formUrl: string;
    switch (this.currentLang) {
      case Constants.FR_LANG_KEY:
        formUrl = VolunteeringConstants.PROPOSE_OFFER_FORM_URL_FR;
        break;
      case Constants.ES_LANG_KEY:
        formUrl = VolunteeringConstants.PROPOSE_OFFER_FORM_URL_ES;
        break;
      case Constants.EN_LANG_KEY:
        formUrl = VolunteeringConstants.PROPOSE_OFFER_FORM_URL_EN;
        break;
      default :
        formUrl = VolunteeringConstants.PROPOSE_OFFER_FORM_URL_FR;
        break;
    }
    window.open(formUrl, '_blank');
  }

  openOfferModal(offerId: number): void {
    const modalHeight = window.innerWidth > 767 ? 400 : window.innerHeight;
    const modalWidth = window.innerWidth > 767 ? 680 : window.innerWidth;
    const offerToOpen = this.projectVolunteeringOffers.find((offer) => {
      return offer.id === offerId;
    });

    if (offerToOpen && !this.offerModalOpened) {
      this.offerModalOpened = true;
      const volunteeringCardModalRef = this.modal.openAdaptableModal({}, VolunteeringCardModalComponent,
        window.innerWidth > 767 ? ModalConstants.NO_PADDING_PANEL_RADIUS_15_CLASS :
          ModalConstants.DEFAULT_FULLSCREEN_PANEL_CLASS, modalWidth, modalHeight, modalWidth);
      volunteeringCardModalRef.componentInstance.offer = offerToOpen;
      volunteeringCardModalRef.componentInstance.user = this.user;
      volunteeringCardModalRef.componentInstance.currentLang = this.currentLang;
      volunteeringCardModalRef.afterClosed().subscribe({
        next: () => {
          this.offerModalOpened = false;
        }
      });
    }
  }

  onOfferModalOpenStatusChanges(opened: boolean): void {
    this.offerModalOpened = opened;
  }

  updateTags() {
    this.titleService.setTitle(this.translate.instant('VOLUNTEERING_TAB.meta_title_volunteering'));
    this.meta.updateTag({
      name: 'description',
      content: this.translate.instant('VOLUNTEERING_TAB.meta_description_volunteering')
    });
    this.meta.updateTag({
      property: 'og:title',
      content: this.translate.instant('VOLUNTEERING_TAB.meta_title_volunteering')
    });
    this.meta.updateTag({
      property: 'og:description',
      content: this.translate.instant('VOLUNTEERING_TAB.meta_description_volunteering')
    });
    this.meta.updateTag({property: 'og:type', content: 'URL'});
    this.meta.updateTag({property: 'og:url', content: window.location.href});
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://noos.ams3.digitaloceanspaces.com/volunteering-backend-img.png'
    });
  }

  openProposeMissionModal() {
    const isMobile = this.screenWidth <= 767;
    const panelClass = isMobile ? ModalConstants.NO_PADDING_FULLSCREEN_PANEL_CLASS : ModalConstants.DEFAULT_PANEL_CLASS;
    const dialogWidth = isMobile ? window.innerWidth : 600;
    const dialogHeight = isMobile ? window.innerHeight : 460;
    const maxHeight = window.innerHeight > 716 ? 716 : window.innerHeight;

    const dialogRef = this.modal.openAdaptableModal(null, ProposeInitiativeModalComponent, panelClass, dialogWidth, dialogHeight, 600, maxHeight);
    dialogRef.componentInstance.user = this.user;
    dialogRef.componentInstance.initiativeType = Constants.INITIATIVE_TYPE_VOLUNTEERING;
    dialogRef.componentInstance.title = this.translate.instant('INITIATIVE_PROPOSAL_MODAL.add_mission');
    dialogRef.componentInstance.explanation1 = this.translate.instant('INITIATIVE_PROPOSAL_MODAL.mission_explanation_1');
    dialogRef.componentInstance.explanation2 = this.translate.instant('INITIATIVE_PROPOSAL_MODAL.mission_explanation_2');
    dialogRef.componentInstance.explanation3 = this.translate.instant('INITIATIVE_PROPOSAL_MODAL.mission_suggestion_text');
  }

  private connectModalFilters(modalRef: MatBottomSheetRef): void {
    modalRef.instance.$filterOptionsChange = this.filterOptionsChange$;
    // Applies the change from the mobile modal and updates filters metadata.
    modalRef.instance.optionChanges$.subscribe((data: any) => {
      this.onPreviewFiltersResults(data.filterCriteria, data.newFilterValues);
    });

    // Gets all filter values on the mobile modal and filters offers.
    modalRef.instance.applyAllFilters$.subscribe((filtersValues: FiltersValues) => {
      this.onFilterSelectionChange(this.FILTER_IDS.CATEGORY_ID, filtersValues.categoryIdsToFilterBy);
      this.onFilterSelectionChange(this.FILTER_IDS.LANGUAGES_ID, filtersValues.languageIdsToFilterBy);
      this.onFilterSelectionChange(this.FILTER_IDS.FORMATS_ID, filtersValues.formatIdsToFilterBy);
      this.onFilterSelectionChange(this.FILTER_IDS.LOCATION_ID, filtersValues.locationIdsToFilterBy);
      this.onFilterSelectionChange(this.FILTER_IDS.DURATION_ID, filtersValues.durationIdsToFilterBy);
      this.onFilterSelectionChange(this.FILTER_IDS.DEDICATION_ID, filtersValues.dedicationIdsToFilterBy);
      this.updateAllFilterOptions();
      this.onFilterOffers();
    });
  }

  private getUserAppliedOffers(userEmail: string) {
    this.projectsService.getUserAppliedProjects(userEmail).subscribe((data) => {
      this.userAppliedOffers = data?.offers;
      if (this.userAppliedOffers && this.userAppliedOffers.length > 0) {
        this.projectVolunteeringOffers.forEach((offer: ProjectVolunteeringOffer) => {
          offer.hasApplied = this.hasApplied(offer.id);
        });
        this.projectVolunteeringOffers$ = of(this.projectVolunteeringOffers);
      }
    });
  }

  onTypeformClick(currentLang: string) {
    let url: string;
    if (currentLang === 'es') {
      url = 'https://noos.typeform.com/to/v5CHyp75';
    } else {
      if (currentLang === 'en') {
        url = 'https://noos.typeform.com/to/doKcHsMp';
      } else {
        url = 'https://noos.typeform.com/to/H0KpiFYB';
      }
    }
    window.open(url, '_blank');
  }

  private checkMatches(match: string | number | FilterTag [], filterValues: any[]): boolean {
    let matches = false;
    if (filterValues.length > 0) {
      for (const matchValue of match as FilterTag[]) {
        for (const filterValue of filterValues) {
          if (matchValue.id === filterValue) {
            matches = true;
          }
          // BUSINESS LOGIC: If we filter by 'onsite' or 'remote' in the 'format' criteria,
          // hybrid format offers still appear, since 'hybrid' implies both 'onsite' and 'remote' format.
          if (matchValue.name === this.VolunteeringConstants.HYBRID_TAG_KEY &&
            matchValue.filter === this.VolunteeringConstants.FORMAT_KEY) {
            matches = true;
          }
        }
      }
    } else {
      matches = true;
    }
    return matches;
  }

  private hasApplied(projectId: number): boolean {
    return this.userAppliedOffers.includes(projectId);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
