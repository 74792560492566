export const ModalConstants = Object.freeze({

  // MODALS
  PROJECT_PANEL_CLASS: 'project-dialog',
  VOTE_PANEL_CLASS: 'vote-dialog',
  CAUSE_PANEL_CLASS: 'cause-dialog',
  VALIDATE_IMPACT_PANEL_CLASS: 'validate-impact-dialog',
  MATCHING_GIFT_PANEL_CLASS: 'matching-gift-dialog',
  NO_KPI_MATCHING_GIFT_PANEL_CLASS: 'no-kpi-matching-gift-dialog',
  CRISIS_EVENT_PANEL_CLASS: 'crisis-event-dialog',
  CONTINENT_PANEL_CLASS: 'continent-dialog',
  COUNTRY_PANEL_CLASS: 'country-dialog',
  CREDENTIAL_CHANGED_PANEL_CLASS: 'credential-changed-dialog',
  CHANGE_EMAIL_PANEL_CLASS: 'change-email-dialog',
  CHANGE_PASSWORD_PANEL_CLASS: 'change-password-dialog',
  EDIT_PAYMENT_PANEL_CLASS: 'edit-payment-dialog',
  STOP_SUBSCRIPTION_PANEL_CLASS: 'stop-subscription-dialog',
  DEFAULT_FULLSCREEN_PANEL_CLASS: 'default-fullscreen-panel',
  NO_PADDING_FULLSCREEN_PANEL_CLASS: 'no-padding-fullscreen-panel',
  FULLSCREEN_VISIBLE_HEADER_PANEL_CLASS: 'fullscreen-visible-header-panel',
  DEFAULT_OLD_PANEL_CLASS: 'default-old-panel',
  DEFAULT_PANEL_CLASS: 'default-panel',
  CHALLENGE_DETAIL_PANEL_CLASS: 'challenge-detail-panel',
  PADDING_16_PANEL_CLASS: 'padding-16-panel',
  NO_PADDING_PANEL_CLASS: 'no-padding-panel',
  NO_PADDING_PANEL_RADIUS_15_CLASS: 'no-padding-radius-15-panel',
  QUIZ_FULLSCREEN_PANEL_CLASS: 'quiz-fullscreen-panel',
  CHALLENGE_RESULT_PANEL_CLASS: 'challenge-result-panel',
  LAC_ONBOARDING_PANEL_CLASS: 'lac-onboarding-panel',


  // BOTTOM SHEETS
  FULL_SCREEN_BOTTOM_PANEL_CLASS: 'full-screen-bottom-sheet',

  // MODAL QUEUE IDS
  modalIds: {
    //LAYER 7: PRODUCT ONBOARDINGS
    WELCOME_ONBOARDING_ID: 'welcomeOnboarding',
    FIRST_VOTE_OB_ID: 'firstVoteOnboarding',
    SUPPORT_OB_ID: 'supportOnboarding',
    VOLUNTEERING_OB_ID: 'volunteeringOnboarding',
    //LAYER 8: VOTE SYSTEM
    VOTE_CONFIRMATION_ID: 'voteConfirmation',
    //LAYER 9: SYSTEM
    SOFT_ASK_ID: 'softAsk'
  }
});
