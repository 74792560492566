import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  private logInSubject = new Subject<void>();
  private signUpSubject = new Subject<void>();
  private browseProjectsSubject = new Subject<void>();
  private projectSubject = new Subject<void>();
  private volunteeringSubject = new Subject<void>();
  private vettingProcessSubject = new Subject<void>();
  private impactOrganizationsSubject = new Subject<void>();
  private termsOfServiceSubject = new Subject<void>();
  private privacyPolicySubject = new Subject<void>();
  private impactSubject = new Subject<void>();
  private impactProjectsSubject = new Subject<void>();
  private profileSettingsSubject = new Subject<void>();
  private brandSubject = new Subject<void>();
  private brandProgramSubject = new Subject<void>();
  private landingSubject = new Subject<void>();
  private brandsSubject = new Subject<void>();
  private matchingGiftSubject = new Subject<void>();
  private campaignSubject = new Subject<void>();
  private challengeSubject = new Subject<void>();
  private homeSubject = new Subject<void>();

  constructor() {
  }

  sendClickEventLogIn() {
    this.logInSubject.next();
  }

  getClickEventLogIn(): Observable<any> {
    return this.logInSubject.asObservable();
  }

  sendClickEventSignUp() {
    this.signUpSubject.next();
  }

  getClickEventSignUp(): Observable<any>{
    return this.signUpSubject.asObservable();
  }

  sendClickEventLanding() {
    this.landingSubject.next();
  }

  getClickEventLanding(): Observable<any> {
    return this.landingSubject.asObservable();
  }

  sendClickEventBrowseProjects() {
    this.browseProjectsSubject.next();
  }

  getClickEventBrowseProjects(): Observable<any> {
    return this.browseProjectsSubject.asObservable();
  }

  sendClickEventVolunteering() {
    this.volunteeringSubject.next();
  }

  getClickEventVolunteering(): Observable<any> {
    return this.volunteeringSubject.asObservable();
  }

  sendClickEventProject() {
    this.projectSubject.next();
  }

  getClickEventProject(): Observable<any> {
    return this.projectSubject.asObservable();
  }

  sendClickEventImpactOrganizations() {
    this.impactOrganizationsSubject.next();
  }

  getClickEventVettingProcess(): Observable<any> {
    return this.vettingProcessSubject.asObservable();
  }

  getClickEventImpactOrganizations(): Observable<any> {
    return this.impactOrganizationsSubject.asObservable();
  }

  sendClickEventTermsOfService() {
    this.termsOfServiceSubject.next();
  }

  getClickEventTermsOfService(): Observable<any>{
    return this.termsOfServiceSubject.asObservable();
  }

  sendClickEventPrivacyPolicy() {
    this.privacyPolicySubject.next();
  }

  getClickEventPrivacyPolicy(): Observable<any>{
    return this.privacyPolicySubject.asObservable();
  }

  sendClickEventImpact() {
    this.impactSubject.next();
  }

  getClickEventImpact(): Observable<any> {
    return this.impactSubject.asObservable();
  }

  sendClickEventImpactProjects() {
    this.impactProjectsSubject.next();
  }

  getClickEventImpactProjects(): Observable<any>{
    return this.impactProjectsSubject.asObservable();
  }

  sendClickEventProfileSettings() {
    this.profileSettingsSubject.next();
  }

  getClickEventProfileSettings(): Observable<any>{
    return this.profileSettingsSubject.asObservable();
  }

  sendClickEventBrand() {
    this.brandSubject.next();
  }

  getClickEventBrand(): Observable<any>{
    return this.brandSubject.asObservable();
  }

  sendClickEventBrandProgram() {
    this.brandProgramSubject.next();
  }

  getClickEventBrandProgram(): Observable<any>{
    return this.brandProgramSubject.asObservable();
  }

  sendClickEventBrands() {
    this.brandsSubject.next();
  }

  getClickEventBrands(): Observable<any> {
    return this.brandsSubject.asObservable();
  }

  sendClickEventMatchingGift() {
    this.matchingGiftSubject.next();
  }

  getClickEventMatchingGift(): Observable<any> {
    return this.matchingGiftSubject.asObservable();
  }

  sendClickEventCampaign(): void {
    this.campaignSubject.next();
  }

  getClickEventCampaign(): Observable<any> {
    return this.campaignSubject.asObservable();
  }

  sendClickEventChallenge(): void {
    this.challengeSubject.next();
  }

  getClickEventChallenge(): Observable<any> {
    return this.challengeSubject.asObservable();
  }

  sendClickEventHome(): void {
    this.homeSubject.next();
  }

  getClickEventHome(): Observable<any> {
    return this.homeSubject.asObservable();
  }
}

