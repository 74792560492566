<header [class.transparent]="isScrolled"
        class="ns-simple-public-header">
  <div class="container">
    <a [routerLink]="currentLang" class="d-flex align-items-center">
      <img alt="NooS logo" class="noos-logo" src="/assets/icons/logo-white-2.svg">
    </a>

    <div class="languages-container">
      <div (click)="changeLanguage('fr')" [ngClass]="{'active-lang': currentLang === '/fr'}">FR</div>
      <span>|</span>
      <div (click)="changeLanguage('en')" [ngClass]="{'active-lang': currentLang === '/en'}">EN</div>
    </div>
  </div>
</header>
