<div class="card-container">
    <div class="card-item">
      <h2>{{ "RESET_PASSWORD_CONFIRM_PAGE.change_password_resetpasswordconfirm" | translate }}</h2>
      <form [formGroup]="resetPassForm" (ngSubmit)="resetPassword()">
        <div class="form-grid">
          <label><span>{{ "RESET_PASSWORD_CONFIRM_PAGE.new_password_resetpasswordconfirm" | translate }}</span><span> *</span>
            <input type="password" placeholder="Enter new password" formControlName="password" (appCapslock)="isCapsOn = $event">
            <img loading="lazy" src="assets/icons/password-{{isPasswordShownFirst ? 'shown' : 'hidden'}}.svg" alt="show password"
                 (appShowPassword)="isPasswordShownFirst = $event">
            <span class="caps-lock-alert" *ngIf="isCapsOn">{{ "RESET_PASSWORD_CONFIRM_PAGE.caps_lock_resetpasswordconfirm" | translate }}</span>
            <div class="password-strength" [ngSwitch]="passwordStrength">{{ "RESET_PASSWORD_CONFIRM_PAGE.password_strength_resetpasswordconfirm" | translate }}
              <span style="color: #EB5757;" *ngSwitchCase="0">{{ "RESET_PASSWORD_CONFIRM_PAGE.password_weak_resetpasswordconfirm" | translate }}</span>
              <span style="color: #EB5757;" *ngSwitchCase="1">{{ "RESET_PASSWORD_CONFIRM_PAGE.password_weak_resetpasswordconfirm" | translate }}</span>
              <span style="color: #E3A903;" *ngSwitchCase="2">{{ "RESET_PASSWORD_CONFIRM_PAGE.password_medium_resetpasswordconfirm" | translate }}</span>
              <span style="color: #E3A903;" *ngSwitchCase="3">{{ "RESET_PASSWORD_CONFIRM_PAGE.password_medium_resetpasswordconfirm" | translate }}</span>
              <span style="color: #34D1B0;" *ngSwitchCase="4">{{ "RESET_PASSWORD_CONFIRM_PAGE.password_strong_resetpasswordconfirm" | translate }}</span>
            </div>
            <password-strength-meter class="d-none" [password]="resetPassForm.value.password"
                                     (strengthChange)="changePassStrength($event)"></password-strength-meter>
            <div class="password-recommendation">
              {{ "RESET_PASSWORD_CONFIRM_PAGE.password_recommendation_resetpasswordconfirm" | translate }}
            </div>
          </label>
          <label><span>{{ "RESET_PASSWORD_CONFIRM_PAGE.confirm_password_resetpasswordconfirm" | translate }}</span><span> *</span>
            <input type="password" placeholder="Enter new password again" formControlName="confirmPassword" (appCapslock)="isCapsOn = $event">
            <img loading="lazy" src="assets/icons/password-{{isPasswordShownSecond ? 'shown' : 'hidden'}}.svg" alt="show password"
                 (appShowPassword)="isPasswordShownSecond = $event">
            <span class="caps-lock-alert" *ngIf="isCapsOn">{{ "RESET_PASSWORD_CONFIRM_PAGE.caps_lock_resetpasswordconfirm" | translate }}</span>
            <div *ngIf="resetPassForm.invalid" class="error-messages">
              <div *ngIf="password.errors?.required && password.touched">{{ "RESET_PASSWORD_CONFIRM_PAGE.password_required_resetpasswordconfirm" | translate }}</div>
              <div *ngIf="password.errors?.minlength && password.touched">{{ "RESET_PASSWORD_CONFIRM_PAGE.password_length_resetpasswordconfirm" | translate }}</div>
              <div *ngIf="confirmPassword.errors?.required && confirmPassword.touched">{{ "RESET_PASSWORD_CONFIRM_PAGE.password_repeat_resetpasswordconfirm" | translate }}</div>
              <div *ngIf="confirmPassword.errors?.mustMatch && confirmPassword.touched">{{ "RESET_PASSWORD_CONFIRM_PAGE.different_password_resetpasswordconfirm" | translate }}</div>
            </div>
          </label>
        </div>
        <button class="green sans-serif" type="submit" [disabled]="resetPassForm.invalid">{{ "RESET_PASSWORD_CONFIRM_PAGE.change_password_resetpasswordconfirm" | translate }}</button>
      </form>
    </div>
  </div>
