export const VolunteeringConstants = Object.freeze({

  FILTER_CRITERIA_IDS: {
    CATEGORY_ID: 0,
    LANGUAGES_ID: 1,
    FORMATS_ID: 2,
    LOCATION_ID: 3,
    DURATION_ID: 4,
    DEDICATION_ID: 5,
  },

  CATEGORY_KEY: 'category',
  LANGUAGE_KEY: 'language',
  FORMAT_KEY: 'format',
  LOCATION_KEY: 'location',
  DURATION_KEY: 'duration',
  DEDICATION_KEY: 'dedication',

  HYBRID_TAG_KEY: 'hybrid',

  NO_PROJECT_OFFER_KEY: 'noprojectoffer',

  PROPOSE_OFFER_FORM_URL_FR: 'https://noos.typeform.com/bnv-candidature',
  PROPOSE_OFFER_FORM_URL_EN: 'https://noos.typeform.com/vol-application',
  PROPOSE_OFFER_FORM_URL_ES: 'https://noos.typeform.com/vol-solicitud'

});
