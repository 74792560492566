import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { LanguageService } from '../services/language.service';
import { RouterUrls } from '../shared/router-urls';
import { User } from '../shared/user.model';

export const LacOnlyGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const languageService = inject(LanguageService);

  return authService.getCurrentUser().pipe(
    switchMap((user: User) => {
      if (user.lac_only) {
        // Redirect to the campaign page in the current language
        return of(true).pipe(
          tap(() => {
            router.navigate([languageService.getCurrentLangWithSlash(), RouterUrls.Campaign]);
          })
        );
      } else {
        // Allow navigation if not lac only mode
        return of(true);
      }
    }),
    catchError(() => {
      return of(true);
    })
  );
};
