import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AuthService } from '../../services/auth.service';
import { RouterUrls } from '../../shared/router-urls';
import { CustomValidators } from '../../shared/custom-validators';
import { AuthConstants } from '../auth.constants';
import { AccountAuthType } from '../../shared/user.model';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: UntypedFormGroup;
  RouterUrls = RouterUrls;
  errorMessage = '';
  isMessageSent = false;

  constructor(private formBuilder: UntypedFormBuilder,
              private authService: AuthService,
              ) { }

  ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(CustomValidators.emailValidationRegex)]],
    });
  }

  resetPassword() {
    this.authService.getAccountAuthType({email: this.resetPasswordForm.value.email})
      .subscribe((authType: AccountAuthType) => {
        if (authType.sso === AuthConstants.AUTH_TYPE_NOOS) {
          this.authService.resetPassword(this.resetPasswordForm.value.email).subscribe(
            res => {
              this.isMessageSent = true;
            },
            error => {
              this.errorMessage = error.error;
              this.email.setErrors({wrongCredentials: true});
            }
          );
        } else {
          this.email.setErrors({usesSsoAuth: true});
        }
      });

  }

  get email() {
    return this.resetPasswordForm.get('email');
  }
}
