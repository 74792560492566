import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appShowPassword]'
})
export class ShowPasswordDirective {

  @Output() appShowPassword = new EventEmitter<boolean>();

  constructor(private elementRef: ElementRef) { }

  @HostListener('click')
  onClick() {
    const passwordInput = this.elementRef.nativeElement.previousSibling;
    const passwordInputType = passwordInput.getAttribute('type');
    if (passwordInputType === 'password') {
      passwordInput.setAttribute('type', 'text');
      this.appShowPassword.emit(true);
    } else {
      passwordInput.setAttribute('type', 'password');
      this.appShowPassword.emit(false);
    }
  }
}
