import {Component, OnInit} from '@angular/core';

import {RouterUrls} from '../shared/router-urls';
import {Router} from '@angular/router';
import {LanguageService} from '../services/language.service';

@Component({
  selector: 'app-page-four-o-four',
  templateUrl: './page-four-o-four.component.html',
  styleUrls: ['./page-four-o-four.component.scss']
})
export class PageFourOFourComponent implements OnInit {

  RouterUrls = RouterUrls;

  constructor(private router: Router, private languageService: LanguageService) { }

  ngOnInit(): void {
    if (this.router.url !== RouterUrls.FourOFour) {
      this.router.navigate(
        [this.languageService.getCurrentLangWithSlash(), RouterUrls.FourOFour]);
    }
  }

}
