import { Component, HostListener, OnInit } from '@angular/core';
import { LanguageService } from '../../../services/language.service';

@Component({
  selector: 'ns-simple-public-header',
  templateUrl: './simple-public-header.component.html',
  styleUrls: ['./simple-public-header.component.scss']
})
export class SimplePublicHeaderComponent implements OnInit {
  isScrolled: boolean;

  constructor(private languageService: LanguageService) {
  }

  get currentLang() {
    return this.languageService.getCurrentLangWithSlash();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.isScrolled = window.pageYOffset > 0;
  }

  ngOnInit(): void {
  }

  changeLanguage(lang) {
    this.languageService.changeLanguage(lang);
  }
}
