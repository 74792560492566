import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Constants } from '../../../app.constants';
import { VolunteeringConstants } from '../../volunteering.constants';
import { ProjectsService } from '../../../services/projects.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '../../../shared/user.model';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { VolunteeringEmployeeContact, VolunteeringRelatedEmployees } from '../../models/volunteering-share.model';
import { ModalConstants } from '../../../shared/services/modal/modal.constants';
import {
  VolunteeringShareSuccessModalComponent
} from '../volunteering-share-success-modal/volunteering-share-success-modal.component';
import { ModalService } from '../../../shared/services/modal/modal.service';

@Component({
  selector: 'app-volunteering-share-modal',
  templateUrl: './volunteering-share-modal.component.html',
  styleUrls: ['./volunteering-share-modal.component.scss']
})
export class VolunteeringShareModalComponent implements OnInit {
  readonly Constants = Constants;
  readonly VolunteeringConstants = VolunteeringConstants;

  searchInput: UntypedFormControl = new UntypedFormControl('');
  userMessageTextarea: UntypedFormControl = new UntypedFormControl('');
  userMessageInput: UntypedFormControl = new UntypedFormControl('');
  isSearchFocusActive = false;
  showSearchDropdown = false;
  maxContactNumberReached = false;
  noContactSelected = false;
  isMobile = false;
  isDataReady = false;
  currentStep = 1;
  maxContactsNumber = 10;

  user: User;
  userContacts: VolunteeringEmployeeContact[];
  filteredUserContacts: VolunteeringEmployeeContact[];
  userContactsToShare: VolunteeringEmployeeContact[] = [];
  noProfilePicture = '/assets/icons/no-avatar.png';
  offerId: number;

  screenWidth: number;
  screenHeight: number;

  constructor(private dialogRef: MatDialogRef<VolunteeringShareModalComponent>,
              @Inject(MAT_DIALOG_DATA)
                data,
              private projectsService: ProjectsService,
              private translate: TranslateService,
              private modal: ModalService,
  ) {
    this.user = data.user;
    this.offerId = data.offerId;
  }

  @HostListener('document:mousedown', ['$event'])
  handleOutsideClick(event) {
    if (this.showSearchDropdown
      && !event.target.classList.contains('search-input')
      && !event.target.classList.contains('search-input-dropdown')
      && !event.target.classList.contains('search-input-label')
      && !event.target.classList.contains('contact-detail-name')
      && !event.target.classList.contains(Constants.BUTTON_TYPE_DEFAULT_DARK_BLUE_SOLID_S)
      && !event.target.classList.contains('contact-details')) {
      this.showSearchDropdown = false;
    }
  }

  ngOnInit(): void {
    this.isMobile = window.innerWidth <= 768;
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.projectsService.getUserRelatedEmployees(this.user?.id).subscribe({
      next: (relatedEmployees: VolunteeringRelatedEmployees) => {
        this.isDataReady = true;
        this.userContacts = relatedEmployees.employees;
        this.userContacts.sort((a, b) => a.name.localeCompare(b.name));
        this.filteredUserContacts = this.userContacts;
      }
    });
    this.searchInput.valueChanges.subscribe({
      next: (value: string) => {
        this.removeHoverStatus();
        if (value.length > 0) {
          const normalizedValue = value.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');

          this.filteredUserContacts = this.userContacts?.filter((contact: VolunteeringEmployeeContact) => {
            const fullName = `${ contact.name } ${ contact.surname }`.toLowerCase()
              .normalize('NFD').replace(/\p{Diacritic}/gu, '');
            return fullName.includes(normalizedValue);
          });
        } else {
          this.filteredUserContacts = this.userContacts;
        }
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  isSearchDropdownOpen() {
    return !this.isMobile ? ((this.isSearchFocusActive || this.showSearchDropdown) && this.searchInput.value.length > 0) : false;
  }

  onAddContactToShare(contact: VolunteeringEmployeeContact): void {
    this.noContactSelected = false;
    if (this.userContactsToShare.length < this.maxContactsNumber) {
      contact.addButton = false;
      this.userContactsToShare.push(contact);
      if (this.isMobile && this.searchInput.value.length > 0) {
        contact.foundWithSearchBar = true;
      }
      if (!this.isMobile) {
        const index = this.userContacts
          .findIndex((employeeContact) => employeeContact.id === contact.id);
        this.userContacts.splice(index, 1);
      }
    } else {
      this.maxContactNumberReached = true;
    }
    this.showSearchDropdown = false;
    this.searchInput.setValue('');
  }

  onRemoveContactToShare(contact: VolunteeringEmployeeContact): void {
    const index = this.userContactsToShare
      .findIndex((employeeContact) => employeeContact.id === contact.id);
    this.userContactsToShare.splice(index, 1);
    if (!this.isMobile) {
      this.userContacts.push(contact);
      this.userContacts.sort((a, b) => a.name.localeCompare(b.name));
    }
    this.maxContactNumberReached = false;
  }

  onCheckRadioButton(contact: VolunteeringEmployeeContact) {
    if (!contact.checked) {
      if (this.userContactsToShare.length < this.maxContactsNumber) {
        contact.checked = true;
        this.onAddContactToShare(contact);
      } else {
        this.maxContactNumberReached = true;
      }
    } else {
      contact.checked = false;
      this.onRemoveContactToShare(contact);
    }
  }

  shareVolunteeringOffer(): void {
    if (this.userContactsToShare.length > 0) {
      const contactsIds: number[] = [];
      this.userContactsToShare.forEach((contact: VolunteeringEmployeeContact) => {
        contactsIds.push(contact.id);
      });
      const userMessage = this.isMobile ? this.userMessageInput.value : this.userMessageTextarea.value;
      this.projectsService.shareVolunteeringOffer(this.user.id, userMessage, this.offerId, contactsIds.join(','))
        .subscribe({
          next: (data) => {
            this.openSuccessModal();
          }
        });
    } else {
      this.noContactSelected = true;
    }
  }

  openSuccessModal() {
    const modalHeight = this.screenWidth > 767 ? 490 : this.screenHeight;
    const modalWidth = this.screenWidth > 767 ? 510 : this.screenWidth;
    this.modal.openFixedModal({ user: this.user }, VolunteeringShareSuccessModalComponent,
      window.innerWidth > 767 ? ModalConstants.DEFAULT_PANEL_CLASS : ModalConstants.DEFAULT_FULLSCREEN_PANEL_CLASS,
      modalWidth, modalHeight);
    this.dialogRef.close();
  }

  removeHoverStatus() {
    this.userContacts?.forEach((contact) => {
      contact.addButton = false;
    });
  }
}
