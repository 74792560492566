<app-custom-spinner *ngIf="isLoading"></app-custom-spinner>

<div class="volunteering-header">
  <div class="top-info">
    <div class="bg-image"></div>

    <div class="container-xl custom-container">
      <div class="top-wrapper">
        <h1>{{ "VOLUNTEERING_TAB.title_volunteeringtab" | translate }}</h1>
        <h2 *ngIf="!isMobile">{{ "VOLUNTEERING_TAB.subtitle" | translate }}</h2>
      </div>
    </div>
  </div>
</div>

<div class="body-section">
  <ng-container *ngIf="!isMobile">
    <div class="grey-card ns-mr-20">
      <div class="title">{{ 'VOLUNTEERING_TAB.propose_ngo_title' | translate }}</div>
      <div class="text"> {{ 'VOLUNTEERING_TAB.propose_ngo_text' | translate }} </div>
      <ns-button
        (callback$)="openProposeMissionModal()"
        [classes]="'ns-mt-20 ns-ml-auto ns-p-20x10'"
        [faIcon]="'fa fa-plus'"
        [iconClasses]="'ns-mr-10'"
        [text]="'INITIATIVE_PROPOSAL_MODAL.add_mission' | translate"
        [type]="Constants.BUTTON_TYPE_DEFAULT_GREEN_FILLED_N">
      </ns-button>

    </div>
    <div class="grey-card">
      <div class="title">{{ 'VOLUNTEERING_TAB.completed_mission_title' | translate }}</div>
      <div class="text"> {{ 'VOLUNTEERING_TAB.completed_mission_text' | translate:{company: companyName} }} </div>
      <ns-button
        (callback$)="onTypeformClick(currentLang)"
        [classes]="'ns-mt-20 ns-ml-auto ns-p-20x10'"
        [faIcon]="'fa fa-calendar-days'"
        [iconClasses]="'ns-mr-10'"
        [text]="'VOLUNTEERING_TAB.typeform_button_volunteeringtab' | translate"
        [type]="Constants.BUTTON_TYPE_DEFAULT_YELLOW_SOLID_M">
      </ns-button>
    </div>
  </ng-container>
  <ng-container *ngIf="isMobile">
    <div class="mobile-title">{{ 'VOLUNTEERING_TAB.subtitle' | translate }}</div>
    <div class="mobile-card">
      <div (click)="firstBlockCollapsed = !firstBlockCollapsed" class="title">
        {{ 'VOLUNTEERING_TAB.propose_ngo_title' | translate }}
        <i [@rotatedState]="firstBlockCollapsed" class="fa-solid fa-angle-down"></i>
      </div>
      <div [@collapse]="firstBlockCollapsed"
           class="content">{{ 'VOLUNTEERING_TAB.propose_ngo_text' | translate }}
        <ns-button
          (callback$)="openProposeMissionModal()"
          [classes]="'ns-mt-12 ns-ml-auto ns-height-28 ns-p-4'"
          [faIcon]="'fa fa-plus'"
          [iconClasses]="'ns-mr-10'"
          [noTextUnderline]="true"
          [text]="'INITIATIVE_PROPOSAL_MODAL.add_mission' | translate"
          [type]="Constants.BUTTON_TYPE_DEFAULT_GREEN_GHOST_N">
        </ns-button>
      </div>
    </div>
    <div class="mobile-card ns-mt-16">
      <div (click)="secondBlockCollapsed = !secondBlockCollapsed" class="title">
        {{ 'VOLUNTEERING_TAB.completed_mission_title' | translate }}
        <i [@rotatedState]="secondBlockCollapsed" class="fa-solid fa-angle-down"></i>
      </div>
      <div [@collapse]="secondBlockCollapsed"
           class="content">{{ 'VOLUNTEERING_TAB.completed_mission_text' | translate:{company: companyName} }}
        <ns-button
          (callback$)="onTypeformClick(currentLang)"
          [classes]="'ns-mt-12 ns-ml-auto ns-height-28 ns-p-4'"
          [faIcon]="'fa fa-calendar-days'"
          [iconClasses]="'ns-mr-10 text-decoration-none'"
          [noTextUnderline]="true"
          [text]="'VOLUNTEERING_TAB.typeform_button_volunteeringtab' | translate"
          [type]="Constants.BUTTON_TYPE_DEFAULT_GREEN_GHOST_N">
        </ns-button>
      </div>
    </div>
  </ng-container>
</div>
<div class="section-title">{{ 'VOLUNTEERING_TAB.missions_title' | translate }}</div>

<div *ngIf="projectVolunteeringOffers$ | async as projectVolunteeringOffers" class="volunteering-container">
  <div class="volunteering-search-block">
    <!-- FILTERS -->
    <div class="volunteering-filters-block-desktop">
      <span>{{'VOLUNTEERING_TAB.filter' | translate}}</span>
      <!-- Category Filter -->
      <mat-form-field [class.option-selected-btn]="categoryIdsToFilterBy.length > 0" appearance="outline"
                      class="ns-button-with-dropdown">
        <div class="label">
          {{'VOLUNTEERING_TAB.category_filter_title' | translate}}
          <span *ngIf="categoryIdsToFilterBy.length > 0" class="filters-count">
            {{categoryIdsToFilterBy.length}}
          </span>
        </div>
        <mat-select #categoryFilter
                    (closed)="handleFilterClose(FILTER_IDS.CATEGORY_ID, categorySelectControl, categoryFilter);"
                    (opened)="onSelectOpened(FILTER_IDS.CATEGORY_ID)"
                    (selectionChange)="onOptionChanges(FILTER_IDS.CATEGORY_ID, categoryFilter.value)"
                    [disableRipple]="true"
                    [formControl]="categorySelectControl" multiple
                    panelClass="mat-select-with-buttons">
          <mat-option *ngFor="let categoryInfo of filterCategoriesInfo"
                      [disabled]="categoryInfo.offersNumber === 0"
                      [value]="categoryInfo.tag.id">
            {{categoryInfo.tag.tag[currentLang]}}
          </mat-option>
          <hr>
          <div class="panel-buttons-container">
            <ns-button
              (callback$)="onRestoreIndividualFilter(categorySelectControl, FILTER_IDS.CATEGORY_ID);categoryFilter.close()"
              [text]="'VOLUNTEERING_TAB.restore_button' | translate"
              [type]="Constants.BUTTON_TYPE_PILL_DARK_GREY_TRANSPARENT_S">
            </ns-button>
            <ns-button
              (callback$)="onApplyIndividualFilterSelection(FILTER_IDS.CATEGORY_ID, categorySelectControl.value);categoryFilter.close()"
              [text]="('VOLUNTEERING_TAB.show_results_button' | translate) +' ('+ offersNumberWithFilter +')'"
              [type]="Constants.BUTTON_TYPE_PILL_LIGHT_GREEN_SOLID_S">
            </ns-button>
          </div>
        </mat-select>
      </mat-form-field>
      <!-- Language Filter -->
      <mat-form-field [class.option-selected-btn]="languageIdsToFilterBy.length > 0" appearance="outline"
                      class="ns-button-with-dropdown">
        <div class="label">
          {{'VOLUNTEERING_TAB.language_filter_title' | translate}}
          <span *ngIf="languageIdsToFilterBy.length > 0" class="filters-count">
            {{languageIdsToFilterBy.length}}
          </span>
        </div>
        <mat-label>
        </mat-label>
        <mat-select #languageFilter
                    (closed)="handleFilterClose(FILTER_IDS.LANGUAGES_ID, languageSelectControl, languageFilter);"
                    (opened)="onSelectOpened(FILTER_IDS.LANGUAGES_ID)"
                    (selectionChange)="onOptionChanges(FILTER_IDS.LANGUAGES_ID, languageFilter.value)"
                    [disableRipple]="true"
                    [formControl]="languageSelectControl" multiple
                    panelClass="mat-select-with-buttons">
          <mat-option *ngFor="let langInfo of filterLanguagesInfo"
                      [disabled]="langInfo.offersNumber === 0"
                      [value]="langInfo.tag.id">
            {{langInfo.tag.tag[currentLang]}}
          </mat-option>
          <hr>
          <div class="panel-buttons-container">
            <ns-button
              (callback$)="onRestoreIndividualFilter(languageSelectControl, FILTER_IDS.LANGUAGES_ID);languageFilter.close()"
              [text]="'VOLUNTEERING_TAB.restore_button' | translate"
              [type]="Constants.BUTTON_TYPE_PILL_DARK_GREY_TRANSPARENT_S">
            </ns-button>
            <ns-button
              (callback$)="onApplyIndividualFilterSelection(FILTER_IDS.LANGUAGES_ID, languageSelectControl.value);languageFilter.close()"
              [text]="('VOLUNTEERING_TAB.show_results_button' | translate) +' ('+ offersNumberWithFilter +')'"
              [type]="Constants.BUTTON_TYPE_PILL_LIGHT_GREEN_SOLID_S">
            </ns-button>
          </div>
        </mat-select>
      </mat-form-field>
      <!-- Format Filter -->
      <mat-form-field [class.option-selected-btn]="formatIdsToFilterBy.length > 0" appearance="outline"
                      class="ns-button-with-dropdown">
        <div class="label">
          {{'VOLUNTEERING_TAB.format_filter_title' | translate}}
          <span *ngIf="formatIdsToFilterBy.length > 0" class="filters-count">
            {{formatIdsToFilterBy.length}}
          </span>
        </div>
        <mat-select #formatFilter
                    (closed)="handleFilterClose(FILTER_IDS.FORMATS_ID, formatSelectControl, formatFilter);"
                    (opened)="onSelectOpened(FILTER_IDS.FORMATS_ID)"
                    (selectionChange)="onOptionChanges(FILTER_IDS.FORMATS_ID, formatFilter.value)"
                    [disableRipple]="true"
                    [formControl]="formatSelectControl" multiple
                    panelClass="mat-select-with-buttons">
          <mat-option *ngFor="let formatInfo of filterFormatsInfo"
                      [disabled]="formatInfo.offersNumber === 0"
                      [value]="formatInfo.tag.id">
            {{formatInfo.tag.tag[currentLang]}}
          </mat-option>
          <hr>
          <div class="panel-buttons-container">
            <ns-button
              (callback$)="onRestoreIndividualFilter(formatSelectControl, FILTER_IDS.FORMATS_ID);formatFilter.close()"
              [text]="'VOLUNTEERING_TAB.restore_button' | translate"
              [type]="Constants.BUTTON_TYPE_PILL_DARK_GREY_TRANSPARENT_S">
            </ns-button>
            <ns-button
              (callback$)="onApplyIndividualFilterSelection(FILTER_IDS.FORMATS_ID, formatSelectControl.value);formatFilter.close()"
              [text]="('VOLUNTEERING_TAB.show_results_button' | translate) +' ('+ offersNumberWithFilter +')'"
              [type]="Constants.BUTTON_TYPE_PILL_LIGHT_GREEN_SOLID_S">
            </ns-button>
          </div>
        </mat-select>
      </mat-form-field>
      <!-- Location Filter -->
      <mat-form-field [class.option-selected-btn]="locationIdsToFilterBy.length > 0" appearance="outline"
                      class="ns-button-with-dropdown">
        <div class="label">
          {{'VOLUNTEERING_TAB.location_filter_title' | translate}}
          <span *ngIf="locationIdsToFilterBy.length > 0" class="filters-count">
            {{locationIdsToFilterBy.length}}
          </span>
        </div>
        <mat-select #locationFilter
                    (closed)="handleFilterClose(FILTER_IDS.LOCATION_ID, locationSelectControl, locationFilter);"
                    (opened)="onSelectOpened(FILTER_IDS.LOCATION_ID)"
                    (selectionChange)="onOptionChanges(FILTER_IDS.LOCATION_ID, locationFilter.value)"
                    [disableRipple]="true"
                    [formControl]="locationSelectControl" multiple
                    panelClass="mat-select-with-buttons">
          <mat-option *ngFor="let locationInfo of filterLocationsInfo"
                      [disabled]="locationInfo.offersNumber === 0"
                      [value]="locationInfo.tag.id">
            {{locationInfo.tag.tag[currentLang]}}
          </mat-option>
          <hr>
          <div class="panel-buttons-container">
            <ns-button
              (callback$)="onRestoreIndividualFilter(locationSelectControl, FILTER_IDS.LOCATION_ID);locationFilter.close()"
              [text]="'VOLUNTEERING_TAB.restore_button' | translate"
              [type]="Constants.BUTTON_TYPE_PILL_DARK_GREY_TRANSPARENT_S">
            </ns-button>
            <ns-button
              (callback$)="onApplyIndividualFilterSelection(FILTER_IDS.LOCATION_ID, locationSelectControl.value);locationFilter.close()"
              [text]="('VOLUNTEERING_TAB.show_results_button' | translate) +' ('+ offersNumberWithFilter +')'"
              [type]="Constants.BUTTON_TYPE_PILL_LIGHT_GREEN_SOLID_S">
            </ns-button>
          </div>
        </mat-select>
      </mat-form-field>
      <!-- Duration Filter -->
      <mat-form-field [class.option-selected-btn]="durationIdsToFilterBy.length > 0" appearance="outline"
                      class="ns-button-with-dropdown">
        <div class="label">
          {{'VOLUNTEERING_TAB.duration_filter_title' | translate}}
          <span *ngIf="durationIdsToFilterBy.length > 0" class="filters-count">
            {{durationIdsToFilterBy.length}}
          </span>
        </div>
        <mat-select #durationFilter
                    (closed)="handleFilterClose(FILTER_IDS.DURATION_ID, durationSelectControl, durationFilter);"
                    (opened)="onSelectOpened(FILTER_IDS.DURATION_ID)"
                    (selectionChange)="onOptionChanges(FILTER_IDS.DURATION_ID, durationFilter.value)"
                    [disableRipple]="true"
                    [formControl]="durationSelectControl" multiple
                    panelClass="mat-select-with-buttons">
          <mat-option *ngFor="let durationInfo of filterDurationInfo"
                      [disabled]="durationInfo.offersNumber === 0"
                      [value]="durationInfo.tag.id">
            {{durationInfo.tag.tag[currentLang]}}
          </mat-option>
          <hr>
          <div class="panel-buttons-container">
            <ns-button
              (callback$)="onRestoreIndividualFilter(durationSelectControl, FILTER_IDS.DURATION_ID);durationFilter.close()"
              [text]="'VOLUNTEERING_TAB.restore_button' | translate"
              [type]="Constants.BUTTON_TYPE_PILL_DARK_GREY_TRANSPARENT_S">
            </ns-button>
            <ns-button
              (callback$)="onApplyIndividualFilterSelection(FILTER_IDS.DURATION_ID, durationSelectControl.value);durationFilter.close()"
              [text]="('VOLUNTEERING_TAB.show_results_button' | translate) +' ('+ offersNumberWithFilter +')'"
              [type]="Constants.BUTTON_TYPE_PILL_LIGHT_GREEN_SOLID_S">
            </ns-button>
          </div>
        </mat-select>
      </mat-form-field>
      <!-- Dedication Filter -->
      <mat-form-field [class.option-selected-btn]="dedicationIdsToFilterBy.length > 0" appearance="outline"
                      class="ns-button-with-dropdown">
        <div class="label">
          {{'VOLUNTEERING_TAB.dedication_filter_title' | translate}}
          <span *ngIf="dedicationIdsToFilterBy.length > 0" class="filters-count">
            {{dedicationIdsToFilterBy.length}}
          </span>
        </div>
        <mat-select #dedicationFilter
                    (closed)="handleFilterClose(FILTER_IDS.DEDICATION_ID, dedicationSelectControl, dedicationFilter);"
                    (opened)="onSelectOpened(FILTER_IDS.DEDICATION_ID)"
                    (selectionChange)="onOptionChanges(FILTER_IDS.DEDICATION_ID, dedicationFilter.value)"
                    [disableRipple]="true"
                    [formControl]="dedicationSelectControl" multiple
                    panelClass="mat-select-with-buttons">
          <mat-option *ngFor="let dedicationInfo of filterDedicationInfo"
                      [disabled]="dedicationInfo.offersNumber === 0"
                      [value]="dedicationInfo.tag.id">
            {{dedicationInfo.tag.tag[currentLang]}}
          </mat-option>
          <hr>
          <div class="panel-buttons-container">
            <ns-button
              (callback$)="onRestoreIndividualFilter(dedicationSelectControl, FILTER_IDS.DEDICATION_ID);dedicationFilter.close()"
              [text]="'VOLUNTEERING_TAB.restore_button' | translate"
              [type]="Constants.BUTTON_TYPE_PILL_DARK_GREY_TRANSPARENT_S">
            </ns-button>
            <ns-button
              (callback$)="onApplyIndividualFilterSelection(FILTER_IDS.DEDICATION_ID, dedicationSelectControl.value);dedicationFilter.close()"
              [text]="('VOLUNTEERING_TAB.show_results_button' | translate) +' ('+ offersNumberWithFilter +')'"
              [type]="Constants.BUTTON_TYPE_PILL_LIGHT_GREEN_SOLID_S">
            </ns-button>
          </div>
        </mat-select>
      </mat-form-field>
      <ns-button
        (callback$)="onRestoreAllFilters()"
        [text]="'VOLUNTEERING_TAB.restore_button' | translate"
        [type]="Constants.BUTTON_TYPE_PILL_DARK_GREY_TRANSPARENT_M">
      </ns-button>
    </div>
    <div class="volunteering-filters-block-mobile">
      <ns-button
        (callback$)="openMobileFilterMenu()"
        [iconRightSide]="true"
        [icon]="'assets/icons/filter_white.svg'"
        [text]="'VOLUNTEERING_TAB.filter_without_colon' | translate"
        [type]="Constants.BUTTON_TYPE_DEFAULT_GREEN_SOLID_S">
      </ns-button>
    </div>
    <!-- END FILTERS -->
    <hr>
  </div>

  <div class="ns-ml-auto ns-mr-auto d-flex justify-content-center">
    <div class="reverse-volunteering-box">
      {{ 'VOLUNTEERING_TAB.reverse_vol_text_1' | translate }}<a
      (click)="onProposeOfferButtonClick()">{{ 'VOLUNTEERING_TAB.reverse_vol_link_text' | translate }}</a>
      {{'VOLUNTEERING_TAB.reverse_vol_text_2' | translate }}
    </div>
  </div>
  <div class="offers-container">
    <ng-container *ngFor="let offer of projectVolunteeringOffers | slice : 0:paginationLimit">
      <app-volunteering-card
        (isOfferModalOpened)="onOfferModalOpenStatusChanges($event)"
        [classes]="'ns-mb-20'"
        [currentLang]="currentLang"
        [offer]="offer"
        [user]="user">
      </app-volunteering-card>
    </ng-container>
  </div>

  <div *ngIf="paginationLimit < projectVolunteeringOffers.length" class="button-wrapper">
    <ns-button (callback$)="showMore()"
               [classes]="(screenWidth < 700 ? 'ns-height-48':'ns-height-63') + ' ns-b-radius-10 ns-font-26 ns-line-height-26'"
               [styles]="screenWidth < 700 ? 'width: calc(100vw - 52px)':'width:300px'"
               [text]="'BRAND_PAGE.see_more_brand_page' | translate"
               [type]="Constants.BUTTON_TYPE_DEFAULT_YELLOW_SOLID_M">
    </ns-button>
  </div>
</div>
