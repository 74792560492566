<div class="top-info">
  <div class="bg-image"></div>

  <h1>404</h1>
  <h2>
    {{ "NOT_FOUND.line1_notfound" | translate }}<br>
    <span>{{ "NOT_FOUND.line2_notfound" | translate }}</span>
  </h2>
  <a [routerLink]="['../', RouterUrls.BrowseProjects]" class="yellow sans-serif">{{ "NOT_FOUND.cta_notfound" | translate }}</a>
</div>

<app-subscribe-banner></app-subscribe-banner>
