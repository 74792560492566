import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TagsService } from '../services/tags.service';

import { RouterUrls } from '../shared/router-urls';
import { Constants } from '../app.constants';

@Component({
  selector: 'app-impact-organization',
  templateUrl: './impact-organization.component.html',
  styleUrls: ['./impact-organization.component.scss']
})
export class ImpactOrganizationComponent implements OnInit {

  clickEventSubscription: Subscription;
  RouterUrls = RouterUrls;

  constructor(
    private meta: Meta,
    private tagsService: TagsService,
    private titleService: Title,
    private translate: TranslateService) {
      this.clickEventSubscription = this.tagsService.getClickEventImpactOrganizations().subscribe(() => {
        this.updateTags();
      });
    }

  ngOnInit(): void {
    this.updateTags();
  }

  updateTags() {
    this.titleService.setTitle(this.translate.instant('FOR_IO.meta_title_forio'));
    this.meta.updateTag({name: 'description', content: this.translate.instant('FOR_IO.meta_description_forio')});
    this.meta.updateTag({property: 'og:title', content: this.translate.instant('FOR_IO.meta_title_forio')});
    this.meta.updateTag({property: 'og:description', content: this.translate.instant('FOR_IO.meta_description_forio')});
    this.meta.updateTag({property: 'og:type', content: 'URL'});
    this.meta.updateTag({property: 'og:url', content: window.location.href});
    this.meta.updateTag({property: 'og:image', content: Constants.APP_URL + 'assets/backgrounds/bg-impact-organization.webp'});
  }
}
