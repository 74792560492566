import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.authService.token;
    const authReq = request.clone(
      {headers: request.headers.append('Authorization', 'Token ' + token)}
    );

    const httpRequest = request.clone({
      headers: new HttpHeaders({
        'Cache-Control': 'no-store',
      })
    });


    return next.handle(token ? authReq : request);
  }
}
