import { Component, OnInit } from '@angular/core';

import { LanguageService } from '../services/language.service';
import { AuthService } from '../services/auth.service';
import { BrandsService } from '../services/brands.service';
import { NavigationEnd, Router } from '@angular/router';
import { RouterUrls } from '../shared/router-urls';
import { User } from '../shared/user.model';
import { UserService } from '../services/user.service';
import { Subject, timer } from 'rxjs';
import { Constants } from '../app.constants';


@Component({
  selector: 'app-landing-page',
  templateUrl: './core-page.component.html',
  styleUrls: ['./core-page.component.scss']
})
export class CorePageComponent implements OnInit {

  isUserLogged;
  isBrandPage$;
  isDemoPage$;
  isPublicPage: boolean;
  isHomePage: boolean;
  showSimpleFooter = false;
  user: User;
  userReady = new Subject<boolean>();

  constructor(private languageService: LanguageService,
              private authService: AuthService,
              private brandsService: BrandsService,
              private userService: UserService,
              private router: Router
  ) {
    router.events.subscribe((event) => (event instanceof NavigationEnd) && this.handleRouteChange(event));
  }

  ngOnInit(): void {
    this.userService.getUser().subscribe({
      next: (user: User) => {
        this.isUserLogged = !!user;
        this.user = user || null;
        this.showSimpleFooter = this.user?.company_slug === Constants.PRIVACY_MODE_SLUG || this.router.url.includes(RouterUrls.SamlAuth);
      }
    });
    this.authService.onLogoutEvent$.subscribe({
      next: () => {
        timer(2000).subscribe(() => {
          this.showSimpleFooter = this.user?.company_slug === Constants.PRIVACY_MODE_SLUG || this.router.url.includes(RouterUrls.SamlAuth);
        });
      }
    });
    this.isBrandPage$ = this.brandsService.isBrandPage;
    this.isDemoPage$ = this.brandsService.isDemoPage;
    this.languageService.setLanguageFromUrl();
  }

  private handleRouteChange(event): void {
    this.isHomePage = event.urlAfterRedirects === this.languageService.getCurrentLangWithSlash();
    this.isPublicPage = event.urlAfterRedirects.startsWith(this.languageService.getCurrentLangWithSlash() + '/' + RouterUrls.Public);
  }
}
