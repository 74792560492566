import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { RouterUrls } from '../../../shared/router-urls';
import { LanguageService } from '../../../services/language.service';
import { Constants } from '../../../app.constants';
import { AuthService } from '../../../services/auth.service';
import { Subscription } from 'rxjs';
import { User } from '../../../shared/user.model';
import { UserService } from '../../../services/user.service';
import { HeaderService } from '../../../services/header.service';
import { ModalQueueService } from '../../../shared/services/modal/modal-queue.service';

@Component({
  templateUrl: './country-dialog.component.html',
  styleUrls: ['./country-dialog.component.scss']
})
export class CountryDialogComponent implements OnInit, OnDestroy {

  countries: any = [
    'Arctic',
    'Antarctica',
    'Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Antigua & Deps',
    'Argentina',
    'Armenia',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bhutan',
    'Bolivia',
    'Bosnia Herzegovina',
    'Botswana',
    'Brazil',
    'Brunei',
    'Bulgaria',
    'Burkina',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cape Verde',
    'Central African Republic',
    'Chad',
    'Chile',
    'China',
    'Colombia',
    'Comoros',
    'Congo',
    'Democratic Republic of Congo',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'East Timor',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Ethiopia',
    'Fiji',
    'Finland',
    'France',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Greece',
    'Grenada',
    'Guatemala',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Israel',
    'Italy',
    'Ivory Coast',
    'Jamaica',
    'Japan',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Korea North',
    'Korea South',
    'Kosovo',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macedonia',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Micronesia',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Morocco',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Qatar',
    'Romania',
    'Russian Federation',
    'Rwanda',
    'St Kitts & Nevis',
    'St Lucia',
    'Saint Vincent & the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome & Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Togo',
    'Tonga',
    'Trinidad & Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Vatican City',
    'Venezuela',
    'Vietnam',
    'Yemen',
    'Zambia',
    'Zimbabwe'
  ];
  selectedCountry = '';
  onboarding: boolean;
  userNameSubscription: Subscription;
  currentUser: User;
  RouterUrls = RouterUrls;

  constructor(
    private userService: UserService,
    private headerService: HeaderService,
    private languageService: LanguageService,
    private router: Router,
    private authService: AuthService,
    private modalQueue: ModalQueueService,
    @Optional() private dialogRef: MatDialogRef<CountryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.onboarding = data.onboarding;
      this.selectedCountry = this.onboarding ? '' : data.country_preference;
    }

  ngOnInit() {
    this.userNameSubscription = this.authService.currentUser.subscribe(
      user => {
        this.currentUser = user;
      }
    );
  }

  closeDialog(update: boolean) {
    if (update) {
      this.dialogRef.close({ event: 'close', data: this.selectedCountry });
    } else {
      this.dialogRef.close({ event: 'close', data: '' });
    }
  }

  onUpdate() {
    this.userService.saveCountry(this.selectedCountry).subscribe();
    this.closeDialog(true);

    if (this.currentUser?.custom_redirect && this.currentUser.custom_redirect === Constants.LOGIN_REDIRECTION_COMPANY_KEY) {
      this.currentUser.company_slug ?
        this.router.navigate([this.languageService.getCurrentLangWithSlash(), RouterUrls.Brands, this.currentUser.company_slug]) :
        this.router.navigate([this.languageService.getCurrentLangWithSlash(), RouterUrls.Home]);
    } else {
      if (this.onboarding) {
        this.modalQueue.addModalToQueue({
          id: this.modalQueue.ids.WELCOME_ONBOARDING_ID,
          priority: 100,
          layer: 7,
          openModalFn: () => {
            this.headerService.$isWelcomeOnboardingDone.next(false);
          }
        });
        this.router.navigate([this.languageService.getCurrentLangWithSlash(), RouterUrls.Home]);
      }
    }
  }

  ngOnDestroy() {
    this.userNameSubscription.unsubscribe();
  }
}
