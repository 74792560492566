<footer class="ns-footer">
<div class="container">
  <div class="column-1">
    <div class="d-flex">
      <img alt="NooS Logo" class="logo" src="/assets/icons/logo-white-2.svg">
    </div>
    <div class="noosletter-text">{{'FOOTER.get_latest_updates' | translate }}</div>
    <div class="d-flex">
      <form [formGroup]="subscribeForm" class="subscribe-form" (ngSubmit)="subscribeForMailing()">
        <input type="email" placeholder="Email" formControlName="email">
        <button class="subscribe" type="submit" [disabled]="subscribeForm.invalid">{{ "SUBSCRIBE_BANNER.button_subscribebanner" | translate }}</button>
      </form>
    </div>
    <div>NooS Global SAS Ⓒ {{ year }}</div>
  </div>
  <div class="column-2">
    <div (click)="navigateTo(FooterConstants.HOME_CODE)">{{'FOOTER.home_footer' | translate }}</div>
    <div (click)="navigateTo(FooterConstants.RSE_TOOL_CODE)" >{{'FOOTER.csr_impact_tool' | translate }}</div>
    <div (click)="navigateTo(FooterConstants.EXPERTISE_IMPACT_CODE)" >{{'FOOTER.expertise_impact' | translate }}</div>
    <div (click)="navigateTo(FooterConstants.CSR_WITH_OUR_CLIENTS_CODE)" >{{'FOOTER.csr_with_our_clients' | translate }}</div>
    <div (click)="navigateTo(FooterConstants.GET_A_DEMO_CODE)">{{'FOOTER.get_demo' | translate }}</div>
    <div (click)="navigateTo(FooterConstants.BLOG_CODE)">{{'FOOTER.blog' | translate }}</div>
  </div>
  <div class="column-3">
    <div (click)="navigateTo(FooterConstants.ABOUT_NOOS_CODE)">{{'FOOTER.about_noos' | translate }}</div>
    <div (click)="navigateTo(FooterConstants.WHO_WE_ARE_CODE)">{{'FOOTER.who_we_are' | translate }}</div>
    <div (click)="navigateTo(FooterConstants.CARBON_COMPENSATION_CODE)" >{{'FOOTER.carbon_compensation' | translate }}</div>
    <div (click)="navigateTo(FooterConstants.OUR_ENGAGED_COMPANIES_CODE)" >{{'FOOTER.our_engaged_companies' | translate }}</div>
    <div (click)="navigateTo(FooterConstants.TERMS_AND_CONDITIONS_CODE)">{{'FOOTER.terms_and_conditions' | translate }}</div>
    <div (click)="navigateTo(FooterConstants.PRIVACY_POLICY_CODE)">{{'FOOTER.privacy_policy_footer' | translate }}</div>
  </div>
  <div class="column-4">
    <div class="hidden-on-desktop">NooS Global SAS Ⓒ {{ year }}</div>
    <div>
      <img alt="Certified B Corporation" height="94" loading="lazy" src="/assets/icons/bcorp-logo.svg" width="62">
    </div>
    <div class="linkedin-button">
      <a href="https://www.linkedin.com/company/noos-global/" target="_blank">
        <img alt="linkedin" loading="lazy" src="/assets/icons/social-linkedin-white-2.svg">
      </a>
    </div>
  </div>
</div>
</footer>
