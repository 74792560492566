<footer class="ns-simple-footer">
  <div class="container">
    <div class="column-1">
      <img alt="NooS Logo" class="logo" src="/assets/icons/logo-white-2.svg">
    </div>
    <div class="column-2">
      <div
        (click)="navigateTo(FooterConstants.TERMS_AND_CONDITIONS_CODE)">{{ 'FOOTER.terms_and_conditions' | translate }}
      </div>
      <div (click)="navigateTo(FooterConstants.PRIVACY_POLICY_CODE)">{{ 'FOOTER.privacy_policy_footer' | translate }}
      </div>
      <div class="hidden-on-desktop">NooS Global SAS Ⓒ {{ year }}</div>
    </div>
    <div class="column-3">
      <div>
        <img alt="Certified B Corporation" height="94" loading="lazy" src="/assets/icons/bcorp-logo.svg" width="62">
      </div>
    </div>
  </div>
</footer>
