import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { CustomValidators } from '../custom-validators';
import { SubscribedDialogComponent } from '../subscribed-dialog/subscribed-dialog.component';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-subscribe-banner',
  templateUrl: './subscribe-banner.component.html',
  styleUrls: ['./subscribe-banner.component.scss']
})
export class SubscribeBannerComponent implements OnInit {

  subscribeForm: UntypedFormGroup;

  constructor(private userService: UserService,
              private translate: TranslateService,
              private dialog: MatDialog,
              private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.subscribeForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(CustomValidators.emailValidationRegex)]]
    });
  }

  openSubscribedNewsletterDialog(success: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '907px';
    dialogConfig.disableClose = true;
    dialogConfig.maxWidth = '907px';
    dialogConfig.closeOnNavigation = true;
    dialogConfig.panelClass = 'subscribed-dialog';
    dialogConfig.backdropClass = 'backdropBackground';
    dialogConfig.data = {
        success
      };
    const dialogRef = this.dialog.open(SubscribedDialogComponent, dialogConfig);
  }

  subscribeForMailing() {
    this.userService.subscribeForMailing(this.subscribeForm.value.email, this.translate.currentLang).subscribe(
      {
        next:() => {
          this.subscribeForm.reset();
          this.openSubscribedNewsletterDialog(true);
        },
        error:() => {
          this.openSubscribedNewsletterDialog(false);
        }
      }
    );
  }

  get email() {
    return this.subscribeForm.get('email');
  }
}
