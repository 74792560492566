import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../../services/api.service';
import {
  PushEditUserPlayers,
  PushPreferences,
  PushPreferencesChangeResponse
} from '../models/push-notifications/push-notifications.model';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  readonly ADD_PLAYER_CODE = 'add';
  readonly DELETE_PLAYER_CODE = 'remove';

  constructor(private http: HttpClient,
              private apiService: ApiService) { }

  getPreferences(userId: number): Observable<PushPreferences> {
    return this.apiService.getCurrentPushPreferences({id: userId});
  }

  changePreferences(userId: number, allowedSoftAsk: boolean, allowedHardAsk: boolean): Observable<PushPreferencesChangeResponse> {
    return this.apiService.postChangePushPreferences({
      id: userId,
      status: allowedSoftAsk,
      native_status: allowedHardAsk
    });
  }

  editUserPlayers(userId: number, playerId: string, deletePlayer = false): Observable<PushEditUserPlayers> {
    return this.apiService.postPlayerId({
      id: userId,
      player_id: playerId,
      action: deletePlayer ? this.DELETE_PLAYER_CODE : this.ADD_PLAYER_CODE
    });
  }
}
