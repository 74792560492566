import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import { HeaderService } from '../../../services/header.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LanguageService } from '../../../services/language.service';
import { Constants } from '../../../app.constants';
import { ModalQueueService } from '../../services/modal/modal-queue.service';

@Component({
  selector: 'app-support-onboarding',
  templateUrl: './support-onboarding.component.html',
  styleUrls: ['./support-onboarding.component.scss']
})
export class SupportOnboardingComponent extends BaseComponent implements OnInit, OnDestroy {
  Constants = Constants;

  @Input() companyName: any;

  currentStep = 1;

  constructor(private headerService: HeaderService,
              protected breakpointObserver: BreakpointObserver,
              protected languageService: LanguageService,
              private renderer: Renderer2,
              private modalQueue: ModalQueueService) {
    super(languageService, breakpointObserver);
  }

  ngOnInit(): void {
    // Hides main page scrollbar.
    this.renderer.setStyle(document.body, 'overflow', 'hidden');

    this.headerService.$isContentMaskVisible.next(true);
  }

  onNextStepClick() {
    this.currentStep++;

    if (this.currentStep === 3) {
      // Close the modal
      this.headerService.$isContentMaskVisible.next(false);
      this.headerService.$isSupportOnboardingDone.next(true);
      this.modalQueue.closeModal(this.modalQueue.ids.SUPPORT_OB_ID);
    }
  }

  ngOnDestroy() {
    this.modalQueue.closeModal(this.modalQueue.ids.SUPPORT_OB_ID);
    super.ngOnDestroy();
    this.renderer.removeStyle(document.body, 'overflow');
  }
}
