export const CampaignConstants = Object.freeze({

  REWARD_KEY: 'reward',

  ACTIVITY_CLOSED_BY_COMPLETION: 'completed',
  ACTIVITY_CLOSED_BY_INTERRUPTION: 'interrupted',
  ACTIVITY_CLOSED_BY_REVISIT: 'revisit',
  ACTIVITY_CLOSED_BY_ERROR: 'error',

  LC_TYPE_READING_CONTENT: 'reading',
  LC_TYPE_QUIZ: 'quiz',
  LC_TYPE_VIDEO: 'video',

  // Learning content modal phases
  LC_PHASE_READING_CONTENT: 0,
  LC_PHASE_TITLE: 1,
  LC_PHASE_QUESTIONS: 2,
  LC_PHASE_VIDEO: 3,
  LC_PHASE_RESULT: 4,
  LC_PHASE_STREAK: 5,
  LC_PHASE_LEVEL: 6,
  LC_PHASE_BADGE: 7,

  // Learning content filter status
  LC_FILTER_STATUS_ALL_JSON: {id: -1, name: {en: 'All', es: "Todo", fr: "Tout"}},
  LC_FILTER_STATUS_ALL: 'all',
  LC_FILTER_STATUS_COMPLETED: 'completed',
  LC_FILTER_STATUS_INCOMPLETED: 'incompleted',

  // Onboarding modal steps
  OB_DESCRIPTION: 'onboarding_description',
  OB_INTRODUCTION: 'onboarding_intro',
  OB_TOPIC_1: 'onboarding_topic_1',
  OB_TOPIC_2: 'onboarding_topic_2',
  OB_REWARD: 'onboarding_reward',

  // Feed post types
  FEED_POST_TYPE_CHALLENGE: 'challenge',
  FEED_POST_TYPE_QUIZ: 'quiz',
  FEED_POST_TYPE_READING_CONTENT: 'reading',

  FEED_ACTION_LIKE: 'like',
  FEED_ACTION_UNLIKE: 'unlike',

  // Navigation contexts | Identifies the origin of the opened modal or page.
  // When users navigate back, they are redirected to their previous location.
  NAV_CONTEXT_CAMPAIGN: 'campaign',
  NAV_CONTEXT_LEARNING_NUGGETS_LIST: 'learning_nuggets_list',
  NAV_CONTEXT_FEED_POST: 'feed_post',
  NAV_CONTEXT_LAST_WEEK_CHALLENGES_CAMPAIGN: 'last_week_challenges_campaign',
  NAV_CONTEXT_LAST_WEEK_CHALLENGES_IMPACT: 'last_week_challenges_impact',
  NAV_CONTEXT_USER_PUBLIC_MODAL_CAMPAIGN: 'user_public_modal_campaign',
  NAV_CONTEXT_CHALLENGES_LIST_IMPACT: 'challenges_list_impact',

  QPARAM_NAV_CONTEXT: 'navigationContext',
  QPARAM_OPEN_FEED_TAB: 'openFeedTab',
  QPARAM_ID: 'id',
  QPARAM_ACTION: 'action',
  QPARAM_LC_TYPE: 'learningContentType',
  QPARAM_OPEN_USER_MODAL: 'openUserPublicModal',
  QPARAM_OPEN_CHALLENGES_LIST: 'openChallengesList',
  QPARAM_OPEN_LC_MODAL: 'openLearningContentModal',
});
