<app-custom-spinner *ngIf="isLoading"></app-custom-spinner>
<ng-container *ngIf="!isLoading">
  <div class="top-container">
    <div (click)="closeDialog()" class="close-button"></div>
    <div (click)="previousStep()" *ngIf="currentStep === 2" class="back-button">
      <div></div>
      <div>{{ 'VOLUNTEERING_TAB.go_back' | translate }}</div>
    </div>
  </div>
  <div #mainContainer [class.form-height]="currentStep > 0" class="main-container">
    <ng-container *ngIf="currentStep === 0">
      <div class="title">{{title}}</div>
      <div [innerHTML]="explanation1" [ngClass]="isMobile ? 'ns-mt-16 ns-font-16 ns-line-height-24':'ns-mt-auto'"
           class="text ns-mb-16"></div>
      <div [innerHTML]="explanation2" class="text ns-mb-auto"></div>
      <div class="small-text ns-mb-16">{{ 'INITIATIVE_PROPOSAL_MODAL.learn_more_text'| translate }}</div>
      <div class="buttons-container">
        <ns-button
          (click)="onLearnMoreButtonClick()"
          [classes]="'ns-mr-48 ns-p-20x10'"
          [styles]=" !isMobile ? 'width: 158px':'width: 100%; margin-bottom: 16px;'"
          [text]="'INITIATIVE_PROPOSAL_MODAL.learn_more'| translate"
          [type]="isMobile ? Constants.BUTTON_TYPE_DEFAULT_DARK_BLUE_STROKE_M: Constants.BUTTON_TYPE_DEFAULT_GREEN_STROKE_M"
        ></ns-button>
        <ns-button
          (click)="OnProposeButtonClick()"
          [classes]="'ns-p-20x10'"
          [styles]=" !isMobile ? 'width: 158px':'width: 100%;'"
          [text]=" (initiativeType === Constants.INITIATIVE_TYPE_NGO_PROJECT ?
           'INITIATIVE_PROPOSAL_MODAL.suggest_project':'INITIATIVE_PROPOSAL_MODAL.suggest_mission') | translate"
          [type]="Constants.BUTTON_TYPE_DEFAULT_YELLOW_SOLID_M"
        ></ns-button>
      </div>
    </ng-container>
    <ng-container *ngIf="currentStep === 1">
      <div class="title ns-mb-16">{{ title }}</div>
      <div [innerHTML]="explanation3" class="text"></div>
      <form [formGroup]="initiativeForm">
        <div class="form-grid ns-mt-48">
          <div [class.flex-column]="isMobile" class="d-flex">
            <label class="ns-mr-10">
              <span>{{ 'INITIATIVE_PROPOSAL_MODAL.organization_name' | translate }}</span>
              <span> *</span>
              <input formControlName="ngoName" placeholder="Doctors Without Borders" type="text">
            </label>
            <label [class.ns-mt-16]="isMobile">
              <span>{{ 'INITIATIVE_PROPOSAL_MODAL.website' | translate }}</span>
              <input formControlName="ngoWebsite" placeholder="www.example.com" type="text">
            </label>
          </div>
          <div class="subtitle">{{ 'INITIATIVE_PROPOSAL_MODAL.contact_info_text'| translate }}</div>
          <label class="ns-mt-16">
            <span>{{ 'INITIATIVE_PROPOSAL_MODAL.name' | translate }}</span>
            <input formControlName="contactName" placeholder="Jane Doe" type="text">
          </label>
          <label class="ns-mt-16">
            <span>{{ 'INITIATIVE_PROPOSAL_MODAL.email' | translate }}</span>
            <span> *</span>
            <input formControlName="contactEmail" placeholder="email@example.com" type="email">
          </label>
          <label class="ns-mt-16">
            <span>{{ 'INITIATIVE_PROPOSAL_MODAL.phone' | translate }}</span>
            <input formControlName="contactPhone" placeholder="(+33) 0 00 00 00 00" type="text">
          </label>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="currentStep === 2">
      <div class="title ns-mb-16">{{ title}}</div>
      <div
        [innerHTML]="explanation3"
        class="text"></div>
      <form [class.w-100]="isMobile" [formGroup]="initiativeWhyForm">
        <div class="form-grid ns-mt-48">
          <label [ngClass]="!isMobile ? 'ns-ml-30 ns-mr-30': ''">
            <span>{{ "INITIATIVE_PROPOSAL_MODAL.why_text" | translate }}</span>
            <span> *</span>
            <textarea [placeholder]="'INITIATIVE_PROPOSAL_MODAL.why_text_placeholder' | translate"
                      formControlName="contactWhy"></textarea>
          </label>
        </div>
      </form>
    </ng-container>
    <div [class.ns-pb-24]="currentStep === 1 && hasMainContainerScroll">
      <ns-button
        (callback$)="nextStep()"
        *ngIf="currentStep > 0"
        [classes]="'ns-mt-44'"
        [disabled]="currentStep === 1 ? initiativeForm.invalid: initiativeWhyForm.invalid"
        [iconAlignedToBorder]="screenWidth > 767"
        [iconClasses]="'icon-12x19 ns-mr-16 ns-ml-10'"
        [iconRightSide]="true"
        [icon]="(currentStep === 1 ? initiativeForm.invalid: initiativeWhyForm.invalid) ?
            'assets/icons/arrow-right-disabled-grey.svg':
            'assets/icons/arrow-right-very-dark-grey.svg'"
        [styles]="!isMobile ? 'width: 306px':'width: calc(100vw - 45px);'"
        [text]="( currentStep === 1 ? 'INITIATIVE_PROPOSAL_MODAL.next':'INITIATIVE_PROPOSAL_MODAL.send_application') | translate"
        [type]="Constants.BUTTON_TYPE_DEFAULT_YELLOW_FILLED_B">
      </ns-button>
    </div>
  </div>
</ng-container>
