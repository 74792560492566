import { Component, OnInit } from '@angular/core';
import { Constants } from '../../../app.constants';
import { VolunteeringConstants } from '../../volunteering.constants';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LanguageService } from '../../../services/language.service';
import { RouterUrls } from '../../../shared/router-urls';

@Component({
  selector: 'app-volunteering-share-success-modal',
  templateUrl: './volunteering-share-success-modal.component.html',
  styleUrls: ['./volunteering-share-success-modal.component.scss']
})
export class VolunteeringShareSuccessModalComponent implements OnInit {
  readonly Constants = Constants;
  readonly VolunteeringConstants = VolunteeringConstants;

  isMobile: boolean;

  constructor(private dialogRef: MatDialogRef<VolunteeringShareSuccessModalComponent>,
              private translate: TranslateService,
              private router: Router,
              private languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.isMobile = window.innerWidth <= 768;
  }

  getProposalUrl(): string {
    let proposalUrl = '#';
    switch (this.currentLang) {
      case Constants.FR_LANG_KEY:
        proposalUrl = VolunteeringConstants.PROPOSE_OFFER_FORM_URL_FR;
        break;
      case Constants.ES_LANG_KEY:
        proposalUrl = VolunteeringConstants.PROPOSE_OFFER_FORM_URL_ES;
        break;
      case Constants.EN_LANG_KEY:
        proposalUrl = VolunteeringConstants.PROPOSE_OFFER_FORM_URL_EN;
        break;
      default :
        proposalUrl = VolunteeringConstants.PROPOSE_OFFER_FORM_URL_FR;
        break;
    }
    return proposalUrl;
  }

  closeDialog() {
    this.router.navigate([this.languageService.getCurrentLangWithSlash(), RouterUrls.VolunteeringOffers]);
    this.dialogRef.close();
  }

  get currentLang() {
    return this.translate.currentLang;
  }

}
