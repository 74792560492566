<div [class.invisible]="!_showNotifications" class="notifications-card" [class.notifications-card-mobile]="isMobile">
  <div class="no-notifications" *ngIf="notifications.length === 0">
    {{ "LAC.no_notifications" | translate }}
  </div>
  <div class="notifications-wrapper" *ngIf="notifications.length >= 1">
    <div class="notifications-title" *ngIf="!isMobile">{{ "LAC.notifications" | translate }}</div>
    <div class="notifications-list">
      <div class="notification"
           [ngClass]="{'read': notification.read}"
           (click)="openPost(notification)"
           *ngFor="let notification of notifications; let i = index">
        <div class="not-read-point" *ngIf="!notification.read"></div>
        <div class="notification-content" [innerHTML]="notification.message[currentLanguage]"></div>
        <div class="notification-date">{{ notification.time_ago }}</div>
      </div>
    </div>
  </div>
</div>
