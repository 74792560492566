import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';

/**
 * Component responsible for handling the redirection after a SAML authentication process.
 * It checks for specific routes and query parameters to determine the authentication outcome.
 */
@Component({
  selector: 'app-saml-redirect',
  templateUrl: './saml-redirect.component.html',
  styleUrls: ['./saml-redirect.component.scss']
})
export class SamlRedirectComponent implements AfterViewInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
  ) {
  }

  /**
   * After the view initializes, this method checks the current route and parameters,
   * processes them, and navigates based on the result of the SAML authentication.
   */
  ngAfterViewInit(): void {
    const route = this.activatedRoute.snapshot.url.join('/');
    const data = this.activatedRoute.snapshot.queryParamMap.get('data');

    // Delay processing to ensure complete page load, may adjust delay based on actual UX needs
    setTimeout(() => {
      if (route === 'saml-success' && data) {
        this.processSuccessfulAuthentication(data);
      }
    }, 1000);
  }

  /**
   * Processes the successful SAML authentication by decoding and using the data provided.
   * @param userEncoded The base64 encoded JSON string from the query parameters.
   */
  private processSuccessfulAuthentication(userEncoded: string): void {
    try {
      const userDecoded = JSON.parse(this.decodeUrlSafeBase64(userEncoded));

      this.authService.onSuccessfulAuthentication(userDecoded);
      this.authService.handleAuthentication(userDecoded);
    } catch (e) {
      console.error('Error processing authentication data');
    }
  }

  /**
   * Decodes a URL-safe base64 string back to its original string format.
   * @param encoded The URL-safe base64 encoded string.
   * @returns The decoded string.
   */
  private decodeUrlSafeBase64(encoded: string): string {
    let base64 = encoded.replace(/-/g, '+').replace(/_/g, '/');
    while (base64.length % 4 !== 0) {
      base64 += '=';
    }
    try {
      return atob(base64);
    } catch (e) {
      console.error('Failed to decode base64 string');
      throw new Error('Failed to decode saml base64 string');
    }
  }

}
