import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-saml-auth',
  templateUrl: './saml-auth.component.html',
  styleUrl: './saml-auth.component.scss'
})
export class SamlAuthComponent {
  constructor(private authService: AuthService) {
  }

  samlLogin(): void {
    this.authService.goToSAMLAuth();
  }
}
