import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import { LanguageService } from '../../services/language.service';
import { RouterUrls } from '../../shared/router-urls';
import { CustomValidators } from '../../shared/custom-validators';

@Component({
  selector: 'app-reset-password-confirm',
  templateUrl: './reset-password-confirm.component.html',
  styleUrls: ['./reset-password-confirm.component.scss']
})
export class ResetPasswordConfirmComponent implements OnInit {

  resetPassForm: UntypedFormGroup;
  isCapsOn: boolean;
  isPasswordShownFirst = false;
  isPasswordShownSecond = false;
  passwordStrength: number;
  RouterUrls = RouterUrls;
  errorMessage = '';
  token = '';

  constructor(private formBuilder: UntypedFormBuilder,
              private authService: AuthService,
              private languageService: LanguageService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private cd: ChangeDetectorRef,
              private apiService: ApiService) { }

  ngOnInit(): void {
    this.resetPassForm = this.formBuilder.group({
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
    }, {validator: CustomValidators.MustMatch('password', 'confirmPassword')});
    this.activatedRoute.queryParamMap.subscribe(queryParams => {
        this.token = queryParams.get('token');
    });
  }

  changePassStrength(event) {
    this.passwordStrength = event;
    this.cd.detectChanges();
  }

  resetPassword() {
    this.apiService.resetUserPasswordConfirm(this.token, this.resetPassForm.value.password).subscribe(
      () => {
        this.router.navigate([this.languageService.getCurrentLangWithSlash(), RouterUrls.LogIn]);
      },
      error => {
        this.errorMessage = error.error;
      }
    );
  }

  get password() {
    return this.resetPassForm.get('password');
  }
  get confirmPassword() {
    return this.resetPassForm.get('confirmPassword');
  }

}
