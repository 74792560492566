import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appCapsLock]'
})
export class CapsLockDirective {

  @Output() appCapsLock = new EventEmitter<boolean>();

  constructor() { }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const capsOn = event.getModifierState && event.getModifierState('CapsLock');
    const x = event.key;
    if (x === 'CapsLock') {
      this.appCapsLock.emit(!capsOn);
    } else {
      this.appCapsLock.emit(capsOn);
    }
  }
}
