<div class="terms-wrapper">
  <h1>{{ "TERMS.main_title_terms" | translate }}</h1>
  <h4>{{ "TERMS.date_terms" | translate }}</h4>
  <p>{{ "TERMS.text1_terms" | translate }}</p>
  <h2>{{ "TERMS.title1_terms" | translate }}</h2>
  <p>{{ "TERMS.text2_terms" | translate }}</p>
  <p>{{ "TERMS.text3_terms" | translate }}</p>
  <p>{{ "TERMS.text4_terms" | translate }}</p>
  <p>{{ "TERMS.text4bis_terms" | translate }}</p>
  <p>{{ "TERMS.text5_terms" | translate }}</p>
  <p>{{ "TERMS.text6_terms" | translate }}</p>
  <h2>{{ "TERMS.title2_terms" | translate }}</h2>
  <h3>{{ "TERMS.subtitle1_terms" | translate }}</h3>
  <p>{{ "TERMS.text7_terms" | translate }}</p>
  <p>{{ "TERMS.text8_terms" | translate }}</p>
  <p>{{ "TERMS.text9_terms" | translate }}</p>
  <p>{{ "TERMS.text10_terms" | translate }}</p>
  <p>{{ "TERMS.text11_terms" | translate }}</p>
  <p>{{ "TERMS.text12_terms" | translate }}</p>
  <p>{{ "TERMS.text13_terms" | translate }}</p>
  <p>{{ "TERMS.text14_terms" | translate }}</p>
  <p>{{ "TERMS.text15_terms" | translate }}</p>
  <p>{{ "TERMS.text16_terms" | translate }}</p>
  <p>{{ "TERMS.text17_terms" | translate }}</p>
  <p>{{ "TERMS.text18_terms" | translate }}</p>
  <p>{{ "TERMS.text19_terms" | translate }}</p>
  <p>{{ "TERMS.text20_terms" | translate }}</p>
  <p>{{ "TERMS.text21_terms" | translate }}</p>
  <p>{{ "TERMS.text22_terms" | translate }}</p>
  <p>{{ "TERMS.text23_terms" | translate }}</p>
  <p>{{ "TERMS.text24_terms" | translate }}</p>
  <p>{{ "TERMS.text25_terms" | translate }}</p>
  <p>{{ "TERMS.text26_terms" | translate }}</p>
  <p>{{ "TERMS.text27_terms" | translate }}</p>
  <p>{{ "TERMS.text28_terms" | translate }}</p>
  <p>{{ "TERMS.text29_terms" | translate }}</p>
  <p>{{ "TERMS.text30_terms" | translate }}</p>
  <p>{{ "TERMS.text31_terms" | translate }}</p>
  <p>{{ "TERMS.text31bis_terms" | translate }}</p>
  <p>{{ "TERMS.text32_terms" | translate }}</p>
  <p>{{ "TERMS.text33_terms" | translate }}</p>
  <p>{{ "TERMS.text33bis_terms" | translate }}</p>
  <p>{{ "TERMS.text34_terms" | translate }}</p>
  <p>{{ "TERMS.text35_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle2_terms" | translate }}</h3>
  <p>{{ "TERMS.text36_terms" | translate }}</p>
  <h2>{{ "TERMS.title3_terms" | translate }}</h2>
  <p>{{ "TERMS.text37_terms" | translate }}</p>
  <h2>{{ "TERMS.title4_terms" | translate }}</h2>
  <h3>{{ "TERMS.subtitle3_terms" | translate }}</h3>
  <p>{{ "TERMS.text38_terms" | translate }}</p>
  <p>{{ "TERMS.text39_terms" | translate }}</p>
  <p>{{ "TERMS.text40_terms" | translate }}</p>
  <p>{{ "TERMS.text41_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle4_terms" | translate }}</h3>
  <p>{{ "TERMS.text42_terms" | translate }}</p>
  <p>{{ "TERMS.text43_terms" | translate }}</p>
  <p>{{ "TERMS.text44_terms" | translate }}</p>
  <p>{{ "TERMS.text45_terms" | translate }}</p>
  <p>{{ "TERMS.text46_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle5_terms" | translate }}</h3>
  <p>{{ "TERMS.text47_terms" | translate }}</p>
  <p>{{ "TERMS.text48_terms" | translate }}</p>
  <p>{{ "TERMS.text49_terms" | translate }}</p>
  <p>{{ "TERMS.text50_terms" | translate }}</p>
  <p>{{ "TERMS.text51_terms" | translate }}</p>
  <p>{{ "TERMS.text52_terms" | translate }}</p>
  <p>{{ "TERMS.text53_terms" | translate }}</p>
  <p>{{ "TERMS.text54_terms" | translate }}</p>
  <p>{{ "TERMS.text55_terms" | translate }}</p>
  <p>{{ "TERMS.text56_terms" | translate }}</p>
  <p>{{ "TERMS.text57_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle6_terms" | translate }}</h3>
  <p>{{ "TERMS.text58_terms" | translate }}</p>
  <h4>{{ "TERMS.subsubtitle1_terms" | translate }}</h4>
  <p>{{ "TERMS.text59_terms" | translate }}</p>
  <ul>
    <li>{{ "TERMS.text60_terms" | translate }}</li>
    <li>{{ "TERMS.text61_terms" | translate }}</li>
    <li>{{ "TERMS.text62_terms" | translate }}</li>
    <li>{{ "TERMS.text63_terms" | translate }}</li>
    <li>{{ "TERMS.text64_terms" | translate }}</li>
  </ul>
  <h3>{{ "TERMS.subtitle7_terms" | translate }}</h3>
  <p>{{ "TERMS.text65_terms" | translate }}</p>
  <div class="link" (click)="openCookies()">{{ "TERMS.link_terms" | translate }}</div>
  <h3>{{ "TERMS.subtitle8_terms" | translate }}</h3>
  <p>{{ "TERMS.text66_terms" | translate }}</p>
  <p>{{ "TERMS.text67_terms" | translate }}</p>
  <p>{{ "TERMS.text68_terms" | translate }}</p>
  <h2>{{ "TERMS.title5_terms" | translate }}</h2>
  <h3>{{ "TERMS.subtitle9_terms" | translate }}</h3>
  <p>{{ "TERMS.text69_terms" | translate }}</p>
  <p>{{ "TERMS.text70_terms" | translate }}</p>
  <p>{{ "TERMS.text71_terms" | translate }}</p>
  <p>{{ "TERMS.text72_terms" | translate }}</p>
  <p>{{ "TERMS.text73_terms" | translate }}</p>
  <p>{{ "TERMS.text74_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle10_terms" | translate }}</h3>
  <p>{{ "TERMS.text75_terms" | translate }}</p>
  <p>{{ "TERMS.text76_terms" | translate }}</p>
  <p>{{ "TERMS.text77_terms" | translate }}</p>
  <p>{{ "TERMS.text78_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle11_terms" | translate }}</h3>
  <p>{{ "TERMS.text79_terms" | translate }}</p>
  <p>{{ "TERMS.text80_terms" | translate }}</p>
  <p>{{ "TERMS.text81_terms" | translate }}</p>
  <p>{{ "TERMS.text82_terms" | translate }}</p>
  <p>{{ "TERMS.text83_terms" | translate }}</p>
  <p>{{ "TERMS.text84_terms" | translate }}</p>
  <p>{{ "TERMS.text85_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle12_terms" | translate }}</h3>
  <p>{{ "TERMS.text86_terms" | translate }}</p>
  <p>{{ "TERMS.text87_terms" | translate }}</p>
  <p>{{ "TERMS.text88_terms" | translate }}</p>
  <p>{{ "TERMS.text89_terms" | translate }}</p>
  <p>{{ "TERMS.text90_terms" | translate }}</p>
  <p>{{ "TERMS.text91_terms" | translate }}</p>
  <h2>{{ "TERMS.title6_terms" | translate }}</h2>
  <p>{{ "TERMS.text92_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle13_terms" | translate }}</h3>
  <p>{{ "TERMS.text93_terms" | translate }}</p>
  <p>{{ "TERMS.text94_terms" | translate }}</p>
  <p>{{ "TERMS.text95_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle14_terms" | translate }}</h3>
  <p>{{ "TERMS.text96_terms" | translate }}</p>
  <h4>{{ "TERMS.subsubtitle2_terms" | translate }}</h4>
  <p>{{ "TERMS.text97_terms" | translate }}</p>
  <p>{{ "TERMS.text98_terms" | translate }}</p>
  <p>{{ "TERMS.text99_terms" | translate }}</p>
  <h4>{{ "TERMS.subsubtitle3_terms" | translate }}</h4>
  <p>{{ "TERMS.text100_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle15_terms" | translate }}</h3>
  <h4>{{ "TERMS.subsubtitle4_terms" | translate }}</h4>
  <p>{{ "TERMS.text101_terms" | translate }}</p>
  <h4>{{ "TERMS.subsubtitle5_terms" | translate }}</h4>
  <p>{{ "TERMS.text102_terms" | translate }}</p>
  <h4>{{ "TERMS.subsubtitle6_terms" | translate }}</h4>
  <p>{{ "TERMS.text103_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle16_terms" | translate }}</h3>
  <p>{{ "TERMS.text104_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle17_terms" | translate }}</h3>
  <p>{{ "TERMS.text105_terms" | translate }}</p>
  <p>{{ "TERMS.text106_terms" | translate }}</p>
  <p>{{ "TERMS.text107_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle18_terms" | translate }}</h3>
  <p>{{ "TERMS.text108_terms" | translate }}</p>
  <p>{{ "TERMS.text109_terms" | translate }}</p>
  <p>{{ "TERMS.text110_terms" | translate }}</p>
  <p>{{ "TERMS.text111_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle19_terms" | translate }}</h3>
  <p>{{ "TERMS.text112_terms" | translate }}</p>
  <p>{{ "TERMS.text113_terms" | translate }}</p>
  <p>{{ "TERMS.text114_terms" | translate }}</p>
  <p>{{ "TERMS.text115_terms" | translate }}</p>
  <h2>{{ "TERMS.title7_terms" | translate }}</h2>
  <p>{{ "TERMS.text116_terms" | translate }}</p>
  <p>{{ "TERMS.text117_terms" | translate }}</p>
  <p>{{ "TERMS.text118_terms" | translate }}</p>
  <p>{{ "TERMS.text119_terms" | translate }}</p>
  <h2>{{ "TERMS.title8_terms" | translate }}</h2>
  <h3>{{ "TERMS.subtitle20_terms" | translate }}</h3>
  <p>{{ "TERMS.text120_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle21_terms" | translate }}</h3>
  <p>{{ "TERMS.text121_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle22_terms" | translate }}</h3>
  <p>{{ "TERMS.text122_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle23_terms" | translate }}</h3>
  <p>{{ "TERMS.text123_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle24_terms" | translate }}</h3>
  <p>{{ "TERMS.text124_terms" | translate }}</p>
  <h2>{{ "TERMS.title9_terms" | translate }}</h2>
  <p>{{ "TERMS.text125_terms" | translate }}</p>
  <p>{{ "TERMS.text126_terms" | translate }}</p>
  <p>{{ "TERMS.text127_terms" | translate }}</p>
  <p>{{ "TERMS.text128_terms" | translate }}</p>
  <p>{{ "TERMS.text129_terms" | translate }}</p>
  <p>{{ "TERMS.text130_terms" | translate }}</p>
  <p>{{ "TERMS.text131_terms" | translate }}</p>
  <p>{{ "TERMS.text132_terms" | translate }}</p>
  <h2>{{ "TERMS.title10_terms" | translate }}</h2>
  <h3>{{ "TERMS.subtitle25_terms" | translate }}</h3>
  <p>{{ "TERMS.text133_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle26_terms" | translate }}</h3>
  <p>{{ "TERMS.text134_terms" | translate }}</p>
  <p>{{ "TERMS.text135_terms" | translate }}</p>
  <p>{{ "TERMS.text136_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle27_terms" | translate }}</h3>
  <p>{{ "TERMS.text137_terms" | translate }}</p>
  <p>{{ "TERMS.text138_terms" | translate }}</p>
  <p>{{ "TERMS.text139_terms" | translate }}</p>
  <h2>{{ "TERMS.title11_terms" | translate }}</h2>
  <h3>{{ "TERMS.subtitle28_terms" | translate }}</h3>
  <p>{{ "TERMS.text140_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle29_terms" | translate }}</h3>
  <p>{{ "TERMS.text141_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle30_terms" | translate }}</h3>
  <p>{{ "TERMS.text142_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle31_terms" | translate }}</h3>
  <p>{{ "TERMS.text143_terms" | translate }}</p>
  <h3>{{ "TERMS.subtitle32_terms" | translate }}</h3>
  <p>{{ "TERMS.text144_terms" | translate }}</p>
  <p>{{ "TERMS.text145_terms" | translate }}</p>
  <p>{{ "TERMS.text146_terms" | translate }}</p>
  <p>{{ "TERMS.text147_terms" | translate }}</p>
  <h2>{{ "TERMS.title12_terms" | translate }}</h2>
  <p>{{ "TERMS.text148_terms" | translate }}</p>
  <h2>{{ "TERMS.title13_terms" | translate }}</h2>
  <p>{{ "TERMS.text149_terms" | translate }}</p>
  <p>{{ "TERMS.text150_terms" | translate }}</p>
  <p>{{ "TERMS.text151_terms" | translate }}</p>
  <p>{{ "TERMS.text152_terms" | translate }}</p>
  <p>{{ "TERMS.text153_terms" | translate }}</p>
  <p>{{ "TERMS.text154_terms" | translate }}</p>
</div>
