import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import {  of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { LanguageService } from '../services/language.service';
import { RouterUrls } from '../shared/router-urls';
import { Constants } from '../app.constants';

const pledgeRestrictedUrls = [RouterUrls.VolunteeringOffers, RouterUrls.Campaign, RouterUrls.Home];

export const canActivateRoute: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const languageService = inject(LanguageService);
  return authService.isUserLogged
    .pipe(
      switchMap(isLoggedIn => {
        return isLoggedIn ? of(isLoggedIn) :
          authService.getCurrentUser().pipe(map(() => true), catchError(() => of(false)));
      }),
      tap(authSucceeded => {
        // LAC Redirection
        if (route.url[0]?.path === RouterUrls.Challenges) {
          router.navigate([languageService.getCurrentLangWithSlash(), RouterUrls.Campaign]);
        }

        if (authSucceeded) {
          if (pledgeRestrictedUrls.includes(route.url[0]?.path) && authService.currentUserValue.pledge_id === null) {
            router.navigate([languageService.getCurrentLangWithSlash()]);
          }
        } else {
          if (route.url[0].path === RouterUrls.YourImpact) {
            router.navigate([languageService.getCurrentLangWithSlash(), RouterUrls.LogIn],
              { queryParams: { redirect: 1, [Constants.RETURN_URL_PARAM_KEY]: state.url } });
          } else {
            router.navigate([languageService.getCurrentLangWithSlash(), RouterUrls.LogIn],
              { queryParams: { [Constants.RETURN_URL_PARAM_KEY]: state.url } });
          }
        }
      })
    );
};

