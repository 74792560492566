<div class="soft-ask-container">
  <div (click)="onDisallowSoftAsk()" class="close-button"></div>
  <div class="logo-container">
    <img alt="noos-logo" src="assets/icons/very-light-green-logo.svg">
  </div>
  <div>
    <span>{{ "SOFT_ASK.notification_title" | translate }}</span>
    <span>{{ "SOFT_ASK.notification_subtitle" | translate }}</span>
    <span>{{ "SOFT_ASK.notification_info" | translate }}</span>
    <div class="buttons-container">
      <button (click)="onAllowSoftAsk()">{{ "SOFT_ASK.allow" | translate }}</button>
      <button (click)="onDisallowSoftAsk()">{{ "SOFT_ASK.disallow" | translate }}</button>
    </div>
  </div>
</div>
