<div class="filters-container">
  <div (click)="closeDialog()" class="close-button"></div>
  <span class="title ns-mt-6">{{'VOLUNTEERING_TAB.filters' | translate}}</span>
  <hr class="separator ns-mt-10">
  <div class="scrollable-y-container">
    <!-- Category Filter -->
    <span class="filter-title">{{'VOLUNTEERING_TAB.category_filter_title' | translate}}</span>
    <hr>
    <div class="options-container">
      <div *ngFor="let categoryInfo of currentFiltersOptions.filterCategoriesInfo" class="filter-option">
        <label class="checkbox">
          <input (change)="onOptionChange(categoryInfo.tag.id, this.FILTER_IDS.CATEGORY_ID)"
                 [checked]="isChecked(categoryInfo.tag.id, categoryIdsToFilterBy)"
                 [disabled]="categoryInfo.itemsNumber === 0"
                 type="checkbox"/>
          <span [ngClass]="{'disabled': categoryInfo.itemsNumber === 0}">
            {{categoryInfo.tag.tag[currentFiltersOptions.language]}}</span>
        </label>
      </div>
    </div>
    <!-- Language Filter -->
    <span class="filter-title">{{'VOLUNTEERING_TAB.language_filter_title' | translate}}</span>
    <hr>
    <div class="options-container">
      <div *ngFor="let langInfo of currentFiltersOptions.filterLanguagesInfo" class="filter-option">
        <label class="checkbox">
          <input (change)="onOptionChange(langInfo.tag.id, this.FILTER_IDS.LANGUAGES_ID)"
                 [checked]="isChecked(langInfo.tag.id, languageIdsToFilterBy)"
                 [disabled]="langInfo.itemsNumber === 0"
                 type="checkbox"/>
          <span [ngClass]="{'disabled': langInfo.itemsNumber === 0}">
            {{langInfo.tag.tag[currentFiltersOptions.language]}}</span>
        </label>
      </div>
    </div>
    <!-- Format Filter -->
    <span class="filter-title">{{'VOLUNTEERING_TAB.format_filter_title' | translate}}</span>
    <hr>
    <div class="options-container">
      <div *ngFor="let formatInfo of currentFiltersOptions.filterFormatsInfo" class="filter-option">
        <label class="checkbox">
          <input (change)="onOptionChange(formatInfo.tag.id, this.FILTER_IDS.FORMATS_ID)"
                 [checked]="isChecked(formatInfo.tag.id, formatIdsToFilterBy)"
                 [disabled]="formatInfo.itemsNumber === 0"
                 type="checkbox"/>
          <span [ngClass]="{'disabled': formatInfo.itemsNumber === 0}">
            {{formatInfo.tag.tag[currentFiltersOptions.language]}}</span>
        </label>
      </div>
    </div>
    <!-- Location Filter -->
    <span class="filter-title">{{'VOLUNTEERING_TAB.location_filter_title' | translate}}</span>
    <hr>
    <div class="options-container">
      <div *ngFor="let locationInfo of currentFiltersOptions.filterLocationsInfo" class="filter-option">
        <label class="checkbox">
          <input (change)="onOptionChange(locationInfo.tag.id, this.FILTER_IDS.LOCATION_ID)"
                 [checked]="isChecked(locationInfo.tag.id, locationIdsToFilterBy)"
                 [disabled]="locationInfo.itemsNumber === 0"
                 type="checkbox"/>
          <span [ngClass]="{'disabled': locationInfo.itemsNumber === 0}">
            {{locationInfo.tag.tag[currentFiltersOptions.language]}}</span>
        </label>
      </div>
    </div>
    <!-- Duration Filter -->
    <span class="filter-title">{{'VOLUNTEERING_TAB.duration_filter_title' | translate}}</span>
    <hr>
    <div class="options-container">
      <div *ngFor="let durationInfo of currentFiltersOptions?.filterDurationInfo" class="filter-option">
        <label class="checkbox">
          <input (change)="onOptionChange(durationInfo.tag.id, this.FILTER_IDS.DURATION_ID)"
                 [checked]="isChecked(durationInfo.tag.id, durationIdsToFilterBy)"
                 [disabled]="durationInfo.itemsNumber === 0"
                 type="checkbox"/>
          <span [ngClass]="{'disabled': durationInfo.itemsNumber === 0}">
            {{durationInfo.tag.tag[currentFiltersOptions.language]}}</span>
        </label>
      </div>
    </div>
    <!-- Dedication Filter -->
    <span class="filter-title">{{'VOLUNTEERING_TAB.dedication_filter_title' | translate}}</span>
    <hr>
    <div class="options-container">
      <div *ngFor="let dedicationInfo of currentFiltersOptions.filterDedicationInfo" class="filter-option">
        <label class="checkbox">
          <input (change)="onOptionChange(dedicationInfo.tag.id, this.FILTER_IDS.DEDICATION_ID)"
                 [checked]="isChecked(dedicationInfo.tag.id, dedicationIdsToFilterBy)"
                 [disabled]="dedicationInfo.itemsNumber === 0"
                 type="checkbox"/>
          <span [ngClass]="{'disabled': dedicationInfo.itemsNumber === 0}">
            {{dedicationInfo.tag.tag[currentFiltersOptions.language]}}</span>
        </label>
      </div>
    </div>
  </div>

  <hr class="separator ns-mb-18">
  <div class="buttons-container">
    <ns-button
      (callback$)="onRestoreFilters()"
      [styles]="'width: 89px;'"
      [text]="'VOLUNTEERING_TAB.restore_button' | translate"
      [type]="Constants.BUTTON_TYPE_PILL_DARK_GREY_TRANSPARENT_L"
      classes="ns-mr-12">
    </ns-button>
    <ns-button
      (callback$)="onApplyFilterSelection()"
      [styles]="'width:189px;'"
      [type]="Constants.BUTTON_TYPE_PILL_LIGHT_GREEN_SOLID_L"
      text="{{('VOLUNTEERING_TAB.show_results_button' | translate) + ' (' + currentFiltersOptions.offersNumber +')'}}">
    </ns-button>
  </div>
</div>
