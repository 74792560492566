import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorService } from '../services/http-error.service';
import { User } from '../shared/user.model';
import { HttpErrorReport } from '../shared/models/http-error-report/http-error-report.model';
import { environment } from '../../environments/environment';
import { Constants } from '../app.constants';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private httpErrorService: HttpErrorService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (!(error.error instanceof ErrorEvent)) {

            const errorReport: HttpErrorReport = {
              source: Constants.ERROR_SOURCE_NAME,
              referer_url: error.url,
              end_url: error.url,
              error_code: error.status.toString(),
              account_id: 0,
              token: environment.backendLogToken
            };

            const user = JSON.parse(localStorage.getItem('currentUser')) as User;
            if (user && user.id) {
              errorReport.account_id = user.id;
            }

            if (error?.status === 404 || error?.status === 403 || error?.status === 400) {
              this.httpErrorService.reportHttpError(errorReport).subscribe();
            }
          }
          return throwError(error);
        })
      );
  }
}
