import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from '../../../services/language.service';
import { RouterUrls } from '../../router-urls';
import { FooterConstants } from '../../footer/footer.constants';

@Component({
  selector: 'ns-simple-footer',
  templateUrl: './simple-footer.component.html',
  styleUrl: './simple-footer.component.scss'
})
export class NsSimpleFooterComponent {

  FooterConstants = FooterConstants;
  RouterUrls = RouterUrls;
  year: number;

  constructor(
    private router: Router,
    private languageService: LanguageService,) {
  }


  ngOnInit(): void {
    this.year = new Date().getFullYear();
  }

  navigateTo(code: number): void {
    switch (code) {
      case FooterConstants.TERMS_AND_CONDITIONS_CODE:
        this.router.navigate([this.languageService.getCurrentLangWithSlash(), RouterUrls.TermsOfService]);
        break;
      case FooterConstants.PRIVACY_POLICY_CODE:
        this.router.navigate([this.languageService.getCurrentLangWithSlash(), RouterUrls.PrivacyPolicy]);
        break;

      default:
        break;
    }
  }
}
