import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../../../shared/custom-validators';
import {
  VolunteeringApplication,
} from '../../../../volunteering/models/volunteering-application.model';
import { Constants } from '../../../../app.constants';
import { VolunteeringApplicant } from '../../../models/volunteering-application.model';
import { ProjectsService } from '../../../../services/projects.service';
import { LanguageService } from '../../../../services/language.service';
import { RouterUrls } from '../../../../shared/router-urls';
import { VolunteeringConstants } from '../../../../volunteering/volunteering.constants';

@Component({
  selector: 'app-applicant-dialog',
  templateUrl: './applicant-dialog.component.html',
  styleUrls: ['./applicant-dialog.component.scss']
})
export class ApplicantDialogComponent implements OnInit {
  readonly Constants = Constants;
  RouterUrls = RouterUrls;

  applicantForm: UntypedFormGroup;
  formName: string;
  formEmail: string;
  formLinkedin: string;

  userEmail: string;
  userName: string;
  userLinkedin: string;

  userId: number;
  projectId: number;
  ioName: string;
  company: string;

  applicantWhyForm: UntypedFormGroup;
  userWhy: string;
  volunteeringApplicant: VolunteeringApplicant;
  showIoName: boolean;

  currentStep = 1;

  screenWidth: number;

  constructor(private dialogRef: MatDialogRef<ApplicantDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public application: VolunteeringApplication,
              private router: Router,
              private translate: TranslateService,
              private formBuilder: UntypedFormBuilder,
              private projectsService: ProjectsService,
              private languageService: LanguageService
  ) {
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.userEmail = this.application.email;
    this.userName = this.application.name;
    this.userId = this.application.userId;
    this.projectId = this.application.projectId;
    this.ioName = this.application.ioName;
    this.company = this.application.company;

    this.applicantForm = this.formBuilder.group({
      formEmail: ['', [Validators.required, Validators.pattern(CustomValidators.emailValidationRegex)]],
      formName: ['', [Validators.required]],
      formLinkedin: ['']
    });

    this.applicantForm.get('formEmail').setValue(this.userEmail);
    this.applicantForm.get('formName').setValue(this.userName);
    this.showIoName = ![VolunteeringConstants.NO_PROJECT_OFFER_KEY, ""].includes(this.ioName);
    this.applicantWhyForm = this.formBuilder.group({
      userWhy: ['', [Validators.required]]
    });
  }


  nextStep(): void {
    this.currentStep++;
  }

  previousStep(): void {
    this.currentStep--;
  }

  lastStep() {
    this.userWhy = this.applicantWhyForm.value.userWhy;

    this.volunteeringApplicant = {
      account_id: this.userId,
      project_id: this.projectId,
      linkedin: this.userLinkedin,
      why: this.userWhy
    };

    if (typeof this.userWhy !== 'undefined') {
      this.newApplicant(this.volunteeringApplicant);
      this.nextStep();
    }
  }


  closeDialog() {
    this.dialogRef.close();
    if (this.currentStep === 3) {
      this.router.navigate([this.languageService.getCurrentLangWithSlash(), RouterUrls.VolunteeringOffers]);
    }
  }

  newApplicant(applicant: VolunteeringApplicant) {
    this.projectsService.addApplicantProjectVolunteering(applicant).subscribe(() => {
    });
  }

  get currentLanguage() {
    return this.languageService.getUserUsedLanguage();
  }

  getTypeformLink(language: string) {
    if (language === 'es') {
      return 'https://noos.typeform.com/to/v5CHyp75';
    } else {
      if (language === 'en') {
        return 'https://noos.typeform.com/to/doKcHsMp';
      } else {
        return 'https://noos.typeform.com/to/H0KpiFYBz';
      }
    }
  }
}
