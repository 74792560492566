import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalConstants } from './modal.constants';

interface ModalRequest {
  // Unique identifier for the modal, used mainly to close the correct modal on the queue.
  id: string;

  // Priority within the layer, higher number = higher priority.
  // If new layer, start by priority = 100, this way there is room for future modals.
  priority: number;

  /**
   * Layers are used to organize modals into different categories based on their importance or context.
   * Each layer represents a level of priority. Modals in higher layers are shown before those in lower layers.
   * Within the same layer, modals can also have different priorities, which further determines the order
   * they appear. This system helps manage which modals are displayed first,
   * ensuring that more critical or relevant modals get attention before others."
   *
   * Reserved Layers:
   * - Layers 0 to 6 are reserved for internal system use and should not be used for new modals.
   *
   * Defined Layers:
   * | Layer | Description             |
   * |-------|-------------------------|
   * | 7     | PRODUCT ONBOARDINGS     |
   * | 8     | VOTE SYSTEM             |
   * | 9     | SYSTEM                  |
   *
   * Example:
   * Assigning a modal to `layer 7` ensures it is managed within the context of product onboarding,
   * and it will be prioritized over modals in layers 8 and 9.
   */
  layer: number;

  // Function that contains the logic to open the modal. Can be any sort of code to open modals you need.
  openModalFn: () => void;
}

/**
 * Service for managing a queue of modal to ensure that only one modal is displayed at a time,
 * maintaining an orderly and prioritized presentation based on predefined layers and priorities.
 *
 * Key Features:
 * - **Queue Management**: Prevents duplicate entries and orders modals by layer and priority.
 * - **Layered Prioritization**: Groups modals into categories such as onboarding or system alerts,
 *   where higher layers have display precedence.
 * - **Sequential Display**: Opens the next modal in the queue only after the current one has closed,
 *   handled by observing modal closure events.
 * - **Error Handling**: Robust error management for unmatched modal closure attempts.
 *
 * **IMPORTANT USAGE INSTRUCTIONS!**
 *
 * - Ensure unique modal IDs and proper layer assignments.
 * - Always close modals using closeModal(id) to maintain queue integrity.
 * - Utilize modal.constants.ts for consistent ID references.
 *
 * Example usage includes managing modals for user onboarding flows, voting systems, and other system notifications, where the management of modal display order and visibility is crucial.
 *
 */
@Injectable({
  providedIn: 'root'
})
export class ModalQueueService {
  public ids = ModalConstants.modalIds;
  private modalQueue: ModalRequest[] = [];
  private currentModal: ModalRequest | null = null;
  // Observable to notify when a modal is closed
  private modalClosedSource = new Subject<void>();
  modalClosed$ = this.modalClosedSource.asObservable();

  constructor() {
    // Hears when a modal is closed and activates the next on the queue.
    this.modalClosed$.subscribe(() => {
      this.currentModal = null;
      this.checkAndOpenNextModal();
    });
  }

  /**
   * Adds a modal request to the queue if it is not already present.
   * Modals are sorted first by layer and then by priority within each layer.
   * @param request The modal request to add to the queue.
   */
  addModalToQueue(request: ModalRequest): void {
    const existingModal = this.modalQueue.find(m => m.id === request.id);
    if (existingModal) {
      return;
    }

    this.modalQueue.push(request);
    this.modalQueue.sort((a, b) => a.layer - b.layer || b.priority - a.priority);
    this.checkAndOpenNextModal();
  }

  /**
   * Checks if there are any modals in the queue and opens the next one if no other modal is currently active.
   */
  checkAndOpenNextModal(): void {
    if (!this.currentModal && this.modalQueue.length > 0) {
      this.currentModal = this.modalQueue.shift()!;
      this.currentModal.openModalFn();
    }
  }

  /**
   * Closes the currently active modal if its ID matches the provided ID.
   * Else, if not the currently active but on the queue, tries to remove it too.
   * @param id The ID of the modal to close.
   */
  closeModal(id: string): void {
    if (this.currentModal && this.currentModal.id === id) {
      this.modalClosedSource.next();
    } else {
      this.attemptToCloseFromQueue(id);
    }
  }

  private attemptToCloseFromQueue(id: string): void {
    const index = this.modalQueue.findIndex(m => m.id === id);
    if (index !== -1) {
      this.modalQueue.splice(index, 1);
    }
  }
}
