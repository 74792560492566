<div class="big-container">
  <div class="top-section">
    <div class="video-wrapper" #videoWrapper>
      <video muted playsinline preload="metadata">
        <source src="https://noos.ams3.digitaloceanspaces.com/landing_main_background.webm" type='video/webm;'/>
        <source src="https://noos.ams3.digitaloceanspaces.com/landing_main_background.mp4" type='video/mp4;'/>
      </video>
      <div class="filter-video"></div>
      <div class="top-text">
        <span>NooS</span>
        <br>
        {{ "LANDING.top_title_landing" | translate }}
      </div>
    </div>
  </div>
  <div class="first-container">
    <div class="first-title">{{ "LANDING.title1_landing" | translate }}</div>
    <div class="squares-section">
      <div class="square-unit unit-1">
        <img loading="lazy" src="/assets/icons/landing-icon-1.svg" alt="collaborative-philanthropy">
        <div class="square-title">{{ "LANDING.square_title_1_landing" | translate }}</div>
        <div class="square-text">{{ "LANDING.square_text_1_landing" | translate }}</div>
      </div>
      <div class="square-unit unit-2">
        <img loading="lazy" src="/assets/icons/landing-icon-2.svg" alt="volunteering">
        <div class="square-title">{{ "LANDING.square_title_2_landing" | translate }}</div>
        <div class="square-text">{{ "LANDING.square_text_2_landing" | translate }}</div>
      </div>
      <div class="square-unit unit-3">
        <img loading="lazy" src="/assets/icons/landing-icon-3.svg" alt="challenges">
        <div class="square-title">{{ "LANDING.square_title_3_landing" | translate }}</div>
        <div class="square-text">{{ "LANDING.square_text_3_landing" | translate }}</div>
      </div>
      <div class="square-unit unit-4">
        <img loading="lazy" src="/assets/icons/landing-icon-4.svg" alt="knowledge">
        <div class="square-title">{{ "LANDING.square_title_4_landing" | translate }}</div>
        <div class="square-text">{{ "LANDING.square_text_4_landing" | translate }}</div>
      </div>
    </div>
    <div class="first-subtitle">{{ "LANDING.first_subtitle_landing" | translate }}</div>
    <div class="first-button" (click)="sendPresentationMail()">{{ "LANDING.first_button_landing" | translate }}</div>
  </div>
  <div class="second-container">
    <div class="second-wrapper">
      <div class="left-side-2">
        <div class="left-title-2">{{ "LANDING.left_title_2_landing" | translate }}</div>
        <div class="left-text-2">{{ "LANDING.left_text_2_landing" | translate }}</div>
      </div>
      <div class="right-image"></div>
    </div>
  </div>
  <div class="third-container">
    <div class="third-title">{{ "LANDING.third_title_landing" | translate }}</div>
    <div class="third-wrapper">
      <div class="left-side-3">
        <div class="left-text-3">{{ "LANDING.left_text_3_landing" | translate }}</div>
        <div class="left-button-3" [routerLink]="[currentLang, RouterUrls.VettingProcess]">{{ "LANDING.left_button_3_landing" | translate }}</div>
      </div>
      <div class="right-side-3">
        <div class="carousel">
          <div class="slider">
            <section>
              <img src="/assets/icons/section-icon-1.svg" alt="impact">
              <div class="square-title">{{ "LANDING.section_title1_landing" | translate }}</div>
              <div class="square-text">{{ "LANDING.section_text1_landing" | translate }}</div>
            </section>
            <section>
              <img src="/assets/icons/section-icon-2.svg" alt="proximity-design">
              <div class="square-title">{{ "LANDING.section_title2_landing" | translate }}</div>
              <div class="square-text">{{ "LANDING.section_text2_landing" | translate }}</div>
            </section>
            <section>
              <img src="/assets/icons/section-icon-3.svg" alt="innovation">
              <div class="square-title">{{ "LANDING.section_title3_landing" | translate }}</div>
              <div class="square-text">{{ "LANDING.section_text3_landing" | translate }}</div>
            </section>
            <section>
              <img src="/assets/icons/section-icon-4.svg" alt="transparency">
              <div class="square-title">{{ "LANDING.section_title4_landing" | translate }}</div>
              <div class="square-text">{{ "LANDING.section_text4_landing" | translate }}</div>
            </section>
            <section>
              <img src="/assets/icons/section-icon-5.svg" alt="communication">
              <div class="square-title">{{ "LANDING.section_title5_landing" | translate }}</div>
              <div class="square-text">{{ "LANDING.section_text5_landing" | translate }}</div>
            </section>
          </div>
          <div class="controls">
            <div class="prev" (click)="prev()">
              <img loading="lazy" src="/assets/icons/left-black-arrow.svg" alt="prev">
            </div>
            <div class="next" (click)="next()">
              <img loading="lazy" src="/assets/icons/right-black-arrow.svg" alt="next">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="fourth-container">
    <div class="fourth-title">{{ "LANDING.fourth_title_landing" | translate }}</div>
    <div class="fourth-wrapper">
      <div class="left-side-4">
        <div class="left-text-4-1">{{ "LANDING.left_text41_landing" | translate }}</div>
        <div class="left-text-4-2">{{ "LANDING.left_text42_landing" | translate }}</div>
        <div class="left-button-4" (click)="sendPresentationMail()">{{ "LANDING.left_button4_landing" | translate }}</div>
        <div (click)="openChatWindow()" class="left-text-4-3">{{ "LANDING.left_text43_landing" | translate }}</div>
      </div>
      <div class="right-side-4">
        <div class="right-side-quote">{{ "LANDING.quote_landing" | translate }}</div>
        <div class="author-container">
          <div class="author-picture"></div>
          <div class="author-left">
            <div class="author-name">{{ "LANDING.quote_author_landing" | translate }}</div>
            <div class="author-position">{{ "LANDING.quote_position_landing" | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="fifth-container">
    <div class="left-side-5">
      <div class="left-title-5">{{ "LANDING.left_title5_landing" | translate }}</div>
      <div class="left-text-5">{{ "LANDING.left_text5_landing" | translate }}</div>
      <div class="left-button-5" [routerLink]="[currentLang, RouterUrls.LogIn]">{{ "LANDING.left_button5_landing" | translate }}</div>
    </div>
    <div [style.background-image]="'url(https://noos.ams3.cdn.digitaloceanspaces.com/landing_impact-image_' + currentLanguage + '.png)'"
         class="right-side-5"></div>
  </div>
</div>
