<div class="impact-organization-header">
    <div class="top-info">
        <div class="bg-image"></div>
        <div class="container-xl custom-container">
            <div class="top-wrapper">
                <h1>{{ "FOR_IO.areyou_forio" | translate }}<br>{{ "FOR_IO.io_forio" | translate }}</h1>
                <button class="yellow serif"><a href="mailto:hello@noos.global">{{ "FOR_IO.contact_forio" | translate }}</a></button>
            </div>
        </div>
    </div>
</div>
<div class="body-section">
    <div class="title-wrapper">
        {{ "FOR_IO.title2_forio" | translate }}
    </div>
    <div class="reasons-container">
        <div class="reason-wrapper">
            <div class="reason-title">
                {{ "FOR_IO.fundraising_forio" | translate }}
            </div>
            <div class="reason-logo">
                <img loading="lazy" src="assets/icons/dark-dollar.png" alt="fundraising">
            </div>
            <div class="reason-text">
                {{ "FOR_IO.fundraising_text_forio" | translate }}
            </div>
        </div>
        <div class="reason-wrapper">
            <div class="reason-title">
                {{ "FOR_IO.relationship_forio" | translate }}
            </div>
            <div class="reason-logo">
                <img loading="lazy" src="assets/icons/relationship.png" alt="relationship">
            </div>
            <div class="reason-text">
                {{ "FOR_IO.relationship_text_forio" | translate }}
            </div>
        </div>
        <div class="reason-wrapper">
            <div class="reason-title">
                {{ "FOR_IO.showcase_forio" | translate }}
            </div>
            <div class="reason-logo">
                <img loading="lazy" src="assets/icons/communication.png" alt="communication">
            </div>
            <div class="reason-text">
                {{ "FOR_IO.showcase_text_forio" | translate }}
            </div>
        </div>
        <div class="reason-wrapper">
            <div class="reason-title">
                {{ "FOR_IO.partnering_forio" | translate }}
            </div>
            <div class="reason-logo">
                <img loading="lazy" src="assets/icons/partner.png" alt="partner">
            </div>
            <div class="reason-text">
                {{ "FOR_IO.partnering_text_forio" | translate }}
            </div>
        </div>
        <div class="reason-wrapper">
            <div class="last-text">
                {{ "FOR_IO.more_forio" | translate }}
            </div>
        </div>
    </div>
    <div class="apply-section">
        <div class="apply-section-title">
            {{ "FOR_IO.question_forio" | translate }}
        </div>
        <div class="apply-section-subtitle">
            {{ "FOR_IO.tell_us_forio" | translate }}
        </div>
        <button class="yellow serif"><a href="mailto:hello@noos.global">{{ "FOR_IO.contact_forio" | translate }}</a></button>
    </div>
</div>

<app-subscribe-banner></app-subscribe-banner>
