<div class="volunteering-ob-container">
  <div class="step">
    <div class="title-1">{{ 'VOLUNTEERING_ONBOARDING.step_1_title' | translate }}</div>
    <img *ngIf="currentStep === 1"
         [src]="'assets/images/png/volunteering_onboarding_1_'+ currentLanguage +'_' + screenMode + '.png'"
         alt="Volunteering"/>
    <img *ngIf="currentStep === 2"
         [src]="'assets/images/png/volunteering_onboarding_2_' + screenMode + '.png'"
         alt="Volunteering"/>
    <div class="title-2">{{ 'VOLUNTEERING_ONBOARDING.step_' + currentStep + '_subtitle' | translate }}</div>
    <div class="text-1">{{ 'VOLUNTEERING_ONBOARDING.step_' + currentStep + '_text' | translate }}</div>
    <div class="button-container">
      <ns-button
        (callback$)="onNextStepClick()"
        [classes]="currentStep === 1 ? 'ns-pr-55' : ''"
        [iconAlignedToBorder]="true"
        [iconClasses]="'icon-19x19 ns-mr-15'"
        [iconRightSide]="true"
        [icon]=" currentStep === 1 ? 'assets/icons/arrow-right-very-dark-grey.svg':''"
        [noTextUnderline]="true"
        [styles]="'min-width:200px;'"
        [text]="'VOLUNTEERING_ONBOARDING.step_' + currentStep + '_button_text' | translate"
        [type]="Constants.BUTTON_TYPE_DEFAULT_YELLOW_FILLED_B">
      </ns-button>
      <div class="dots">
        <span *ngFor="let step of [].constructor(2); let i = index"
              [ngClass]="{'active-dot': i === (currentStep - 1)}">
        </span>
      </div>
    </div>
  </div>
</div>
